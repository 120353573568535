import React from 'react';
import {
    Checkbox
} from '@blueprintjs/core';

export class Target {
    tablet: string;
    fragment: string;
    id: number;
    selected: boolean;

    constructor(tablet = "", fragment = "", id = -1) {
        this.tablet = tablet;
        this.fragment = fragment;
        this.id = id;
        this.selected = false;
    }
}

export interface ITargetListProps {
    targets: Target[]
    handleSelectionChange: (target: number, checked: boolean) => void
}

export interface ITargetListState {
    selectedTargets: string[],
}

class TargetList extends React.Component<ITargetListProps, ITargetListState> {

    constructor(props: ITargetListProps, state: ITargetListState) {
        super(props)

        this.state = {
            selectedTargets: []
        }
    }

    handleEnabledChange = (event: any) => {
        let checked: boolean = event.target.checked;
        let checkBoxName: string = event.target.name;

        let tmpTargets: string[] = this.state.selectedTargets;

        if (checked === true) {
            tmpTargets.push(checkBoxName);
        } else {
            const index = tmpTargets.indexOf(checkBoxName);
            if (index > -1) {
                tmpTargets.splice(index, 1);
            }
        }
        this.setState({selectedTargets: tmpTargets});
        this.props.handleSelectionChange(parseInt(checkBoxName), checked);
    }

    render() {
        return (
            <ul style={{listStyleType:"none", padding: "15px"}}>
                {this.props.targets.map((target) => {
                    return <li key={target.id + ""}>
                        <Checkbox name= {target.id + ""} label={target.tablet + " / " + target.fragment} large alignIndicator="right" onChange={this.handleEnabledChange} style={{background: "darkgrey"}}>
                            </Checkbox>
                    </li>
                })
                }
            </ul>
        );
    }
}

export default TargetList;