import React from "react";
import './sign-select-box.css'

import { Button, MenuItem, Props, Intent, TagProps } from "@blueprintjs/core";
import { ItemRenderer, MultiSelect } from "@blueprintjs/select";

import {
    INTENTS,
    signSelectProps,
    createSign,
    renderCreateSignOption,
    areSignsEqual,
    arrayContainsSign,
    maybeAddCreatedSignToArray,
    maybeDeleteCreatedSignFromArray,
} from "./sign";

import { SIGN_CLASSES, ISignClass } from './annotation-names'

// eslint-disable-next-line @typescript-eslint/ban-types
export interface IMultiSelectProps extends Props {
    /**
     * Identifier of this example.
     * This will appear as the `data-example-id` attribute on the DOM element.
     */
    allowCreate: boolean,
    hasInitialContent: boolean,
    id: string,
    fill: boolean,
    selectSign: (name: string) => void,
    deselectSign: (name: string) => void,
    clearSigns: () => void,
}

export interface IMultiSelectState {
    createdItems: ISignClass[];
    fill: boolean;
    signClasses: ISignClass[];
    intent: boolean;
    items: ISignClass[];
    openOnKeyDown: boolean;
    popoverMinimal: boolean;
    resetOnSelect: boolean;
    tagMinimal: boolean;
    filterActive: boolean;
}

const SignMultiSelect = MultiSelect.ofType<ISignClass>();

export class SignSelectBox extends React.PureComponent<IMultiSelectProps, IMultiSelectState> {

    public state: IMultiSelectState = {
        createdItems: [],
        fill: true,
        signClasses: [],
        intent: true,
        items: signSelectProps.items,
        openOnKeyDown: false,
        popoverMinimal: true,
        resetOnSelect: true,
        tagMinimal: true,
        filterActive: false,
    };

    /*
    constructor(props: IMultiSelectProps) {
        super(props);
    }
    */

    handleFilterClick() {
        this.setState({ filterActive: !this.state.filterActive });
    }

    render() {
        const { signClasses, tagMinimal, popoverMinimal, ...flags } = this.state;
        const getTagProps = (_value: React.ReactNode, index: number): TagProps => ({
            intent: this.state.intent ? INTENTS[index % INTENTS.length] : Intent.NONE,
            minimal: tagMinimal,
            large: false,
            multiline: false, style: { fontSize: '8pt' }
        });

        const initialContent = this.props.hasInitialContent ? (
            <MenuItem disabled={true} text={`${SIGN_CLASSES.length} items loaded.`} />
        ) : // explicit undefined (not null) for default behavior (show full list)
            undefined;
        const maybeCreateNewItemFromQuery = this.props.allowCreate ? createSign : undefined;
        const maybeCreateNewItemRenderer = this.props.allowCreate ? renderCreateSignOption : undefined;

        const clearButton =
            signClasses.length > 0
                ? <Button icon="cross" small minimal outlined onClick={this.handleClear} />
                : undefined;
        return (
            <div className="Filter">
                <Button icon="filter" text="" onClick={() => this.handleFilterClick()} />
                {this.state.filterActive &&
                    <SignMultiSelect
                        {...signSelectProps}
                        {...flags}
                        openOnKeyDown={false}
                        createNewItemFromQuery={maybeCreateNewItemFromQuery}
                        createNewItemRenderer={maybeCreateNewItemRenderer}
                        itemRenderer={this.renderSign}
                        itemsEqual={areSignsEqual}
                        noResults={<MenuItem disabled={true} text="No results." />}
                        onItemSelect={this.handleSignSelect}
                        onItemsPaste={this.handleSignPaste}
                        popoverProps={{
                            minimal: popoverMinimal,
                            fill: this.props.fill,
                        }}
                        tagRenderer={this.renderTag}
                        tagInputProps={{
                            onRemove: this.handleTagRemove,
                            rightElement: clearButton,
                            tagProps: getTagProps,
                        }}
                        initialContent={initialContent}
                        items={this.props.hasInitialContent === true ? this.state.items : []}
                        selectedItems={this.state.signClasses}
                        scrollToActiveItem={true}
                    />
                }
            </div>
        );
    };

    private renderTag = (sign: ISignClass) => sign.sign;

    private renderSign: ItemRenderer<ISignClass> = (sign, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        } else {
            return (
                <MenuItem
                    active={modifiers.active}
                    icon={this.isSignSelected(sign) ? "tick" : "blank"}
                    key={sign.sign}
                    label={sign.sign}
                    onClick={handleClick}
                    text={sign.sign}
                    shouldDismissPopover={true}
                />
            );
        }
    }

    private handleTagRemove = (_tag: React.ReactNode, index: number) => {
        this.deselectSign(index);
    }

    private getSelectedSignIndex(sign: ISignClass) {
        return this.state.signClasses.indexOf(sign);
    }

    private isSignSelected(sign: ISignClass) {
        return this.getSelectedSignIndex(sign) !== -1;
    }

    private selectSign(sign: ISignClass) {
        this.selectSigns([sign]);
        if (this.props.selectSign !== undefined) {
            this.props.selectSign(sign.sign);
        }
    }

    private selectSigns(signToSelect: ISignClass[]) {
        const { createdItems, signClasses, items } = this.state;

        let nextCreatedItems = createdItems.slice();
        let nextSignClasses = signClasses.slice();
        let nextItems = items.slice();

        signToSelect.forEach(sign => {
            const results = maybeAddCreatedSignToArray(nextItems, nextCreatedItems, sign);
            nextItems = results.items;
            nextCreatedItems = results.createdItems;
            // Avoid re-creating an item that is already selected (the "Create
            // Item" option will be shown even if it matches an already selected
            // item).
            nextSignClasses = !arrayContainsSign(nextSignClasses, sign) ? [...nextSignClasses, sign] : nextSignClasses;
        });

        this.setState({
            createdItems: nextCreatedItems,
            signClasses: nextSignClasses,
            items: nextItems,
        })
    }

    /**
     * This function was implemented based on the implementation from
     * https://github.com/palantir/blueprint/blob/develop/packages/docs-app/src/examples/select-examples/multiSelectExample.tsx
     * Function private deselectFilm(index: number)
     *
     * Function is invoked on deselecting a sign class within selection list
     * @param index
     */
    private deselectSign = (index: number) => {
        // get current sign classes list
        const { signClasses } = this.state;
        // get single sign class for deselection
        const signClass = signClasses[index];
        if (this.props.deselectSign !== undefined) {
            this.props.deselectSign(signClass.sign);
        }
        const { createdItems: nextCreatedItems, items: nextItems } = maybeDeleteCreatedSignFromArray(
            this.state.items,
            this.state.createdItems,
            signClass
        );
        // delete the item (selected sign class) if the user manually created it
        this.setState({
            createdItems: nextCreatedItems,
            signClasses: signClasses.filter((_signClass, i) => i !== index),
            items: nextItems,
        });
    }

    private handleSignSelect = (sign: ISignClass) => {
        if (!this.isSignSelected(sign)) {
            this.selectSign(sign);
        } else {
            this.deselectSign(this.getSelectedSignIndex(sign));
        }
    }

    private handleSignPaste = (signs: ISignClass[]) => {
        this.selectSigns(signs);
    };

    private handleClear = () => {
        this.setState({ signClasses: [] });
        if (this.props.clearSigns !== undefined) {
            this.props.clearSigns();
        }
    }
}

export default SignSelectBox