import { MenuItem } from "@blueprintjs/core";
import { ItemRenderer } from "@blueprintjs/select";
import * as React from "react";

export interface IModel {
    /** ID of tablet. */
    id: number;
    /** Name of tablet. */
    name: string;
    /** Type of the model. */
    type: string;
    /** Description of the model. */
    description: string;
    /** File name of the model. */
    filename: string;
}

export const renderModel: ItemRenderer<IModel> = (model, { handleClick, modifiers, query }) => {
    const text = `${model.name}`;
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={model.id}
            icon={'layers'}
            onClick={handleClick}
            text={text}
        />
    );
};

export const renderCreateTabletOption = (
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>,
) => (
    <MenuItem
        icon="add"
        text={`Create "${query}"`}
        active={active}
        onClick={handleClick}
        shouldDismissPopover={false}
    />
);

export const modelSelectProps = {
    itemRenderer: renderModel,
    items: new Array<IModel>(),
};

export function createModel(id: number, name: string, type: string, description: string, filename: string): IModel {
    return {
        id,
        name,
        type,
        description,
        filename,
    };
}

export function addModelToArray(tablets: IModel[], tabletToAdd: IModel) {
    return [...tablets, tabletToAdd];
}