import React from 'react';
import {
    HotkeysTarget2,
    Button,
    Popover,
    Radio,
    RadioGroup,
    Classes,
    Tooltip,
    Position,
    Intent
} from '@blueprintjs/core';
import { handleStringChange } from "@blueprintjs/docs-theme";

import TabletSelectBox from "./../tablet-select-box";
import { ITablet } from "./../tablet-select-box/tablet";
import FragmentSelectBox from "./../fragment-select-box";
import { IFragment } from "./../fragment-select-box/fragment";
import AnnotationCanvas from "./../annotation-canvas";

export interface IDocumentSelectProps {
    annotationCanvasTarget: AnnotationCanvas,
    onItemSelect: ((id: number, name: string, tabletName: string, type: string) => void),
    hideMenus: (() => void),
}

export interface IDocumentSelectState {
    disabled: boolean;
    isOpen: boolean;
    option: string;
    optionRepresentation: string;
    documentName: string;
    currentTablet: ITablet | undefined;
    currentFragment: IFragment | undefined;
}

class DocumentSelect extends React.PureComponent<IDocumentSelectProps, IDocumentSelectState> {
    popoverElement: React.RefObject<Popover> = React.createRef<Popover>();
    tooltipElement: React.RefObject<Tooltip> = React.createRef<Tooltip>();
    tabletSelectBoxElement: React.RefObject<TabletSelectBox> = React.createRef<TabletSelectBox>();
    fragmentSelectBoxElement: React.RefObject<FragmentSelectBox> = React.createRef<FragmentSelectBox>();
    annotationCanvasElement: AnnotationCanvas;

    private hotkeys = [
        {
            combo: "O",
            global: true,
            label: "Open Document",
            onKeyDown: () => this.handleButtionClick(),
        },

    ];

    constructor(props: IDocumentSelectProps, state: IDocumentSelectState) {
        super(props);
        this.annotationCanvasElement = props.annotationCanvasTarget;
        this.state = {
            disabled: false,
            isOpen: false,
            option: "one",
            optionRepresentation: "1",
            documentName: "Select a fragment from a tablet that shall be displayed. (Hotkey O)",
            currentTablet: undefined,
            currentFragment: undefined,
        };
    }

    getCurrentTablet() {
        return this.state.currentTablet;
    }

    getCurrentFragment() {
        return this.state.currentFragment;
    }

    getTablets() {
        if (this.tabletSelectBoxElement.current)
            this.tabletSelectBoxElement.current.getTablets()
    }

    handleTabletSelect(tablet: ITablet) {
        this.setState({ currentTablet: tablet });
        this.fragmentSelectBoxElement.current.getFragments(tablet.id, this.state.optionRepresentation);
    }

    handleFragmentSelect(fragment: IFragment) {
        console.log(fragment);
        this.setState({ currentFragment: fragment });
        this.setState({ documentName: this.state.currentTablet?.name + ": " + fragment.name });
        if (this.state.currentTablet?.name !== undefined) {
            this.props.onItemSelect(fragment.id, fragment.name, this.state.currentTablet?.name, this.state.optionRepresentation);
        } else {
            this.props.onItemSelect(fragment.id, fragment.name, "", this.state.optionRepresentation);
        }
        this.setState({ isOpen: false });
    }

    handleButtionClick() {
        this.props.hideMenus();
        this.setState({ isOpen: !this.state.isOpen })
    }

    componentDidMount() {
        this.getTablets();
    }

    handleOptionChange_Language = handleStringChange(option => this.setState({ option }));
    handleOptionChange_Representation = handleStringChange(optionRepresentation => this.setState({ optionRepresentation }));

    render() {
        return (
            <HotkeysTarget2 hotkeys={this.hotkeys}>
                {({ handleKeyDown, handleKeyUp }) => (
                    <div tabIndex={0} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
                        <Popover
                            popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                            ref={this.popoverElement}
                            portalClassName="document-select"
                            isOpen={this.state.isOpen}
                            enforceFocus={false}
                            autoFocus={true}
                            minimal={true}
                            content={
                                <div>
                                    <TabletSelectBox
                                        ref={this.tabletSelectBoxElement}
                                        initialItem={this.state.currentTablet}
                                        onItemSelect={(tablet: ITablet) => this.handleTabletSelect(tablet)}
                                    />
                                    <FragmentSelectBox
                                        ref={this.fragmentSelectBoxElement}
                                        initialItem={this.state.currentFragment}
                                        onItemSelect={(fragment: IFragment) => this.handleFragmentSelect(fragment)}
                                    />
                                    <Button
                                        className={Classes.MINIMAL}
                                        icon="cross"
                                        onClick={() => this.setState({ isOpen: false })}
                                        style={{
                                            right: "0px",
                                            position: "absolute",
                                            top: "0px"
                                        }}
                                    />
                                </div>
                            }
                        >
                            <Tooltip ref={this.tooltipElement} content={this.state.documentName} position={Position.BOTTOM}>
                                <Button
                                    icon="document-open"
                                    text="Open Document"
                                    active={this.state.isOpen}
                                    intent={this.state.currentTablet && this.state.currentFragment ? Intent.NONE : Intent.PRIMARY}
                                    onClick={() => this.handleButtionClick()}
                                />
                            </Tooltip>
                        </ Popover>
                    </div>
                )}
            </HotkeysTarget2>
        );
    }
/* removed from render()

                                    <RadioGroup
                                        label="Language (Not implemented)"
                                        onChange={this.handleOptionChange_Language}
                                        selectedValue={this.state.option}
                                    >
                                        <Radio label="Hittite" value="one" />
                                        <Radio label="Babylonian" value="two" />
                                        <Radio label="Old Assyrian" value="three" />
                                    </RadioGroup>
                                    <RadioGroup
                                        label="Representation (Not implemented)"
                                        onChange={this.handleOptionChange_Representation}
                                        selectedValue={this.state.optionRepresentation}
                                    >
                                        <Radio label="2D" value="1" />
                                        <Radio label="3D" value="2" />
                                    </RadioGroup>
*/
}

export default DocumentSelect;
