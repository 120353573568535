import React from "react";

import { Button, MenuItem, Props, Intent, TagProps } from "@blueprintjs/core";
import { ItemRenderer, MultiSelect } from "@blueprintjs/select";

import {
    INTENTS,
    ITarget,
    signSelectProps,
    renderCreateSignOption,
    areSignsEqual,
    arrayContainsSign,
    maybeAddCreatedSignToArray,
    maybeDeleteCreatedSignFromArray, TARGETS
} from "./target";

// eslint-disable-next-line @typescript-eslint/ban-types
export interface IMultiSelectProps extends Props {
    /**
     * Identifier of this example.
     * This will appear as the `data-example-id` attribute on the DOM element.
     */
    id: string;
}

export interface IMultiSelectState {
    allowCreate: boolean;
    createdItems: ITarget[];
    fill: boolean;
    signClasses: ITarget[];
    hasInitialContent: boolean;
    intent: boolean;
    items: ITarget[];
    openOnKeyDown: boolean;
    popoverMinimal: boolean;
    resetOnSelect: boolean;
    tagMinimal: boolean;
}

const SignMultiSelect = MultiSelect.ofType<ITarget>();

export class TargetProvider extends React.PureComponent<IMultiSelectProps, IMultiSelectState> {

    public state: IMultiSelectState = {
        allowCreate: false,
        createdItems: [],
        fill: true,
        signClasses: [],
        hasInitialContent: false,
        intent: true,
        items: signSelectProps.items,
        openOnKeyDown: false,
        popoverMinimal: true,
        resetOnSelect: false,
        tagMinimal: true,
    };

    appendClass(target: ITarget) {
        console.log("Called Append");
        if (!TARGETS.some(item => areSignsEqual(item, target))) {
            TARGETS.push(target);
        }
        console.log(TARGETS);
        this.selectSigns([target]);
        this.forceUpdate();
    }

    clear() {
        console.log("Cleared");
        TARGETS.splice(0, TARGETS.length);
    }

    getTargets() {
        return TARGETS;
    }

    render() {
        const { allowCreate, signClasses, hasInitialContent, tagMinimal, popoverMinimal, ...flags } = this.state;
        const getTagProps = (_value: React.ReactNode, index: number): TagProps => ({
            intent: this.state.intent ? INTENTS[index % INTENTS.length] : Intent.NONE,
            minimal: tagMinimal,
            large: false,
            multiline: false, style: { fontSize: '8pt' }
        });

        const initialContent = this.state.hasInitialContent ? (
            <MenuItem disabled={true} text={`${TARGETS.length} items loaded.`} />
        ) : // explicit undefined (not null) for default behavior (show full list)
            undefined;
        //const maybeCreateNewItemFromQuery = allowCreate ? createTarget : undefined;
        const maybeCreateNewItemRenderer = allowCreate ? renderCreateSignOption : undefined;

        const clearButton =
            signClasses.length > 0
                ? <Button icon="cross" small minimal outlined onClick={this.handleClear} />
                : undefined;

        return (
            <SignMultiSelect
                {...signSelectProps}
                {...flags}

                createNewItemRenderer={maybeCreateNewItemRenderer}
                initialContent={initialContent}
                itemRenderer={this.renderSign}
                itemsEqual={areSignsEqual}
                items={this.state.items}
                noResults={<MenuItem disabled={true} text="No results." />}
                onItemSelect={this.handleSignSelect}
                onItemsPaste={this.handleSignPaste}
                popoverProps={{
                    minimal: popoverMinimal,
                    fill: true,
                }}
                tagRenderer={this.renderTag}
                tagInputProps={{
                    onRemove: this.handleTagRemove,
                    rightElement: clearButton,
                    tagProps: getTagProps,
                }}
                selectedItems={this.state.signClasses}
                scrollToActiveItem={true}
            />
        );
    };

    private renderTag = (target: ITarget) => target.tablet + " / " + target.fragment;

    private renderSign: ItemRenderer<ITarget> = (sign, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        } else {
            return (
                <MenuItem
                    active={modifiers.active}
                    icon={this.isSignSelected(sign) ? "tick" : "blank"}
                    key={sign.id}
                    label={sign.tablet + sign.fragment}
                    onClick={handleClick}
                    text={sign.tablet + sign.fragment}
                    shouldDismissPopover={true}
                />
            );
        }
    }

    private handleTagRemove = (_tag: React.ReactNode, index: number) => {
        this.deselectSign(index);
    }

    private getSelectedSignIndex(sign: ITarget) {
        return this.state.signClasses.indexOf(sign);
    }

    private isSignSelected(sign: ITarget) {
        return this.getSelectedSignIndex(sign) !== -1;
    }

    private selectSign(sign: ITarget) {
        this.selectSigns([sign]);
    }

    private selectSigns(signToSelect: ITarget[]) {
        const { createdItems, signClasses, items } = this.state;

        let nextCreatedItems = createdItems.slice();
        let nextSignClasses = signClasses.slice();
        let nextItems = items.slice();

        signToSelect.forEach(sign => {
            const results = maybeAddCreatedSignToArray(nextItems, nextCreatedItems, sign);
            nextItems = results.items;
            nextCreatedItems = results.createdItems;
            // Avoid re-creating an item that is already selected (the "Create
            // Item" option will be shown even if it matches an already selected
            // item).
            nextSignClasses = !arrayContainsSign(nextSignClasses, sign) ? [...nextSignClasses, sign] : nextSignClasses;
        });

        this.setState({
            createdItems: nextCreatedItems,
            signClasses: nextSignClasses,
            items: nextItems,
        })

    }

    /**
     * This function was implemented based on the implementation from
     * https://github.com/palantir/blueprint/blob/develop/packages/docs-app/src/examples/select-examples/multiSelectExample.tsx
     * Function private deselectFilm(index: number)
     *
     * Function is invoked on deselecting a sign class within selection list
     * @param index
     */
    private deselectSign = (index: number) => {
        // get current sign classes list
        const { signClasses } = this.state;
        // get single sign class for deselection
        const signClass = signClasses[index];
        const { createdItems: nextCreatedItems, items: nextItems } = maybeDeleteCreatedSignFromArray(
            this.state.items,
            this.state.createdItems,
            signClass
        );
        // delete the item (selected sign class) if the user manually created it
        this.setState({
            createdItems: nextCreatedItems,
            signClasses: signClasses.filter((_signClass, i) => i !== index),
            items: nextItems,
        });
    }

    private handleSignSelect = (sign: ITarget) => {
        if (!this.isSignSelected(sign)) {
            this.selectSign(sign);
        } else {
            this.deselectSign(this.getSelectedSignIndex(sign));
        }
    }

    private handleSignPaste = (signs: ITarget[]) => {
        this.selectSigns(signs);
    };

    private handleClear = () => this.setState({ signClasses: [] });

}

export default TargetProvider