/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { MenuItem } from "@blueprintjs/core";
import { ItemPredicate, ItemRenderer } from "@blueprintjs/select";
import * as React from "react";

export interface ITablet {
    /** ID of tablet. */
    id: number;
    /** Name of tablet. */
    name: string;
    /** Number of fragments. */
    fragmentCount: number;
}

export const renderTablet: ItemRenderer<ITablet> = (tablet, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }
    const text = `${tablet.name}`;
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            label={tablet.fragmentCount.toString()}
            key={tablet.id}
            icon={'layers'}
            onClick={handleClick}
            text={highlightText(text, query)}
        />
    );
};

export const renderCreateTabletOption = (
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>,
) => (
        <MenuItem
            icon="add"
            text={`Create "${query}"`}
            active={active}
            onClick={handleClick}
            shouldDismissPopover={false}
        />
    );

export const filterTablet: ItemPredicate<ITablet> = (query, tablet, _index, exactMatch) => {
    const normalizedName = tablet.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedName === normalizedQuery;
    } else {
        return `${normalizedName}`.indexOf(normalizedQuery) >= 0;
    }
};

function highlightText(text: string, query: string) {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter(word => word.length > 0)
        .map(escapeRegExpChars);
    if (words.length === 0) {
        return [text];
    }
    const regexp = new RegExp(words.join("|"), "gi");
    const tokens: React.ReactNode[] = [];
    while (true) {
        const match = regexp.exec(text);
        if (!match) {
            break;
        }
        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
}

function escapeRegExpChars(text: string) {
    // TODO: Still same lines?
    //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
}

export const tabletSelectProps = {
    itemPredicate: filterTablet,
    itemRenderer: renderTablet,
    items: new Array<ITablet>(),
};

export function createTablet(id: number, name: string, fragmentCount: number): ITablet {
    return {
        id,
        name,
        fragmentCount,
    };
}

export function areTabletsEqual(tabletA: ITablet, tabletB: ITablet) {
    // Compare only the names (ignoring case) just for simplicity.
    return tabletA.name.toLowerCase() === tabletB.name.toLowerCase();
}

export function doesTabletEqualQuery(tablet: ITablet, query: string) {
    return tablet.name.toLowerCase() === query.toLowerCase();
}

export function arrayContainsTablet(tablets: ITablet[], tabletToFind: ITablet | undefined): boolean {
    if (tabletToFind === undefined) return false;
    return tablets.some((tablet: ITablet) => tablet.name === tabletToFind.name);
}

export function addTabletToArray(tablets: ITablet[], tabletToAdd: ITablet) {
    return [...tablets, tabletToAdd];
}

export function deleteTabletFromArray(tablets: ITablet[], tabletToDelete: ITablet) {
    return tablets.filter(tablet => tablet !== tabletToDelete);
}

export function maybeAddCreatedTabletToArrays(
    items: ITablet[],
    createdItems: ITablet[],
    tablet: ITablet,
): { createdItems: ITablet[]; items: ITablet[] } {
    const isNewlyCreatedItem = !arrayContainsTablet(items, tablet);
    return {
        createdItems: isNewlyCreatedItem ? addTabletToArray(createdItems, tablet) : createdItems,
        // Add a created tablet to `items` so that the tablet can be deselected.
        items: isNewlyCreatedItem ? addTabletToArray(items, tablet) : items,
    };
}

export function maybeDeleteCreatedTabletFromArrays(
    items: ITablet[],
    createdItems: ITablet[],
    tablet: ITablet | undefined,
): { createdItems: ITablet[]; items: ITablet[] } {
    const wasItemCreatedByUser = arrayContainsTablet(createdItems, tablet);
    if (tablet === undefined) return { createdItems, items };
    // Delete the item if the user manually created it.
    return {
        createdItems: wasItemCreatedByUser ? deleteTabletFromArray(createdItems, tablet) : createdItems,
        items: wasItemCreatedByUser ? deleteTabletFromArray(items, tablet) : items,
    };
}
