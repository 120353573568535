import { clientConfig } from './../../config.js';
import { customFetch } from "./../utils/utils";
import { ITarget } from "./../target-provider/target";

export type BoundingBox = {
    box: number[];
    confidence: number;
}

export type BoundingBoxWithClass = {
    box: number[];
    confidence: number;
    class: string;
}

class Webservices {
    apiURL: string = clientConfig.webApiProtocol + clientConfig.webApiUrl + ":" + clientConfig.webApiPort;

    private getStandardRequestHeader() {
        let requestHeaders: any = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token')
        };
        return requestHeaders;
    }

    getImageByName = (tabletName: string, fragmentName: string) => {
        let requestHeaders: any = { 'Authorization': localStorage.getItem('token') };

        //let url = clientConfig.webApiProtocol + clientConfig.webApiUrl + ":" + clientConfig.webApiPort + "/api/fragmentObject?tabletName=" + tabletName + "&fragmentName=" + fragmentName;
        let url = clientConfig.webApiProtocol + clientConfig.webApiUrl + ":" + clientConfig.webApiPort + "/api/imageForFragmentName?tabletName=" + tabletName + "&fragmentName=" + fragmentName;
        customFetch(url, {
            method: 'GET',
            headers: requestHeaders,
        }).then(response => response.blob())
            .then(blob => {
                return blob;
            });
    }

    getTabletAndFragmentName = (queryID: number) => {
        let requestHeaders: any = this.getStandardRequestHeader();

        var requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                query_id: queryID,
            })
        }

        return customFetch(this.apiURL + "/api/getTabletAndFragmentName", requestOptions)
            .then(response => {
                return response.json();
            });
    }

    getTargetDetailsForQuery = (queryID: number) => {
        let requestHeaders: any = this.getStandardRequestHeader();

        var requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                query_id: queryID,
            })
        }

        return customFetch(this.apiURL + "/api/getTargetDetailsForQuery", requestOptions)
            .then(response => {
                return response.json();
            });
    }

    getResultsForQbE = (queryID: number) => {
        let requestHeaders: any = this.getStandardRequestHeader();

        var requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                query_id: queryID,
            })
        }

        return customFetch(this.apiURL + "/api/getResultsForQbE", requestOptions)
            .then(response => {
                return response.json();
            });
    }

    getThumbsnailsForQBE = (queryID: number) => {
        let requestHeaders: any = this.getStandardRequestHeader();

        var requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                query_id: queryID,
            })
        }

        return customFetch(this.apiURL + "/api/getThumbnailsForQbE", requestOptions)
            .then(response => {
                return response.json();
            });
    }

    addAnnotation = (signclass: string, tablet_fragment_obj_id: number, bbox: number[]): Promise<Response> => {
        let requestHeaders: any = this.getStandardRequestHeader();

        var requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                tablet_fragment_obj_id: tablet_fragment_obj_id,
                sign_name: signclass,
                box: bbox
            })
        }

        return customFetch(this.apiURL + "/api/addAnnotation", requestOptions)
            .then(response => {
                return response.json();
            });
    }

    removeAnnotationFromProject = (
        objectId: number,
    ) => {
        let requestHeaders: any = this.getStandardRequestHeader();

        var requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                objectId: objectId,
            })
        }

        customFetch(this.apiURL + "/api/removeAnnotationFromProject", requestOptions)
            .then(response => {
                return response.json();
            });
    }

    deleteAnnotationSpecifiedByQueryObjectId = (
        queryObjectId: number,
    ) => {
        let requestHeaders: any = this.getStandardRequestHeader();

        var requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                queryObjectId: queryObjectId,
            })
        }

        customFetch(this.apiURL + "/api/deleteAnnotationSpecifiedByQueryObjectId", requestOptions)
            .then(response => {
                return response.json();
            });
    }

    uploadImage = (
        file: File,
        tablet: string,
        fragment: string
    ) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('tablet', tablet);
        formData.append('fragment', fragment);

        let requestHeaders: any = {
            'Authorization': localStorage.getItem('token')
        };

        var requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: formData
        }

        customFetch(this.apiURL + "/api/upload", requestOptions)
            .then(response => {
                console.log(response);
                //return response.json();
            }).then(results => {
            });
    }

    getHandMadeAnnotations = (
        query_id: number,
        callback_handmadeAnnotations: (results: string) => void
    ) => {
        let requestHeaders: any = this.getStandardRequestHeader();

        var requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                query_id: query_id
            })
        }

        try {
            customFetch(this.apiURL + "/api/getHandMadeAnnotations", requestOptions)
                .then(response => {
                    return response.json();
                }).then(results => {
                    callback_handmadeAnnotations(results.parsedResults);
                });
        } catch (error) {
            // No handmade annotations available
        }
    }

    getResultsForQuery = (
        query_id: number,
        task_type: number,
        callback_boxes: (results: string, useDifferentColors: boolean) => void,
        callback_thumbnails: (predictedBoxes: BoundingBox[], images: number[][]) => void,
    ) => {
        let requestHeaders: any = this.getStandardRequestHeader();

        var requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                query_id: query_id
            })
        }

        customFetch(this.apiURL + "/api/getResultsForQuery", requestOptions)
            .then(response => {
                return response.json();
            }).then(results => {
                if (task_type === 2) {
                    if (results.boxes === undefined) {
                        //console.log(results.results[0].boxes);
                        callback_boxes(results.results[0].boxes, false);
                        callback_thumbnails(results.results[0].thumbnails, results.results[0].boxes);
                    } else {
                        callback_boxes(results.boxes, false);
                        callback_thumbnails(results.thumbnails, results.boxes);
                    }
                } else {
                    callback_boxes(results, true);
                }
            });
    }

    /*
    getStateForQuery = (
        query_id: number,
        callback: (state: string) => void
    ) => {
        console.log("Getting state for query: " + query_id);
        let requestHeaders: any = this.getStandardRequestHeader();

        var requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                query_id: query_id
            })
        }

        customFetch(this.apiURL + "/api/getStateForQuery", requestOptions)
            .then(response => {
                return response.json();
            }).then(state => {
                console.log(state);
                callback(state);
        });
    }
    */

    queueTask = (
        task: number,
        user: string,
        tablets: Int16Array[] | ITarget[],
        params: string,
        model: number,
        callback: (state: string) => void
    ) => {
        console.log("Queuing task: " + task + " for user " + user + ". Refered tablets: " + tablets + ". Uses parameters: " + params);
        let requestHeaders: any = this.getStandardRequestHeader();
        console.log(tablets);
        const requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                task: task,
                user: user,
                tablets: tablets,
                params: params,
                model: model,
            })
        };

        console.log(Array.isArray(tablets));
        if (tablets.length === 1) {
            //customFetch(this.apiURL + "/api/queueTask", requestOptions)
            /* TODO: add comment XMLHttpsRequest */
            console.log("Queuing task with a single target");
        } else if (tablets.length > 1) {
            console.log("Queuing task with multiple targets");
        }
        customFetch(this.apiURL + "/api/queueQuery", requestOptions)
            .then(response => {
                return response.json();
            }).then(state => {
                console.log(state);
                callback(state);
            });
    }

    annotate = (
        tabletName: string,
        fragmentName: string,
        callback: (confidentPredictions: BoundingBoxWithClass[], lessConfidentPredictions: BoundingBoxWithClass[]) => void,
        success: () => void) => {
        console.log("Calling Web Service - task: annotate");
        let requestHeaders: any = this.getStandardRequestHeader();

        var targetRef = tabletName + "/" + fragmentName;
        var requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                type: "annotate",
                target_reference: targetRef
            })
        }

        customFetch(this.apiURL + "/api/annotate", requestOptions)
            .then(response => {
                return response.json();
            }).then(predictions => {
                var confidentPredictions: BoundingBoxWithClass[] = predictions.first;
                var lessConfidentPredictions: BoundingBoxWithClass[] = predictions.second;

                callback(confidentPredictions, lessConfidentPredictions);
                success();
            });
    }

    queryByExample = (
        tabletName: string,
        fragmentName: string,
        boundingBox: number[],
        callback: (predictedBoxes: BoundingBox[], images: number) => void,
        callback2: (predictedBoxes: BoundingBox[], images: number) => void) => {
        console.log("Calling Web Service - task: query by example");
        let requestHeaders: any = this.getStandardRequestHeader();

        var targetRef = tabletName + "/" + fragmentName;
        var requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                type: "querybyexample",
                image: targetRef,
                n_items: 10,
                query: [
                    boundingBox[1],
                    boundingBox[0],
                    boundingBox[3],
                    boundingBox[2]
                ]
            })
        }

        customFetch(this.apiURL + "/api/queryByExample", requestOptions)
            .then(response => {
                return response.json();
            }).then(predictions => {
                callback(predictions.bounding_boxes, predictions.thumbnails);
                callback2(predictions.bounding_boxes, predictions.thumbnails);
            });
    }

    classify = (
        tabletName: string,
        fragmentName: string,
        boundingBox: number[],
        callback: (prediction: string, confidence: number) => void) => {
        console.log("Calling Web Service - task: classify");
        let requestHeaders: any = this.getStandardRequestHeader();

        var targetRef = tabletName + "/" + fragmentName;
        var requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                type: "classify",
                target_reference: targetRef,
                target_bounding_box: "(" + boundingBox[0] + "," +
                    boundingBox[1] + "," +
                    boundingBox[2] + "," +
                    boundingBox[3] + ")"
            })
        }

        customFetch(this.apiURL + "/api/classify", requestOptions)
            .then(response => {
                return response.json();
            }).then(prediction => {
                console.log(prediction);
                callback(prediction.sign, prediction.conf);
            });
    }

    confirmAnnotationPredictions = (
        queryId: number
    ) => {
        let requestHeaders: any = this.getStandardRequestHeader();
        var requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                queryId: queryId
            })
        }
        customFetch(this.apiURL + "/api/confirmAnnotationPredictions", requestOptions)
            .then(response => {
                return response.json();
            }).then(prediction => {
                console.log(prediction);
            });
    }

    confirmQueryByExamplePredictions = (
        predictionsToConfirm: number[],
        signClass: string | undefined = undefined,
    ) => {
        let requestHeaders: any = this.getStandardRequestHeader();
        var requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                predictionsToConfirm: predictionsToConfirm,
                signClass: signClass
            })
        }
        customFetch(this.apiURL + "/api/confirmQueryByExamplePredictions", requestOptions)
            .then(response => {
                return response.json();
            }).then(prediction => {
                console.log(prediction);
            });
    }

    getAiModells = (
        callbackAiModells: (aiModells: any[]) => void,
    ) => {
        let requestHeaders: any = this.getStandardRequestHeader();
        var requestOptions = {
            method: 'POST',
            headers: requestHeaders
        }
        customFetch(this.apiURL + "/api/getAiModells", requestOptions)
            .then(response => {
                return response.json();
            }).then(aiModells => {
                callbackAiModells(aiModells.result);
            });
    }
}

export default Webservices;