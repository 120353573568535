export interface ISignClass {
	/** TODO: **/
	sign: string;
}

export const SIGN_CLASSES: ISignClass[] = [
	{sign: "AŠ"},
	{sign: "AŠ.AŠ"},
	{sign: "ḪAL"},
	{sign: "EŠ6"},
	{sign: "BAL"},
	{sign: "GÍR"},
	{sign: "GÍR-gunû"},
	{sign: "BÚR"},
	{sign: "TAR"},
	{sign: "AN"},
	{sign: "AŠ+ŠUR"},
	{sign: "MUG"},
	{sign: "ZADIM"},
	{sign: "BA"},
	{sign: "ZU"},
	{sign: "SU"},
	{sign: "SU/SU"},
	{sign: "ŠEN"},
	{sign: "ARAD"},
	{sign: "ÁRAD"},
	{sign: "ITI"},
	{sign: "ÍTI"},
	{sign: "ŠUBUR"},
	{sign: "ŠAḪ"},
	{sign: "KA"},
	{sign: "KAxGÍR"},
	{sign: "TU6"},
	{sign: "KAxTU"},
	{sign: "KAxRU"},
	{sign: "UŠ11"},
	{sign: "KAxBAR"},
	{sign: "NUNDUM"},
	{sign: "SU6"},
	{sign: "PÙ"},
	{sign: "KAxAD.KUG"},
	{sign: "KAxNE"},
	{sign: "KAxÚR"},
	{sign: "KAxKIB"},
	{sign: "KAxGAG"},
	{sign: "KAxUŠ"},
	{sign: "KAxPA"},
	{sign: "KAxGIŠ"},
	{sign: "KAxŠID"},
	{sign: "KAxÚ"},
	{sign: "KAxGA"},
	{sign: "KAxSAR"},
	{sign: "KAxÁŠ"},
	{sign: "KAxDÚB"},
	{sign: "KAxŠA"},
	{sign: "ŠUDU"},
	{sign: "KAxŠE"},
	{sign: "KAxUD"},
	{sign: "KAxPI"},
	{sign: "KAxERIM"},
	{sign: "BÚN"},
	{sign: "KAxḪAR"},
	{sign: "KAxU"},
	{sign: "KAxMI"},
	{sign: "KAx\"EŠ\""},
	{sign: "KAxIGI"},
	{sign: "KAxKI"},
	{sign: "EME"},
	{sign: "KAxŠÈ"},
	{sign: "KAxGUR7"},
	{sign: "NAG"},
	{sign: "GU7"},
	{sign: "KAxKADRA"},
	{sign: "KAxÀŠ"},
	{sign: "KAxSIG"},
	{sign: "KAxGU"},
	{sign: "KAxLUM"},
	{sign: "URU"},
	{sign: "URUxTU"},
	{sign: "UKKIN"},
	{sign: "URUxKÁR"},
	{sign: "BANŠUR"},
	{sign: "ŠÀKIR"},
	{sign: "ÚRU"},
	{sign: "URUxUL"},
	{sign: "ASARI"},
	{sign: "GIŠGAL"},
	{sign: "URUxA"},
	{sign: "URUxḪA"},
	{sign: "ÈRIM"},
	{sign: "URUxGU"},
	{sign: "LI"},
	{sign: "TU"},
	{sign: "KU4"},
	{sign: "GUR8"},
	{sign: "LA"},
	{sign: "APIN"},
	{sign: "MAḪ"},
	{sign: "PAB"},
	{sign: "PÚŠ"},
	{sign: "BÙLUG"},
	{sign: "PAB.IŠ"},
	{sign: "PAB.E"},
	{sign: "GÀM"},
	{sign: "MU"},
	{sign: "SÌLA"},
	{sign: "ŠEŠLAM"},
	{sign: "ZI/ZI"},
	{sign: "NÚMUN"},
	{sign: "ZI/ZI.ŠE"},
	{sign: "ZI/ZI.A"},
	{sign: "GIL"},
	{sign: "KÍD"},
	{sign: "NUN/NUN-gekreuzt"},
	{sign: "KÁD"},
	{sign: "KÀD"},
	{sign: "RU"},
	{sign: "NU"},
	{sign: "BAD"},
	{sign: "ÈŠE"},
	{sign: "ŠIR"},
	{sign: "ŠIR-tenû"},
	{sign: "NUMUN"},
	{sign: "NUMUN-gunû"},
	{sign: "TI"},
	{sign: "TI-tenû"},
	{sign: "DIN"},
	{sign: "MAŠ"},
	{sign: "BAR"},
	{sign: "BÁN"},
	{sign: "KÚNGA"},
	{sign: "IDIGNA"},
	{sign: "GÍDIM"},
	{sign: "MAŠ.MAN"},
	{sign: "AG"},
	{sign: "AGxŠÍTA"},
	{sign: "MÈ"},
	{sign: "MÁŠ"},
	{sign: "KUN"},
	{sign: "ḪU"},
	{sign: "U5"},
	{sign: "NAM"},
	{sign: "BURU5"},
	{sign: "IG"},
	{sign: "MUD"},
	{sign: "SA4"},
	{sign: "ŠÌTA"},
	{sign: "ZI"},
	{sign: "GI"},
	{sign: "GI+E"},
	{sign: "RI"},
	{sign: "NUN"},
	{sign: "NUN-tenû"},
	{sign: "TÙR"},
	{sign: "IMMAL"},
	{sign: "TÙRxUŠ"},
	{sign: "TÙRxNÍG"},
	{sign: "ŠILAM"},
	{sign: "ŠILAM/ŠILAM"},
	{sign: "KAB"},
	{sign: "ḪÚB"},
	{sign: "ḪUB"},
	{sign: "SUR"},
	{sign: "MÚŠ"},
	{sign: "MÙŠ"},
	{sign: "MÙŠxA"},
	{sign: "SED"},
	{sign: "MÙŠxZA"},
	{sign: "GAD"},
	{sign: "GAD.KÍD"},
	{sign: "AKKIL"},
	{sign: "UMBIN"},
	{sign: "SIG8"},
	{sign: "ŠINIG"},
	{sign: "KINDA"},
	{sign: "EN"},
	{sign: "ENME"},
	{sign: "EN/EN-gekreuzt"},
	{sign: "EN-quadrat"},
	{sign: "4xEN"},
	{sign: "GAŠMU"},
	{sign: "BURU14"},
	{sign: "DÀR"},
	{sign: "DIM"},
	{sign: "MUN"},
	{sign: "BULUG"},
	{sign: "MÉNBULUG"},
	{sign: "LÀL"},
	{sign: "KU7"},
	{sign: "SA"},
	{sign: "AŠGAB"},
	{sign: "GÁN"},
	{sign: "KÁR"},
	{sign: "GÚ"},
	{sign: "USAN"},
	{sign: "DUR"},
	{sign: "GUN"},
	{sign: "GUR"},
	{sign: "SI"},
	{sign: "SU4"},
	{sign: "DAR"},
	{sign: "SAG"},
	{sign: "SAGxNUN"},
	{sign: "SAGxUM"},
	{sign: "SAGxDU"},
	{sign: "SAGxGAG"},
	{sign: "SAGxNI"},
	{sign: "SAGxUŠ"},
	{sign: "DÌLIB"},
	{sign: "SAGxÚ"},
	{sign: "SAGxUB"},
	{sign: "SAGxSIG7"},
	{sign: "SAGxMI"},
	{sign: "SAGxUR"},
	{sign: "SAGxA"},
	{sign: "SAGxḪA"},
	{sign: "SAGxMUNUS"},
	{sign: "SAGxLUM"},
	{sign: "MÁ"},
	{sign: "DIMGUL"},
	{sign: "ÙZ"},
	{sign: "ÙZ.KASKAL"},
	{sign: "SUR9"},
	{sign: "SUR10"},
	{sign: "DIR"},
	{sign: "“nìq”"},
	{sign: "TAB"},
	{sign: "TAB-quadrat"},
	{sign: "TAB/TAB.NI/NI.DIŠ/DIŠ"},
	{sign: "EŠ21"},
	{sign: "MEGIDDA"},
	{sign: "GEŠTIN"},
	{sign: "GEŠTINxKUR"},
	{sign: "MÉGIDDA"},
	{sign: "LÍMMU"},
	{sign: "IA7"},
	{sign: "AŠ4"},
	{sign: "ÍMIN"},
	{sign: "ÚSSU"},
	{sign: "ÍLIMMU"},
	{sign: "TAG"},
	{sign: "TAGxGU4"},
	{sign: "TAGxŠU"},
	{sign: "TAGxUD"},
	{sign: "TAGxTÚG"},
	{sign: "KÁ"},
	{sign: "AB"},
	{sign: "ABxNUN"},
	{sign: "ABxKÁR"},
	{sign: "ABxŠEŠ"},
	{sign: "ABxÁŠ"},
	{sign: "URUGAL"},
	{sign: "ABxSIG7"},
	{sign: "URUDU"},
	{sign: "ABxU"},
	{sign: "ABxAŠ"},
	{sign: "UNUG"},
	{sign: "ABxKI"},
	{sign: "ABxLAGAB"},
	{sign: "ABxGÍN"},
	{sign: "NINA"},
	{sign: "ABxIMIN"},
	{sign: "UM"},
	{sign: "UMxU"},
	{sign: "URUDUxU"},
	{sign: "UMxU.LAGAB"},
	{sign: "URUDUxU.LAGAB"},
	{sign: "UMxME.DA"},
	{sign: "DUB"},
	{sign: "DUBxŠE"},
	{sign: "DUBxŠÀ"},
	{sign: "DUBxLAGAB"},
	{sign: "NAB"},
	{sign: "MUL"},
	{sign: "TA"},
	{sign: "TA-gunû"},
	{sign: "TAxḪI"},
	{sign: "TAxMI"},
	{sign: "I"},
	{sign: "GAN"},
	{sign: "KÁM"},
	{sign: "TUR"},
	{sign: "TUR.DIŠ"},
	{sign: "ZIZNA"},
	{sign: "AD"},
	{sign: "ZÍ"},
	{sign: "IA"},
	{sign: "IN"},
	{sign: "RAB"},
	{sign: "DIM8"},
	{sign: "DÌM"},
	{sign: "DIM10"},
	{sign: "LUGAL"},
	{sign: "DIM9"},
	{sign: "LÚ.LÚ-inversum"},
	{sign: "DIM11"},
	{sign: "ḪAŠḪUR"},
	{sign: "EZEN"},
	{sign: "EZENxAN"},
	{sign: "EZENxLI"},
	{sign: "EZENxLA"},
	{sign: "BÀD"},
	{sign: "EZENxSI"},
	{sign: "UBARA"},
	{sign: "UBARA-quadrat"},
	{sign: "EZENxGU4"},
	{sign: "EZENxÚ"},
	{sign: "EZENxMIR"},
	{sign: "EZENxSIG7"},
	{sign: "EZENxŠE"},
	{sign: "EZENxUD"},
	{sign: "EZENxKUG"},
	{sign: "ASILAL"},
	{sign: "EZENxLU"},
	{sign: "EZENxGÍN"},
	{sign: "ÀSILAL"},
	{sign: "SIL7"},
	{sign: "ASILAL4"},
	{sign: "EZENxḪA"},
	{sign: "SUM"},
	{sign: "NAGA"},
	{sign: "AN.NAGA.AN.NAGA-inversum"},
	{sign: "AN.NAGA-quadrat"},
	{sign: "NAGA-inversum"},
	{sign: "NAGA.NAGA-inversum"},
	{sign: "NAGAxŠU-tenû"},
	{sign: "NIB"},
	{sign: "UG"},
	{sign: "AZ"},
	{sign: "GAB"},
	{sign: "GAB.LIŠ"},
	{sign: "EDIN"},
	{sign: "TAḪ"},
	{sign: "KASKAL"},
	{sign: "ÌTI"},
	{sign: "ILLAT"},
	{sign: "KASKAL.BU"},
	{sign: "KASKAL.AḪ"},
	{sign: "KASKAL.LAGAB"},
	{sign: "KASKAL.TÚL/TÚL"},
	{sign: "KASKAL/KASKAL.TÚL/TÚL"},
	{sign: "ZIKURA"},
	{sign: "AM"},
	{sign: "AMxA"},
	{sign: "UZU"},
	{sign: "BÍL"},
	{sign: "NE"},
	{sign: "ÉMEŠ"},
	{sign: "ENTEN"},
	{sign: "NÍNDA"},
	{sign: "NÍNDAxAŠ"},
	{sign: "NÍNDAxAŠ.AŠ"},
	{sign: "NÍNDAxBAL"},
	{sign: "ŠÀM"},
	{sign: "NÍNDA.BÁN"},
	{sign: "NÍNDAxGI"},
	{sign: "SUMAŠ"},
	{sign: "AZU"},
	{sign: "NÍNDAxDUB?"},
	{sign: "ÁG"},
	{sign: "NÍNDAxGU4"},
	{sign: "NÍNDAxŠID?"},
	{sign: "NÍNDAxÚ"},
	{sign: "NÍNDAxÚ.AŠ"},
	{sign: "NÍNDAxŠE.AŠ"},
	{sign: "NÍNDAxŠE.AŠ.AŠ"},
	{sign: "ŠÁM"},
	{sign: "NÍNDAxU"},
	{sign: "NÍNDAxMAN"},
	{sign: "ZIG"},
	{sign: "NÍNDAxME.KÍD"},
	{sign: "GALAM"},
	{sign: "KUM"},
	{sign: "GAZ"},
	{sign: "ÚR"},
	{sign: "ÚRxNUN"},
	{sign: "ÚRxAL"},
	{sign: "ÚRxÚ"},
	{sign: "ÚRxÚ.AŠ"},
	{sign: "ÚRxA.ḪA"},
	{sign: "ÚRxḪA"},
	{sign: "IL"},
	{sign: "ILxKÁR"},
	{sign: "DU"},
	{sign: "LAḪ4"},
	{sign: "SUḪUŠ"},
	{sign: "KAŠ4"},
	{sign: "ANŠE"},
	{sign: "TUM"},
	{sign: "TUM-gunû & TUMxKÁR"},
	{sign: "EGIR"},
	{sign: "IŠ"},
	{sign: "BI"},
	{sign: "EŠEMIN5"},
	{sign: "BIxSIG7"},
	{sign: "BIxNÍG"},
	{sign: "ŠIM"},
	{sign: "ŠIMxBAL"},
	{sign: "ŠIMxBÚR"},
	{sign: "ŠIMxMUG"},
	{sign: "ŠIMxDIN"},
	{sign: "ŠIMxBULUG"},
	{sign: "ŠIMxSIG7"},
	{sign: "ŠIMxLUL"},
	{sign: "ŠIMxPI"},
	{sign: "ŠIMxIGI"},
	{sign: "ŠIMxA"},
	{sign: "ŠIMxNÍG"},
	{sign: "ŠIMxMUNUS"},
	{sign: "ŠIMxKÚŠU"},
	{sign: "GISAL"},
	{sign: "BÁNŠUR"},
	{sign: "KIB"},
	{sign: "GÁN/GÁN-gekreuzt"},
	{sign: "GIŠ/GIŠ-gekreuzt"},
	{sign: "GAG"},
	{sign: "NI"},
	{sign: "NIxE"},
	{sign: "UŠ"},
	{sign: "UŠxKÍD"},
	{sign: "UŠxKU"},
	{sign: "KÀŠ"},
	{sign: "NA4"},
	{sign: "DÀG"},
	{sign: "GÁ"},
	{sign: "ŠITA"},
	{sign: "GÁxAŠ"},
	{sign: "GÁxḪAL.LA"},
	{sign: "GÁxGÍR.SU"},
	{sign: "AMA"},
	{sign: "GÁxAN.GAG.A"},
	{sign: "GÁxKÍD"},
	{sign: "GÁxBAD"},
	{sign: "GÁxGI"},
	{sign: "GANUN"},
	{sign: "GÁxKAB"},
	{sign: "GÁxEN"},
	{sign: "GÁxBURU14"},
	{sign: "GÁxMUN"},
	{sign: "GÁxKÁR"},
	{sign: "GÁxDUB"},
	{sign: "GÁxSUM"},
	{sign: "GÁxKASKAL"},
	{sign: "GÁxIŠ.ḪU.AŠ"},
	{sign: "GÁxGAG"},
	{sign: "SILA4"},
	{sign: "GÁxKID.LAL"},
	{sign: "GÁxŠID"},
	{sign: "ÙR"},
	{sign: "GÁxGI4"},
	{sign: "GÁxSAR"},
	{sign: "GÁxÁŠ.GAL"},
	{sign: "GÁxBUR.RA"},
	{sign: "GÁxDA"},
	{sign: "GÁxSIG7"},
	{sign: "ÉSAG"},
	{sign: "GÁxŠE.TUR"},
	{sign: "GÁxUD"},
	{sign: "GÁxḪI.LI"},
	{sign: "GÁxU"},
	{sign: "GÁxÁB"},
	{sign: "ITIMA"},
	{sign: "GÁxDI"},
	{sign: "GÁxKUG.AN"},
	{sign: "MEN"},
	{sign: "GÁxA.DA.ḪA"},
	{sign: "ÉxA.DA.ḪA"},
	{sign: "GÁxA.IGI"},
	{sign: "GÁxḪA.LU.ŠÈ"},
	{sign: "GALGA"},
	{sign: "ARḪUŠ"},
	{sign: "GÁxEL.LA"},
	{sign: "ŠÍTA"},
	{sign: "KISAL"},
	{sign: "NI+GIŠ"},
	{sign: "IR"},
	{sign: "DAG"},
	{sign: "DAG.KISIM5"},
	{sign: "KIŠI8"},
	{sign: "DAG.KISIM5xLA"},
	{sign: "DAG.KISIM5xBÙLUG"},
	{sign: "DAG.KISIM5xKÍD"},
	{sign: "DAG.KISIM5xGI"},
	{sign: "DAG.KISIM5xSI"},
	{sign: "DAG.KISIM5xNE"},
	{sign: "DAG.KISIM5xBI"},
	{sign: "DAG.KISIM5xGAG"},
	{sign: "UTUA"},
	{sign: "ÙBUR"},
	{sign: "UBUR4"},
	{sign: "UTUL5"},
	{sign: "KIŠI9"},
	{sign: "DAG.KISIM5xÚ.MAŠ"},
	{sign: "UBUR"},
	{sign: "DAG.KISIM5xDÉ?"},
	{sign: "DAG.KISIM5xDÚB"},
	{sign: "DAG.KISIM5xAMAR"},
	{sign: "ÚBUR"},
	{sign: "AMAŠ"},
	{sign: "DAG.KISIM5xA.MAŠ"},
	{sign: "DAG.KISIMxḪA"},
	{sign: "DAG.KISIM5xLUM"},
	{sign: "PA"},
	{sign: "BANMIN"},
	{sign: "ŠAB"},
	{sign: "NUSKA"},
	{sign: "SIPA"},
	{sign: "GIŠ"},
	{sign: "GUR17"},
	{sign: "GIŠxBAD"},
	{sign: "GU4"},
	{sign: "GU4xKASKAL"},
	{sign: "AL"},
	{sign: "ALxKÍD?"},
	{sign: "ALxUŠ"},
	{sign: "ALxGIŠ"},
	{sign: "ALxAL"},
	{sign: "ALxŠE"},
	{sign: "ALxGIM"},
	{sign: "ALxKI"},
	{sign: "ALxḪA"},
	{sign: "MAR"},
	{sign: "KID"},
	{sign: "ŠID"},
	{sign: "MES"},
	{sign: "ŠIDxIM"},
	{sign: "MESxME & UMxME"},
	{sign: "ÚMBISAG"},
	{sign: "Ú"},
	{sign: "GA"},
	{sign: "GA-gunû"},
	{sign: "ÍL"},
	{sign: "LUḪ"},
	{sign: "É"},
	{sign: "KAL"},
	{sign: "KAL/KAL-gekreuzt"},
	{sign: "ALAD"},
	{sign: "E"},
	{sign: "E/E.NUN/NUN"},
	{sign: "DUG"},
	{sign: "KALAM"},
	{sign: "UN"},
	{sign: "NIR"},
	{sign: "GURUN"},
	{sign: "UB"},
	{sign: "EŠ16"},
	{sign: "LIMMU4"},
	{sign: "GI4"},
	{sign: "GIGI"},
	{sign: "ÚSAN"},
	{sign: "ÚSAN.GAG"},
	{sign: "RA"},
	{sign: "DÙL"},
	{sign: "IA9"},
	{sign: "LÚ"},
	{sign: "LÚ/LÚ"},
	{sign: "LÚ-quadrat"},
	{sign: "LÚ-tenû"},
	{sign: "LÚ-šeššig"},
	{sign: "LÚxBAD"},
	{sign: "LÚ-šeššigxBAD"},
	{sign: "LÚxKÁD"},
	{sign: "LÚxKÍD?"},
	{sign: "LÚxGÁN"},
	{sign: "LÚxNE"},
	{sign: "LÚxAL"},
	{sign: "LÚxŠU"},
	{sign: "LÚxKAM"},
	{sign: "LÚxIM"},
	{sign: "LÚxKI"},
	{sign: "LÚxME.EN"},
	{sign: "LÚxLAGAB"},
	{sign: "LÚxKU"},
	{sign: "LÚxTÚG"},
	{sign: "LÚxŠÈ-tenû"},
	{sign: "LÚxŠÈ.LAL"},
	{sign: "LÚxSÍG.BU"},
	{sign: "LÚ.GAM"},
	{sign: "ŠEŠ"},
	{sign: "AŠ9"},
	{sign: "ÌMIN"},
	{sign: "ÙSSU"},
	{sign: "ÌLIMMU"},
	{sign: "ZAG"},
	{sign: "SAR"},
	{sign: "UD-gunû"},
	{sign: "GÀR"},
	{sign: "LIL"},
	{sign: "MÚRU"},
	{sign: "SAG5"},
	{sign: "DÉ"},
	{sign: "DÉxKASKAL"},
	{sign: "DÉxPA"},
	{sign: "ÁŠ"},
	{sign: "BANEŠ"},
	{sign: "BANLIMMU"},
	{sign: "BANIA"},
	{sign: "MA"},
	{sign: "GAL"},
	{sign: "GAL.KINDA"},
	{sign: "BÁRA"},
	{sign: "GÚG"},
	{sign: "MIR"},
	{sign: "DUN4"},
	{sign: "GIR"},
	{sign: "BUR"},
	{sign: "Á "},
	{sign: "DA"},
	{sign: "GAŠAN"},
	{sign: "U-gunû"},
	{sign: "SIG7"},
	{sign: "DÚB"},
	{sign: "BALAG"},
	{sign: "ŠA"},
	{sign: "ŠU"},
	{sign: "KAD4"},
	{sign: "KAD5"},
	{sign: "NAR"},
	{sign: "LUL"},
	{sign: "SA6 "},
	{sign: "BÌŠEBA "},
	{sign: "ALAM"},
	{sign: "URI"},
	{sign: "GE23 "},
	{sign: "GAM"},
	{sign: "ILIMMU4"},
	{sign: "KUR"},
	{sign: "ŠE"},
	{sign: "BU"},
	{sign: "BU/BU-gekreuzt"},
	{sign: "SIRSIR "},
	{sign: "UZ "},
	{sign: "SUD"},
	{sign: "MUŠ"},
	{sign: "RI8 "},
	{sign: "TIR"},
	{sign: "NINNI5"},
	{sign: "TIR/TIR.GAD/GAD.NÍG/NÍG"},
	{sign: "ŠE/ŠE.GAD/GAD.NÍG/NÍG"},
	{sign: "ŠE/ŠE.TAB/TAB.NÍG/NÍG"},
	{sign: "U/U.SUR/SUR"},
	{sign: "U/U.PA/PA.NÍG/NÍG"},
	{sign: "TE"},
	{sign: "KAR "},
	{sign: "LIŠ"},
	{sign: ": (Glossenkeil)"},
	{sign: "TAB-tenû"},
	{sign: "1/4 (altassyrisch)"},
	{sign: "KAM (ḪIxBAD)"},
	{sign: "KAM (ḪIxBAD)-v"},
	{sign: "UD"},
	{sign: "ÍTIMA "},
	{sign: "PI"},
	{sign: "PI/PI-gekreuzt"},
	{sign: "PI+AB"},
	{sign: "PI+I"},
	{sign: "PI+BI"},
	{sign: "PI+Ú"},
	{sign: "PI+E"},
	{sign: "PI+BU"},
	{sign: "PI+U"},
	{sign: "PI+IB"},
	{sign: "PI+A"},
	{sign: "ŠÀ"},
	{sign: "ŠÀxBAD"},
	{sign: "ŠÀxTUR"},
	{sign: "ŠÀxNE"},
	{sign: "ŠÀxGIŠ"},
	{sign: "ŠÀxUD"},
	{sign: "ŠÀxU"},
	{sign: "BIR6 "},
	{sign: "ŠÀxMI"},
	{sign: "PEŠ4 "},
	{sign: "ŠÀxŠÚ"},
	{sign: "UD.MUNUS.ḪÚB"},
	{sign: "ÚḪ "},
	{sign: "ERIM"},
	{sign: "PÍR "},
	{sign: "NUNUZ"},
	{sign: "NUNUZ.ÁB & NUNUZ.KISIM5"},
	{sign: "LAḪTAN "},
	{sign: "LÁḪTAN "},
	{sign: "NUNUZ.ÁBxKÀD"},
	{sign: "ÙSAN "},
	{sign: "NUNUZ.ÁBxNE"},
	{sign: "MÙD "},
	{sign: "NUNUZ. ÁBxBI.U"},
	{sign: "NUNUZ.ÁBxGU4"},
	{sign: "NUNUZ.ÁBxÚ.BA"},
	{sign: "NUNUZ.ÁBxDUG"},
	{sign: "NUNUZ.ÁBxGÚG.BÙLUG"},
	{sign: "NUNUZ.ÁBxSIG7"},
	{sign: "ZIB"},
	{sign: "EŠ23"},
	{sign: "1/6 (altassyrisch)"},
	{sign: "ḪI"},
	{sign: "ŠÁR "},
	{sign: "TÍ "},
	{sign: "SÙR "},
	{sign: "A’"},
	{sign: "AḪ "},
	{sign: "AḪ.KASKAL"},
	{sign: "AḪ.ME"},
	{sign: "AḪ.ME.U"},
	{sign: "KAM "},
	{sign: "IM"},
	{sign: "IM/IM-gekreuzt"},
	{sign: "IM-quadrat"},
	{sign: "IM/IM-gekreuzt.IM/IM-gekreuzt"},
	{sign: "IMxKÍD"},
	{sign: "BIR "},
	{sign: "ḪAR "},
	{sign: "ḪUŠ "},
	{sign: "SUḪUR"},
	{sign: "GE22"},
	{sign: "ZUBUR"},
	{sign: "ŠÚŠUR "},
	{sign: "ŠÁRxGAD "},
	{sign: "ŠÁRxGAL.DIŠ"},
	{sign: "ŠÁRxGAL.MIN"},
	{sign: "ŠÁRxU"},
	{sign: "ŠÁRxU-gunû"},
	{sign: "ŠÁRxMAN"},
	{sign: "ŠÁRxEŠ"},
	{sign: "ŠÁRxNIMIN"},
	{sign: "ŠÁRxNINNU"},
	{sign: "ŠÁRxDIŠ "},
	{sign: "ḪIxKIN"},
	{sign: "U"},
	{sign: "U-gunû "},
	{sign: "UGU"},
	{sign: "UDUN"},
	{sign: "ŠIBIR"},
	{sign: "GÀKKUL "},
	{sign: "GAKKUL "},
	{sign: "U.GUR"},
	{sign: "U.DAR"},
	{sign: "SAGŠU "},
	{sign: "ÁB"},
	{sign: "UB5 "},
	{sign: "KIR6 "},
	{sign: "GIRI16 "},
	{sign: "LILIZ "},
	{sign: "LIBIŠ "},
	{sign: "KIŠ"},
	{sign: "MEZE "},
	{sign: "ÁBxA"},
	{sign: "MI"},
	{sign: "GUL"},
	{sign: "GIR4 "},
	{sign: "ŠAGAN "},
	{sign: "PAN"},
	{sign: "GIM "},
	{sign: "KISIM5"},
	{sign: "GINGIRA"},
	{sign: "DÚBUR "},
	{sign: "NÁ"},
	{sign: "NIM"},
	{sign: "TÙM "},
	{sign: "KIR7 "},
	{sign: "LAM"},
	{sign: "LAMxKUR"},
	{sign: "AMAR"},
	{sign: "SISKUR "},
	{sign: "AMARxKUG "},
	{sign: "UL"},
	{sign: "ŠITA4 "},
	{sign: "ÚTU "},
	{sign: "GÌR"},
	{sign: "PIRIG"},
	{sign: "GÌR.GÌR-inversum"},
	{sign: "LULIM "},
	{sign: "ALIM "},
	{sign: "DUGUD"},
	{sign: "GIG "},
	{sign: "NIGIN4 "},
	{sign: "NÌGIN "},
	{sign: "MAN "},
	{sign: "U.U-gunû"},
	{sign: "KUŠU "},
	{sign: "EŠ "},
	{sign: "NIMIN"},
	{sign: "MAŠGI oder BARGI "},
	{sign: "NINNU"},
	{sign: "Ux6"},
	{sign: "Ux7"},
	{sign: "Ux8"},
	{sign: "Ux9"},
	{sign: "LAGAR"},
	{sign: "DUL "},
	{sign: "DU6 "},
	{sign: "SU7 "},
	{sign: "LAGARxŠE.SUM"},
	{sign: "IGI"},
	{sign: "PÀD "},
	{sign: "AR "},
	{sign: "AGRIG "},
	{sign: "U6 "},
	{sign: "SIG5 "},
	{sign: "KURUM7 (IGI.ERIM)"},
	{sign: "Ù "},
	{sign: "LIBIR "},
	{sign: "ḪUL "},
	{sign: "ḪUL4 "},
	{sign: "KURUM7 (IGI.NÍG)"},
	{sign: "DI"},
	{sign: "KI"},
	{sign: "KIxBAD"},
	{sign: "KIxUD"},
	{sign: "KIxU"},
	{sign: "PÉŠ-v"},
	{sign: "KIMIN "},
	{sign: "KIŠI4 "},
	{sign: "ŠUL "},
	{sign: "KUG "},
	{sign: "PAD"},
	{sign: "15"},
	{sign: "DIŠ"},
	{sign: "NIGIDA "},
	{sign: "LAL"},
	{sign: "LÁL "},
	{sign: "PAPNUN (LÁL)"},
	{sign: "ME"},
	{sign: "MEŠ "},
	{sign: "LAGAB"},
	{sign: "ENGUR "},
	{sign: "ZIKUM "},
	{sign: "LAGABxAN"},
	{sign: "LAGABxKÍD"},
	{sign: "GIGIR "},
	{sign: "ÉSIR "},
	{sign: "LAGABxNUMUN.ḪI.A"},
	{sign: "LAGABxGI"},
	{sign: "LAGABxEN"},
	{sign: "LAGABxDAR"},
	{sign: "U8 "},
	{sign: "ZAR "},
	{sign: "UDUB "},
	{sign: "LAGABxBI"},
	{sign: "LAGABxUŠ"},
	{sign: "LAGABxŠÍTA"},
	{sign: "LAGABxGU4"},
	{sign: "LAGABxAL"},
	{sign: "LAGABxÚ.AŠ"},
	{sign: "LAGABxGA"},
	{sign: "ŠÁRA "},
	{sign: "LAGABxLUL"},
	{sign: "LAGABxGE23"},
	{sign: "LAGABxŠE.SUM"},
	{sign: "LAGABxMUŠ"},
	{sign: "LAGABxKAR.SU.NA"},
	{sign: "LAGABxLIŠ"},
	{sign: "LAGABxUD"},
	{sign: "LAGABxAḪ"},
	{sign: "LAGABxIM"},
	{sign: "TÚL "},
	{sign: "LAGABxU.A"},
	{sign: "BUL "},
	{sign: "LAGABxKI"},
	{sign: "GARIM "},
	{sign: "LAGABxME.EN"},
	{sign: "LAGABxLU"},
	{sign: "LAGABxKIN"},
	{sign: "SUG "},
	{sign: "LAGABxA.TAR"},
	{sign: "LAGABxA.DA.ḪA"},
	{sign: "LAGABxA.LAL"},
	{sign: "LAGABxA.NÍG"},
	{sign: "LAGABxḪA"},
	{sign: "LAGABxNÍG"},
	{sign: "LAGABxŠÚ"},
	{sign: "LAGABxŠÚ.ŠÚ"},
	{sign: "NIGIN "},
	{sign: "LAGAB-quadrat"},
	{sign: "NENNI "},
	{sign: "IB"},
	{sign: "KU"},
	{sign: "TÚG"},
	{sign: "ŠÈ"},
	{sign: "DIŠ+KU "},
	{sign: "LU"},
	{sign: "DIB"},
	{sign: "ÀD "},
	{sign: "KIN"},
	{sign: "SÍG"},
	{sign: "DARA4 "},
	{sign: "EREN "},
	{sign: "GUR7 "},
	{sign: "MUNŠUB "},
	{sign: "ŠÉŠ "},
	{sign: "MÚNŠUB "},
	{sign: "DIŠ.U"},
	{sign: "MIN"},
	{sign: "ŠUŠANA"},
	{sign: "TUK"},
	{sign: "UR"},
	{sign: "URBINGU"},
	{sign: "UR-šeššig"},
	{sign: "URxA"},
	{sign: "URxMIN"},
	{sign: "GIDIM"},
	{sign: "2+30 "},
	{sign: "ŠANABI"},
	{sign: "UDUG"},
	{sign: "EŠ5"},
	{sign: "UR4"},
	{sign: "GÍN "},
	{sign: "IŠŠEBU"},
	{sign: "KINGUSILA"},
	{sign: "A"},
	{sign: "AGAM "},
	{sign: "AxSAG"},
	{sign: "AxMUŠ"},
	{sign: "AxDU6 "},
	{sign: "AxIGI"},
	{sign: "EDURU "},
	{sign: "ZÀḪ "},
	{sign: "DIŠ/DIŠ"},
	{sign: "NIGIDAMIN"},
	{sign: "PAPNUN"},
	{sign: "NIGIDAEŠ"},
	{sign: "ZA"},
	{sign: "LIMMU5"},
	{sign: "NIGIDALIMMU"},
	{sign: "AD4 "},
	{sign: "GIŠTA'E "},
	{sign: "ḪA"},
	{sign: "ZUBUD "},
	{sign: "GUG "},
	{sign: "NÍG "},
	{sign: "LIMMU"},
	{sign: "IÁ"},
	{sign: "ÀŠ"},
	{sign: "IMIN"},
	{sign: "USSU"},
	{sign: "DIŠ/DIŠ/DIŠ"},
	{sign: "ILIMMU"},
	{sign: "ŠÚ"},
	{sign: "ÉN "},
	{sign: "KÈŠ "},
	{sign: "KUNGA "},
	{sign: "ŠEG8 "},
	{sign: "LIL5 "},
	{sign: "GÍBIL "},
	{sign: "ŠUDUN "},
	{sign: "ḪÚL"},
	{sign: "ŠEG9 "},
	{sign: "LÌL "},
	{sign: "ŠÚ.UR-šeššig"},
	{sign: "SIG"},
	{sign: "PÉŠ"},
	{sign: "MUNUS "},
	{sign: "ZUM"},
	{sign: "ZÚM "},
	{sign: "NIN9 "},
	{sign: "NIN "},
	{sign: "MIM+MA"},
	{sign: "DAM"},
	{sign: "GÉME "},
	{sign: "GU"},
	{sign: "SÙḪ "},
	{sign: "NAGAR"},
	{sign: "TUGUL "},
	{sign: "MUNUS.LAGAR"},
	{sign: "KÚŠU"},
	{sign: "ÉGI "},
	{sign: "NIG "},
	{sign: "EL"},
	{sign: "LUM"},
	{sign: "MÚRGU "},
	{sign: "LUM/LUM"},
	{sign: "LUM/LUM.ŠÚ"},
	{sign: "LÙGUD "},
	{sign: "SIG4"},
	{sign: "MURGU "},
	{sign: "SIG4/SIG4.ŠÚ"},
]
