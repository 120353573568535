import { clientConfig } from './../../config.js';

import React from 'react';
import { MenuItem, Button } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { customFetch } from "./../utils/utils";

import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";


import {
    areQueriesEqual,
    querySelectProps,
    createQuery,
    maybeAddCreatedQueryToArrays,
    IQuery,
    jsonQuery,
    maybeDeleteCreatedQueryFromArrays,
    renderCreateQueryOption,
    arrayContainsQuery,
} from "./query";
//import { MENU_ITEM_LABEL } from '@blueprintjs/core/lib/esm/common/classes';

const QuerySelect = Select.ofType<IQuery>();

export interface IQuerySelectProps {
    onItemSelect: ((query: IQuery) => void),
    username: string,
    initialItem: IQuery,
}

export interface IQuerySelectState {
    allowCreate: boolean;
    closeOnSelect: boolean;
    createdItems: IQuery[];
    disableItems: boolean,
    disabled: boolean,
    filterable: boolean,
    tablet: IQuery | undefined;
    items: IQuery[];
    minimal: boolean;
    openOnKeyDown: boolean;
    resetOnClose: boolean;
    resetOnQuery: boolean;
    resetOnSelect: boolean;
}

class QuerySelectBox extends React.PureComponent<IQuerySelectProps, IQuerySelectState> {
    tabletSelectElement = React.createRef<Select<IQuery>>();

    constructor(props: IQuerySelectProps, state: IQuerySelectState) {
        super(props);
        this.state = {
            allowCreate: false,
            closeOnSelect: true,
            createdItems: [],
            disableItems: false,
            disabled: false,
            filterable: true,
            tablet: props.initialItem,
            items: querySelectProps.items,
            minimal: true,
            openOnKeyDown: false,
            resetOnClose: false,
            resetOnQuery: true,
            resetOnSelect: false,
        };
    }

    getQueries(username: string) {
        var apiURL = clientConfig.webApiProtocol + clientConfig.webApiUrl + ":" + clientConfig.webApiPort;

        let requestHeaders: any = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token')
        };

        var requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                username: username
            })
        }

        let initialQueries = new Array<jsonQuery>();
        /*
        customFetch(apiURL + "/api/getQueryList", requestOptions)
            .then(response => {
                return response.json();
            }).then(data => {
                initialQueries = data.queries;
                querySelectProps.items = initialQueries.map(function (item) { return createQuery(item.query_id, item.tablet_id, item.task, item.state, item.params, 0, item.tablet_name) });
                querySelectProps.items.sort((a, b) => (a.query_id < b.query_id) ? 1 : -1); // sort by name
                this.setState({ items: querySelectProps.items });
                if (!arrayContainsQuery(querySelectProps.items, this.state.tablet)) {
                    this.setState({ tablet: undefined });
                }
        });
        */

        customFetch(apiURL + "/api/getTabletAndFragmentNameForFragmentObjectID", requestOptions)
        .then(response => {
            return response.json();
        }).then(data => {
            initialQueries = data.result[0];
            querySelectProps.items = initialQueries.map(function (item) {
                return createQuery(item.query_id, item.tablet_id, item.task, item.state, item.params, 0, item.tablet_name + " / " + item.tablet_fragment_name)
            });
            querySelectProps.items.sort((a, b) => (a.query_id < b.query_id) ? 1 : -1); // sort by name
            this.setState({ items: querySelectProps.items });
            if (!arrayContainsQuery(querySelectProps.items, this.state.tablet)) {
                this.setState({ tablet: undefined });
            }
        });
    }

    componentDidMount() {
        var username = localStorage.getItem('username');
        if (username !== null) {
            this.getQueries(username);
        }
    }

    render() {
        const { allowCreate, tablet, minimal, disabled, filterable, items,
            resetOnClose, resetOnQuery, resetOnSelect } = this.state;

        //const maybeCreateNewItemFromQuery = allowCreate ? createTablet : undefined;
        const maybeCreateNewItemRenderer = allowCreate ? renderCreateQueryOption : undefined;

        return (
            <>
                <label className="bp3-label">
                    Queries:
                    <QuerySelect
                        {...querySelectProps}
                        filterable={filterable}
                        resetOnClose={resetOnClose}
                        resetOnQuery={resetOnQuery}
                        resetOnSelect={resetOnSelect}
                        className="fragment-select"
                        //createNewItemFromQuery={maybeCreateNewItemFromQuery}
                        createNewItemRenderer={maybeCreateNewItemRenderer}
                        itemsEqual={areQueriesEqual}
                        ref={this.tabletSelectElement}
                        // we may customize the default tabletSelectProps.items by
                        // adding newly created items to the list, so pass our own.
                        items={items}
                        //activeItem={this.state.tablet}
                        noResults={<MenuItem disabled={true} text="No results." />}
                        initialContent={<MenuItem disabled={true} text={querySelectProps.items.length + " queries loaded."} />}
                        onItemSelect={this.handleValueChange}
                        popoverProps={{ minimal }}
                        scrollToActiveItem={false}
                    >
                        <Button
                            icon="layers"
                            rightIcon="caret-down"
                            text={tablet ? `${tablet.query_id} (${tablet.query_count})` : "(No selection)"}
                            disabled={disabled}
                        />
                    </QuerySelect>
                </label>
            </>
        );
    }

    private handleValueChange = (selectedTablet: IQuery) => {
        // delete the old tablet from the list if it was newly created
        const { createdItems, items } = maybeDeleteCreatedQueryFromArrays(
            this.state.items,
            this.state.createdItems,
            this.state.tablet,
        );
        // add the new tablet to the list if it is newly created
        const { createdItems: nextCreatedItems, items: nextItems } = maybeAddCreatedQueryToArrays(
            items,
            createdItems,
            selectedTablet,
        );
        // set state and notify parent when complete
        this.setState({ createdItems: nextCreatedItems, tablet: selectedTablet, items: nextItems },
            () => this.props.onItemSelect(selectedTablet)
        );
    };
}

export default QuerySelectBox;