import * as React from "react";
import { HotkeysTarget2, Button, Classes, Dialog, Intent, Tooltip, FormGroup, InputGroup, Position } from "@blueprintjs/core";

export interface IAnnotationPopupProps {
    onSuccess: ((signclass: string) => void),
    hideMenus: (() => void),
}

export interface IAnnotationPopupState {
    isOpen: boolean;
    signclass: string,
    disabled: boolean,
}

export class AnnotationPopUpAdd extends React.Component<IAnnotationPopupProps, IAnnotationPopupState> {
    signclassElement = React.createRef<HTMLInputElement>();
    tooltipElement: React.RefObject<Tooltip> = React.createRef<Tooltip>();

    constructor(props: IAnnotationPopupProps, state: IAnnotationPopupState) {
        super(props);
        this.state = {
            isOpen: false,
            signclass: '',
            disabled: false,
        };
    }

    private hotkeys = [
        {
            combo: "A",
            global: true,
            label: "Add Annotation",
            onKeyUp: () => {this.handleOpen();},
        },
    ];

    render() {
        return (
            <HotkeysTarget2 hotkeys={this.hotkeys}>
                {({ handleKeyDown, handleKeyUp }) => (
                    <div tabIndex={0} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
                        <Tooltip ref={this.tooltipElement} content={"Opens a menu to add an annotation to the marked region. (Hotkey A)"} position={Position.BOTTOM}>
                            <Button
                                icon="add"
                                intent={Intent.PRIMARY}
                                onClick={this.handleOpen}
                                disabled={this.state.disabled}
                            >
                                Add
                            </Button>
                        </Tooltip>
                        <Dialog
                            icon="git-push"
                            onClose={this.handleClose}
                            title="Add annotation"
                            {...this.state}
                        >
                            <form onSubmit={this.handleSubmit}>
                                <div className={Classes.DIALOG_BODY}>
                                    <FormGroup
                                        label="Sign class"
                                        labelFor="text-input"
                                        labelInfo="(required)">
                                        <InputGroup id="signclass-input" autoFocus inputRef={this.signclassElement} placeholder="Sign class" name="signclass" onChange={this.handleChange} />
                                    </FormGroup>
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        <Tooltip content="Close the dialog.">
                                            <Button onClick={this.handleClose}>Cancel</Button>
                                        </Tooltip>
                                        <Button intent={Intent.PRIMARY} rightIcon="git-push" type="submit">Add</Button>
                                    </div>
                                </div>
                            </form>
                        </Dialog>
                    </div>
                )}
            </HotkeysTarget2>
        );
    }

    private handleOpen = () => { 
        this.props.hideMenus();
        console.log(this.signclassElement.current?.innerText);
        this.setState({ isOpen: true });
    };

    private handleClose = () => this.setState({ isOpen: false });

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ signclass: event.target.value });
    }

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (this.state.signclass !== "") {
            this.props.onSuccess(this.state.signclass);
            this.handleClose();
        }
    }
}
export default AnnotationPopUpAdd;