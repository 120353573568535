import React from 'react';
import {
    Button,
    Popover,
    Tooltip,
    Position,
    Classes,
    Intent
} from '@blueprintjs/core';

import QuerySelectBox from "./../query-select-box";
import { IQuery } from "./../query-select-box/query";
import AnnotationCanvas from "./../annotation-canvas";

export interface IQuerySelectProps {
    annotationCanvasTarget: AnnotationCanvas,
    username: string,
    onItemSelect: ((query: IQuery) => void),
    hideMenus: (() => void),
}

export interface IQuerySelectState {
    disabled: boolean;
    isOpen: boolean;
    option: string;
    documentName: string;
    currentQuery: IQuery | undefined;
}

class QuerySelect extends React.PureComponent<IQuerySelectProps, IQuerySelectState> {
    popoverElement: React.RefObject<Popover> = React.createRef<Popover>();
    tooltipElement: React.RefObject<Tooltip> = React.createRef<Tooltip>();
    querySelectBoxElement: React.RefObject<QuerySelectBox> = React.createRef<QuerySelectBox>();

    constructor(props: IQuerySelectProps, state: IQuerySelectState) {
        super(props);
        this.state = {
            disabled: false,
            isOpen: false,
            option: "one",
            documentName: "Select one of your issued tasks to display the results.",
            currentQuery: undefined, 
        };
    }

    componentDidMount() {
        this.getQueries();
    }

    getQueries() {
        if (this.querySelectBoxElement.current)
            this.querySelectBoxElement.current.getQueries()
    }

    handleQuerySelect(query: IQuery) {
        this.props.onItemSelect(query);
    }

    render() {
        return (
            <>
                <Popover
                    popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                    ref={this.popoverElement}
                    portalClassName="query-select"
                    isOpen={this.state.isOpen}
                    enforceFocus={false}
                    autoFocus={true}
                    minimal={true}
                    content={
                        <div>
                            <QuerySelectBox
                                ref={this.querySelectBoxElement}
                                initialItem={this.state.currentQuery}
                                username={this.props.username}
                                onItemSelect={(query: IQuery) => this.handleQuerySelect(query)}
                            />
                        </div>
                    }
                    >
                    <Tooltip ref={this.tooltipElement} content={this.state.documentName} position={Position.BOTTOM}>
                        <Button
                            icon="document-open"
                            text="Query states"
                            active={this.state.isOpen}
                            intent={this.state.currentQuery ? Intent.NONE : Intent.PRIMARY}
                            onClick={() => { this.props.hideMenus(); this.setState({ isOpen: !this.state.isOpen }) }}
                        />
                    </Tooltip>
                </ Popover>
            </>
        );
    }
}

export default QuerySelect;