import React from 'react';
import {
    Button,
    Popover,
    Classes,
    Tooltip,
    Position,
    Intent
} from '@blueprintjs/core';

export interface IHelpMenuProps {

}

export interface IHelpMenuState {
    isOpen: boolean,
}

class HelpMenu extends React.PureComponent<IHelpMenuProps, IHelpMenuState>{
    popoverElement: React.RefObject<Popover> = React.createRef<Popover>();
    tooltipElement: React.RefObject<Tooltip> = React.createRef<Tooltip>();

    constructor(props: IHelpMenuProps, state: IHelpMenuState) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    render() {
        return (
            <>
                <Popover
                    popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                    ref={this.popoverElement}
                    portalClassName="document-select"
                    isOpen={this.state.isOpen}
                    enforceFocus={false}
                    autoFocus={true}
                    minimal={true}
                    content={
                        <div>
                            <div>
                                <b>Content:</b> <br />This website allows users to organize and maintain cuneiform documents. The most important feature of this website is the integration of an AI.
                                The AI currently offers 2 methods that users can issue using the graphical user interface (Queue Query):
                                <br />-Annotating: The AI can annotate images of tablets automatically.
                                <br />-Query by example: The AI can search for regions that are similar to a marked region.
                            </div>
                            <br />
                            <div>
                                <b>Info:</b> <br />The website is currently under development by members of the <a href="https://graphics.cs.tu-dortmund.de/en/">Computer graphics</a> chair of the <a href="https://www.cs.tu-dortmund.de/nps/en/Home/index.html">Faculty of Computer Science</a> at <a href="https://www.tu-dortmund.de/en/">TU Dortmund University</a>. <br />It was created within the scope of the DFG project 'CuKa'.
                            </div>
                            <br />
                            <div>
                                <b>Contact:</b> <br />Feel free to send an e-mail with feedback or a bugreport to the <a href="mailto:christopher.rest@cs.tu-dortmund.de">CuKaAI-Team</a>
                            </div>
                        </div>
                    }>
                    <Tooltip ref={this.tooltipElement} content={"Help menu"} position={Position.BOTTOM}>
                        <Button
                            icon="help"
                            text=""
                            active={this.state.isOpen}
                            intent={Intent.PRIMARY}
                            onClick={() => { this.setState({ isOpen: !this.state.isOpen }) }}
                        />
                    </Tooltip>
                </Popover>
            </>
        );
    }
}

export default HelpMenu;