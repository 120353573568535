import React from "react";
import {MenuItem, Intent} from "@blueprintjs/core";
import {ItemPredicate, ItemRenderer} from "@blueprintjs/select";

export const INTENTS = [Intent.NONE, Intent.PRIMARY, Intent.SUCCESS, Intent.DANGER, Intent.WARNING];

export interface ITarget {
    tablet: string;
    fragment: string;
    id: number;
}

export class Target implements ITarget {
    tablet: string = "";
    fragment: string = "";
    id: number = -1;
}

export const TARGETS: ITarget[] = [
]

export const renderCreateSignOption = (
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>,
) => (
    <MenuItem
        icon="add"
        text={`Create "${query}"`}
        active={active}
        onClick={handleClick}
        shouldDismissPopover={false}
    />
)

export function createTarget(tablet: string, fragment: string, id: number): Target {
    let result: Target = new Target();
    result.tablet = tablet;
    result.fragment = fragment;
    result.id = id;
    return result;
}

export function areSignsEqual(tabletA: ITarget, tabletB: ITarget): boolean {
    return  tabletA.tablet.toLowerCase() === tabletB.tablet.toLowerCase() &&
            tabletA.fragment.toLowerCase() === tabletB.fragment.toLowerCase();
}

export function arrayContainsSign(tablets: ITarget[], tabletToFind: ITarget): boolean {
    return tablets.some((tablet: ITarget) => tablet.tablet === tabletToFind.tablet && tablet.fragment === tabletToFind.fragment)
}

export function maybeAddCreatedSignToArray(
    items: ITarget[],
    createdItems: ITarget[],
    sign: ITarget
): { createdItems: ITarget[], items: ITarget[] } {
    // immediately invoke arrow function and evaluate boolean value if array (items) contains (sign)
    if (!arrayContainsSign(items, sign)) {
        createdItems.push(sign);
        items.push(sign);
    }
    return {createdItems, items};
}

export function maybeDeleteCreatedSignFromArray(
    items: ITarget[],
    createdItems: ITarget[],
    sign: ITarget
): { createdItems: ITarget[], items: ITarget[] } {

    if (arrayContainsSign(createdItems, sign)) {
        createdItems = createdItems.filter(item => item !== sign);
        items = items.filter(item => item !== sign);
    }
    ;
    return {createdItems, items};
}

export const filterSign: ItemPredicate<ITarget> = (query, target, _index, exactMatch) => {
    const normalizeSign = target.tablet.toLowerCase() + " / " + target.fragment.toLowerCase();
    const normalizeQuery = query.toLowerCase();
    if (exactMatch) {
        return normalizeSign === normalizeQuery;
    } else {
        return normalizeSign.indexOf(normalizeQuery) >= 0;
    }
}

export function highlightText(text: string, query: string) {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter(word => word.length > 0)
        .map(escapeRegExpChars);
    if (words.length === 0) {
        return [text];
    }
    const regexp = new RegExp(words.join("|"), "gi");
    const tokens: React.ReactNode[] = [];
    while (true) {
        const match = regexp.exec(text);
        if (!match) {
            break;
        }
        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
}

export function escapeRegExpChars(text: string) {
    // TODO: Still same lines?
    //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
}

export const renderSign: ItemRenderer<ITarget> = (target, {handleClick, modifiers, query}) => {
    if (!modifiers.matchesPredicate) {
        return null;
    } else {
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={target.tablet + " / " + target.fragment}
                key={target.id}
                onClick={handleClick}
                text={highlightText(target.tablet + " / " + target.fragment, query)}
            />
        )
    }
}

export const signSelectProps = {
    itemPredicate: filterSign,
    itemRenderer: renderSign,
    items: TARGETS,
}