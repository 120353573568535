/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { MenuItem } from "@blueprintjs/core";
import { ItemPredicate, ItemRenderer } from "@blueprintjs/select";
import * as React from "react";

export interface IFragment {
    /** ID of fragment. */
    id: number;
    /** Name of fragment. */
    name: string;
}

export const renderFragment: ItemRenderer<IFragment> = (fragment, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }
    const text = `${fragment.name}`;
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            label={"x"}
            key={fragment.id}
            icon={'layer'}
            onClick={handleClick}
            text={highlightText(text, query)}
        />
    );
};

export const renderCreateFragmentOption = (
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>,
) => (
        <MenuItem
            icon="add"
            text={`Create "${query}"`}
            active={active}
            onClick={handleClick}
            shouldDismissPopover={false}
        />
    );

export const filterFragment: ItemPredicate<IFragment> = (query, fragment, _index, exactMatch) => {
    const normalizedName = fragment.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedName === normalizedQuery;
    } else {
        return `${normalizedName}`.indexOf(normalizedQuery) >= 0;
    }
};

function highlightText(text: string, query: string) {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter(word => word.length > 0)
        .map(escapeRegExpChars);
    if (words.length === 0) {
        return [text];
    }
    const regexp = new RegExp(words.join("|"), "gi");
    const tokens: React.ReactNode[] = [];
    while (true) {
        const match = regexp.exec(text);
        if (!match) {
            break;
        }
        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
}

function escapeRegExpChars(text: string) {
    // TODO: Unneccessary excape characters. Still the same?
    // return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1"); 
    return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
}

export const fragmentSelectProps = {
    itemPredicate: filterFragment,
    itemRenderer: renderFragment,
    items: new Array<IFragment>(),
};

export function createFragment(id: number, name: string): IFragment {
    return {
        id,
        name,
    };
}

export function areFragmentsEqual(fragmentA: IFragment, fragmentB: IFragment) {
    // Compare only the names (ignoring case) just for simplicity.
    return fragmentA.name.toLowerCase() === fragmentB.name.toLowerCase();
}

export function doesFragmentEqualQuery(fragment: IFragment, query: string) {
    return fragment.name.toLowerCase() === query.toLowerCase();
}

export function arrayContainsFragment(fragments: IFragment[], fragmentToFind: IFragment | undefined): boolean {
    if (fragmentToFind === undefined) return false;
    return fragments.some((fragment: IFragment) => fragment.name === fragmentToFind.name);
}

export function addFragmentToArray(fragments: IFragment[], fragmentToAdd: IFragment) {
    return [...fragments, fragmentToAdd];
}

export function deleteFragmentFromArray(fragments: IFragment[], fragmentToDelete: IFragment) {
    return fragments.filter(fragment => fragment !== fragmentToDelete);
}

export function maybeAddCreatedFragmentToArrays(
    items: IFragment[],
    createdItems: IFragment[],
    fragment: IFragment,
): { createdItems: IFragment[]; items: IFragment[] } {
    const isNewlyCreatedItem = !arrayContainsFragment(items, fragment);
    return {
        createdItems: isNewlyCreatedItem ? addFragmentToArray(createdItems, fragment) : createdItems,
        // Add a created fragment to `items` so that the fragment can be deselected.
        items: isNewlyCreatedItem ? addFragmentToArray(items, fragment) : items,
    };
}

export function maybeDeleteCreatedFragmentFromArrays(
    items: IFragment[],
    createdItems: IFragment[],
    fragment: IFragment | undefined,
): { createdItems: IFragment[]; items: IFragment[] } {
    const wasItemCreatedByUser = arrayContainsFragment(createdItems, fragment);
    if (fragment === undefined) return { createdItems, items };
    // Delete the item if the user manually created it.
    return {
        createdItems: wasItemCreatedByUser ? deleteFragmentFromArray(createdItems, fragment) : createdItems,
        items: wasItemCreatedByUser ? deleteFragmentFromArray(items, fragment) : items,
    };
}
