import React from "react";

import {Suggest} from "@blueprintjs/select";
import {Button, Intent, MenuItem, Props} from "@blueprintjs/core";

import {
    IEpochSelect,
    epochSelectProps,
    createEpoch,
    renderCreateEpochOption,
    areEpochsEqual,
    maybeDeleteCreatedEpochFromArrays,
    maybeAddCreatedEpochToArrays
} from "./epoch";


export const INTENTS = [Intent.NONE, Intent.PRIMARY, Intent.SUCCESS, Intent.DANGER, Intent.WARNING];

const EpochSelect = Suggest.ofType<IEpochSelect>();

export interface IEpochSelectProps extends Props {
    id: string;
}

export interface IEpochSelectState {
    allowCreate: boolean;
    closeOnSelect: boolean;
    createdItems: IEpochSelect[];
    fill: boolean;
    epoch: IEpochSelect;
    items: IEpochSelect[];
    minimal: boolean;
    openOnKeyDown: boolean;
    resetOnClose: boolean;
    resetOnQuery: boolean;
    resetOnSelect: boolean;
}

export class EpochSelectBox extends React.PureComponent<IEpochSelectProps, IEpochSelectState> {

    public state: IEpochSelectState = {
        allowCreate: false,
        closeOnSelect: true,
        createdItems: [],
        fill: true,
        epoch: {epoch: ""},
        items: epochSelectProps.items,
        minimal: true,
        openOnKeyDown: false,
        resetOnClose: false,
        resetOnQuery: false,
        resetOnSelect: false,
    };

    public render() {

        const {allowCreate, epoch, minimal, ...flags} = this.state;

        const maybeCreateNewItemFromQuery = allowCreate ? createEpoch : undefined;
        const maybeCreateNewItemRenderer = allowCreate ? renderCreateEpochOption : undefined;

        return (
            <EpochSelect
                {...epochSelectProps}
                {...flags}
                selectedItem={this.state.epoch}
                createNewItemFromQuery={maybeCreateNewItemFromQuery}
                createNewItemRenderer={maybeCreateNewItemRenderer}
                inputValueRenderer={(epoch: IEpochSelect) => epoch.epoch}
                itemsEqual={areEpochsEqual}
                items={this.state.items}
                noResults={<MenuItem disabled={true} text="No results." />}
                onItemSelect={this.handleValueChange}
                popoverProps={{ minimal }}
                inputProps={{
                    placeholder: "Epoch...",
                    rightElement: <Button minimal outlined icon="cross" onClick={this.handleClearSelected}/>
                }}
            />
        )
    }

    private handleClearSelected = () => {
        console.log('Select all button clicked!');
        const epoch: IEpochSelect = {epoch: ""};
        this.setState({epoch});
    }

    private handleValueChange = (epoch: IEpochSelect) => {
        // delete the old epoch from the list if it was newly created
        const {createdItems, items} = maybeDeleteCreatedEpochFromArrays(
            this.state.items,
            this.state.createdItems,
            this.state.epoch,
        );

        // add the new epoch to the list if it is newly created
        const {createdItems: nextCreatedItems, items: nextItems} = maybeAddCreatedEpochToArrays(
            items,
            createdItems,
            epoch,
        );

        // change component state
        this.setState({
            createdItems: nextCreatedItems,
            epoch,
            items: nextItems
        })
    }
}

export default EpochSelectBox