import { clientConfig } from './../../config.js';

import React from 'react';
import ResizeObserver from 'react-resize-observer';
import Webservices from "./../webservices";
import { BoundingBoxWithClass, BoundingBox } from "./../webservices/webservices";
import { customFetch } from "./../utils/utils";

import './annotation-canvas.css';
import { ThreeDRotationSharp } from '@material-ui/icons';
//import { elementIsOrContains } from '@blueprintjs/core/lib/esm/common/utils';

export interface IAnnotationCanvasProps {
    path: string,
    onItemSelect: ((id: number, name: string) => void),
}

export interface IAnnotationCanvasState {
    // Selection Rect
    mouseCoordPressedX: number,
    mouseCoordPressedY: number,
    mouseCoordReleasedX: number,
    mouseCoordReleasedY: number,
    mouseCoordCurrentX: number,
    mouseCoordCurrentY: number,
    mouseDownLeft: boolean,

    // canvas navigation
    lastX: number,
    lastY: number,
    dragStart: DOMPoint | null,
    dragged: boolean,
    scaleFactor: number,

    // image
    src: string,
    imageLoaded: boolean,
    tabletFragmentObjId: number,

    // QueryByExample Values
    queryByExampleNumCandidates: number,
    queryByExampleMiNConfidence: number,

    // Ground truth bounding boxes
    canvas_bounding_boxes: canvasAnnotation[],

    // Predicted bounding boxes
    predicted_bounding_boxes: canvasAnnotation[],

    // Misc
    boxSelectMode: boolean,
    canvas_query_bounding_box: number[],
    hovered_bounding_box_id: number,
    selected_bounding_box_id: number,
    thumbnails: string[],
    show_classes: boolean,
    show_second_best: boolean,
    width: number,
    height: number,
    query_id: number,
    query_state: number,
    hideAnnotations: boolean,
    filter: string[],

    // What are these used for????
    bounding_boxes: BoundingBox[],
    annotations: BoundingBoxWithClass[],
    annotations_s: BoundingBoxWithClass[],
}

interface canvasVert {
    x: number,
    y: number
}

interface canvasBoundingBox {
    x: number,
    y: number,
    w: number,
    h: number
}

interface canvasAnnotation {
    id: number,
    db_id: number,
    name: string;
    bBox: canvasBoundingBox,
    verts: canvasVert[],
    color: string,
    sel_color: string,
    object_project: number,
}

const zeroPad = (num: string, places: number) => String(num).padStart(places, '0');
const minAnnotationSize: number = 3;

class AnnotationCanvas extends React.Component<IAnnotationCanvasProps, IAnnotationCanvasState> {
    canvasElement: React.RefObject<HTMLCanvasElement>;
    selectionCanvasElement: HTMLCanvasElement;
    webservices: Webservices;
    img: HTMLImageElement;
    colorsHash: Map<string, number>;
    canvas: any;
    ctx: any;
    selCanvas: any;
    selCtx: any;

    constructor(props: IAnnotationCanvasProps, state: IAnnotationCanvasState) {
        super(props);
        this.onloadFunction = this.onloadFunction.bind(this);

        this.canvasElement = React.createRef<HTMLCanvasElement>();
        this.selectionCanvasElement = document.createElement('canvas');
        this.webservices = new Webservices();
        this.img = new Image();
        this.colorsHash = new Map();

        //this.canvas => gets initialized in DidComponentUpdate
        //this.ctx => gets initialized in DidComponentUpdate

        // create selection hit buffer canvas
        this.selCanvas = this.selectionCanvasElement;
        this.selCtx = this.selCanvas.getContext('2d');

        this.state = {
            mouseCoordPressedX: 0,
            mouseCoordPressedY: 0,
            mouseCoordReleasedX: 0,
            mouseCoordReleasedY: 0,
            mouseCoordCurrentX: 0,
            mouseCoordCurrentY: 0,
            mouseDownLeft: false,
            src: "",
            tabletFragmentObjId: -1,
            scaleFactor: 1.1,
            boxSelectMode: false,
            bounding_boxes: [],
            canvas_bounding_boxes: [],
            predicted_bounding_boxes: [],
            hovered_bounding_box_id: -1,
            selected_bounding_box_id: -1,
            thumbnails: [],
            annotations: [],
            annotations_s: [],
            show_classes: true,
            show_second_best: false,
            imageLoaded: false,
            width: 1,
            height: 1,
            lastX: 0,
            lastY: 0,
            dragStart: null,
            dragged: false,
            query_id: -1, // TODO: FIX
            query_state: -1,
            hideAnnotations: false,
            filter: [],
            queryByExampleNumCandidates: 5000,
            queryByExampleMiNConfidence: 50,
            canvas_query_bounding_box: []
        };

        // function bindings
        this.canvasMouseDown = this.canvasMouseDown.bind(this);
        this.canvasMouseMove = this.canvasMouseMove.bind(this);
        this.canvasMouseUp = this.canvasMouseUp.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.loadAnnotations = this.loadAnnotations.bind(this);
    }

// =========================================================================================
// ========================= START Basic Canvas functions ==================================
// =========================================================================================
    redraw() {
        if (this.state.imageLoaded) {
            // Clear the entire canvas
            var p1 = this.ctx.transformedPoint(0, 0);
            var p2 = this.ctx.transformedPoint(this.canvas.width, this.canvas.height);
            this.ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);

            this.ctx.save();
            this.ctx.setTransform(1, 0, 0, 1, 0, 0);
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.ctx.restore();

            /* This should be done in order
                Draw Image
                Draw Ground-Truth-Annotations
                Draw Query-By-Example-Boxes
                Draw Selection-Rect
                Draw Query-Box
            */
            this.ctx.drawImage(this.img, 0, 0);

            // This function draws the GT-BoundingBoxes (these can be hidden)
            // canvas_bounding_boxes
            this.drawBoundingBoxes();
            
            // This function draws the QbE bounding box proposals (these can not be hidden)
            // this.state.predicted_bounding_boxes
            this.drawQueryByExamplePredictions(); 
            
            // Kann auskommentiert bleiben
            //this.drawAnnotations();

            this.drawSelectionRect();
            this.drawQueryBox();
        }
    }

    public resize(new_width: number, new_height: number) {
        this.setState({ width: new_width, height: new_height });

        const canvas: any = this.canvasElement.current
        //const selectionCanvas: any = this.selectionCanvasElement
        if (canvas) {
            let ctx: any = canvas.getContext('2d');
            // backup transform
            let tf = ctx.getTransform();

            // resize canvas
            canvas.width = this.state.width;
            canvas.height = this.state.height;

            // restore transform (not sure why this is necessary, tf before and after seems to be identical)
            ctx.setTransform(tf.a, tf.b, tf.c, tf.d, tf.e, tf.f);

            if (this.state.imageLoaded) {
                // Clear the entire canvas
                ctx.save();
                ctx.setTransform(1, 0, 0, 1, 0, 0);
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.restore();

                ctx.drawImage(this.img, 0, 0);
            }
        }
    }

    canvasMouseDown(evt: any) {
        if (this.state.boxSelectMode || evt.ctrlKey) {
            const rect = evt.target.getBoundingClientRect();
            const x = evt.clientX - rect.left
            const y = evt.clientY - rect.top
            var tp = this.ctx.transformedPoint(x, y);
            this.setState({ mouseDownLeft: true, mouseCoordPressedX: tp.x, mouseCoordPressedY: tp.y });
        } else {
            document.body.style.webkitUserSelect = document.body.style.userSelect = 'none';
            this.setState({
                lastX: evt.offsetX || (evt.pageX - this.canvas.offsetLeft),
                lastY: evt.offsetY || (evt.pageY - this.canvas.offsetTop),
                dragStart: this.ctx.transformedPoint(this.state.lastX, this.state.lastY),
                dragged: false
            });
        }
    }

    canvasMouseMove(evt: any) {
        const rect = evt.target.getBoundingClientRect();
        const x = evt.clientX - rect.left
        const y = evt.clientY - rect.top
        if (this.state.boxSelectMode || evt.ctrlKey) {
            if (this.state.mouseDownLeft) {
                var tmp = this.ctx.transformedPoint(x, y);
                this.setState({ mouseCoordCurrentX: tmp.x, mouseCoordCurrentY: tmp.y });
                this.redraw();
            }
        } else {
            this.setState({
                lastX: evt.offsetX || (evt.pageX - this.canvas.offsetLeft),
                lastY: evt.offsetY || (evt.pageY - this.canvas.offsetTop),
                dragged: true
            });
            if (this.state.dragStart) {
                var tp = this.ctx.transformedPoint(this.state.lastX, this.state.lastY);
                this.ctx.translate(tp.x - this.state.dragStart.x, tp.y - this.state.dragStart.y);
            }
            // set hovered bounding box
            let id = this.computeSelectedBoundingBox(x, y);
            this.setState({ hovered_bounding_box_id: id < 0 ? -1 : id });
            this.redraw();
        }
    }

    issueRedraw() {
        this.redraw();
    }

    canvasMouseUp(evt: any) {
        const rect = evt.target.getBoundingClientRect();
        const x = evt.clientX - rect.left
        const y = evt.clientY - rect.top
        if (this.state.boxSelectMode || evt.ctrlKey) {
            var tp = this.ctx.transformedPoint(x, y);
            this.setState({ mouseDownLeft: false, mouseCoordReleasedX: tp.x, mouseCoordReleasedY: tp.y });
        } else {
            this.setState({ dragStart: null });
            if (!this.state.dragged) {
                //if (!id) this.zoom(evt.shiftKey ? -1 : 1);
                // set selected bounding box
                let id = this.computeSelectedBoundingBox(x, y);
                if(id > -1) {
                    console.log(this.state.canvas_bounding_boxes[id]);
                }
                
                this.setState({ selected_bounding_box_id: id < 0 ? -1 : id });
                this.redraw();
            }
        }
    }

    public zoomFit() {
        // calulate horizontal and vertical scaling factors
        let dw: number = this.canvas.width / this.img.width;
        let dh: number = this.canvas.height / this.img.height;

        //let tr = this.ctx.getTransform();
        // apply smaller factor and center image
        if (dw < dh) {
            let center_offset_vertical = (this.canvas.height - (this.img.height * dw)) / 2.0;
            this.ctx.setTransform(dw, 0, 0, dw, 0, center_offset_vertical);
        } else {
            let center_offset_horizontal = (this.canvas.width - (this.img.width * dh)) / 2.0;
            this.ctx.setTransform(dh, 0, 0, dh, center_offset_horizontal, 0);
        }
        this.redraw();
    }
    
    zoom(clicks: any) {
        let pt = this.ctx.transformedPoint(this.state.lastX, this.state.lastY);
        this.ctx.translate(pt.x, pt.y);
        let factor = Math.pow(this.state.scaleFactor, clicks);
        this.ctx.scale(factor, factor);
        this.ctx.translate(-pt.x, -pt.y);
        this.redraw();
    }

    handleScroll(evt: any) {
        let delta = evt.wheelDelta ? evt.wheelDelta / 40 : evt.detail ? -evt.detail : 0;
        if (delta) this.zoom(delta);
        return evt.preventDefault() && false;
    }

    // Adds ctx.getTransform() - returns an SVGMatrix
    // Adds ctx.transformedPoint(x,y) - returns an SVGPoint
    trackTransforms(ctx: any) {
        var svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
        var xform: DOMMatrix | undefined = svg.createSVGMatrix();
        ctx.getTransform = function () { return xform; };
        ctx.imageSmoothingEnabled = false;

        var savedTransforms: DOMMatrix[] = [];
        var save = ctx.save;
        ctx.save = function () {
            if (xform)
                savedTransforms.push(xform.translate(0, 0));
            return save.call(ctx);
        };

        var restore = ctx.restore;
        ctx.restore = function () {
            xform = savedTransforms.pop();
            return restore.call(ctx);
        };

        var scale = ctx.scale;
        ctx.scale = function (sx: number, sy: number) {
            xform = xform?.scaleNonUniform(sx, sy);
            return scale.call(ctx, sx, sy);
        };

        var rotate = ctx.rotate;
        ctx.rotate = function (radians: number) {
            xform = xform?.rotate(radians * 180 / Math.PI);
            return rotate.call(ctx, radians);
        };

        var translate = ctx.translate;
        ctx.translate = function (dx: number, dy: number) {
            xform = xform?.translate(dx, dy);
            return translate.call(ctx, dx, dy);
        };

        var transform = ctx.transform;
        ctx.transform = function (a: number, b: number, c: number, d: number, e: number, f: number) {
            var m2 = svg.createSVGMatrix();
            m2.a = a; m2.b = b; m2.c = c; m2.d = d; m2.e = e; m2.f = f;
            xform = xform?.multiply(m2);
            return transform.call(ctx, a, b, c, d, e, f);
        };

        var setTransform = ctx.setTransform;
        ctx.setTransform = function (a: number, b: number, c: number, d: number, e: number, f: number) {
            if (xform) {
                xform.a = a;
                xform.b = b;
                xform.c = c;
                xform.d = d;
                xform.e = e;
                xform.f = f;
            }
            return setTransform.call(ctx, a, b, c, d, e, f);
        };

        var pt = svg.createSVGPoint();
        ctx.transformedPoint = function (x: number, y: number) {
            pt.x = x; pt.y = y;
            return pt.matrixTransform(xform?.inverse());
        }
        ctx.transformedPoint_Inv = function (x: number, y: number) {
            pt.x = x; pt.y = y;
            return pt;
        }
    }

    drawSelectionRect() {
        let scale = this.ctx.getTransform().a;

        this.ctx.beginPath();
        if (this.state.mouseDownLeft) {
            this.ctx.rect(this.state.mouseCoordPressedX,
                this.state.mouseCoordPressedY,
                this.state.mouseCoordCurrentX - this.state.mouseCoordPressedX,
                this.state.mouseCoordCurrentY - this.state.mouseCoordPressedY);
        } else {
            this.ctx.rect(this.state.mouseCoordPressedX,
                this.state.mouseCoordPressedY,
                this.state.mouseCoordReleasedX - this.state.mouseCoordPressedX,
                this.state.mouseCoordReleasedY - this.state.mouseCoordPressedY);
        }
        this.ctx.strokeStyle = "red";
        this.ctx.lineWidth = (2 / scale) > 2 ? (2 / scale) : 2;
        this.ctx.stroke();
    }

    public focusOnAnnotation(box: any) {
        /**
         * TODO: add function description
         *  @param {*} box - bounding box coordinates [x0, y0, x1, y1]
         */
        //console.log("Trying to focus on selection");
        //console.log(box);

        // get bounding box width and height
        let boxWidth = box[2] - box[0];
        let boxHeight = box[3] - box[1];
        let factor = 5.0;

        // calculate horizontal and vertical scaling factors
        let dw: number = this.canvas.width / boxWidth / factor;
        let dh: number = this.canvas.height / boxHeight / factor;

        let d = Math.min(dw, dh);
        let center_offset_horizontal = - (box[0] * d - (this.canvas.width - boxWidth * d) / 2);
        let center_offset_vertical = - (box[1] * d - (this.canvas.height - boxHeight * d) / 2);
        this.ctx.setTransform(d, 0, 0, d, center_offset_horizontal, center_offset_vertical);
        this.redraw();
    }

// =========================================================================================
// =========================== END Basic Canvas functions ==================================
// =========================================================================================

// =========================================================================================
// ========================= START Color functions =========================================
// =========================================================================================

    getRandomColor(): string {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    getHashedColor(str: string): string {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var c = ((14515249 * hash) & 0x00FFFFFF).toString(16).toUpperCase();

        return "#" + "00000".substring(0, 6 - c.length) + c;
    }

    /*
    randomBoundingBoxes() {
        this.colorsHash.clear();
        let boxes: canvasAnnotation[] = [];
        for (let i = 0; i < 1000; i++) {
            let px: number = Math.floor(Math.random() * (this.img.width - 100));
            let py: number = Math.floor(Math.random() * (this.img.height - 100));
            let c: string = this.getRandomColor();
            let sc: string = "#" + zeroPad(i.toString(16), 6); // unique selection color (rgb)

            let bb: canvasAnnotation = { id: i, db_id: -1,name: "", bBox: { x: px, y: py, w: 100, h: 100 }, verts: [], color: c, sel_color: sc };
            boxes.push(bb);
            this.colorsHash.set(sc, i);
        }
        this.setState({ canvas_bounding_boxes: boxes });
    }
    */

// =========================================================================================
// =========================== END Color functions =========================================
// =========================================================================================

// =========================================================================================
// ========================= START Callback functions ======================================
// =========================================================================================

    classifyCallback = (predictedClass: string, confidence: number) => {
        // TODO: What shall we do with a drunken sailor? 
        // Wo zeigen wir predictedClass und Confidence an?
        this.setState({});
    }

    //queryByExampleCallback = (predictedBoxes: BoundingBox[], images: string[]) => {
    //    this.setState({ bounding_boxes: predictedBoxes });
    //    //this.setState({thumbnails: images})
    //}


    annotateCallback = (confidentPredictions: BoundingBoxWithClass[], lessConfidentPredictions: BoundingBoxWithClass[]) => {
        this.setState({ annotations: confidentPredictions, annotations_s: lessConfidentPredictions });
    }

    taskQueueCallback = (state: any) => {
        this.setState({ query_id: state.id });
    }

    getStateCallback = (state: any) => {
        this.setState({ query_state: state.status });
    }

    getResultsAnnotateCallback = (annotations: any, useDifferentColors: boolean) => {
        this.setState({canvas_bounding_boxes: [], predicted_bounding_boxes: []}, () => this.loadResultsFromQuery(annotations, useDifferentColors, true));
        try {
            this.webservices.getHandMadeAnnotations(this.state.query_id, this.getHandmadeAnnotationsCallback);    
        } catch (error) {
            console.log("Caught failed to fetch error");
            // No handmade annotations available
        }        
    }

    getHandmadeAnnotationsCallback = (json: any, storeInCanvasBoundingBoxes: boolean = false, useDifferentColors: boolean = false) => {
        console.log("HandmadeAnnotations Callback");

        if (json) {
            
            this.colorsHash.clear();
            
            let boxes: canvasAnnotation[];            
            if (storeInCanvasBoundingBoxes === true) {
                boxes = this.state.canvas_bounding_boxes;
            } else {
                boxes = this.state.predicted_bounding_boxes;
            }
            
            if (json[0].Objects !== undefined) {
                // TESTING
                // TESTING
                // TESTING
                // TESTING
                // TESTING
                type vert = { ord: number, x: number, y: number }
                type a_object = { anno: number, verts: vert[], object_id: number }
                type annotation = { Objects: a_object[], sign: string }

                let annotations: annotation[] = json;
                for (let i = 0; i < annotations.length; i++) {
                    let element: annotation = annotations[i];
                    let name = element.sign;
                    let verts = element.Objects[0].verts;
                    if (verts.length > 0) {
                        let minX = verts[0].x;
                        let maxX = verts[0].x;
                        let minY = verts[0].y;
                        let maxY = verts[0].y;
                        verts.forEach(vertex => {
                            minX = (vertex.x < minX) ? vertex.x : minX;
                            maxX = (vertex.x > maxX) ? vertex.x : maxX;
                            minY = (vertex.y < minY) ? vertex.y : minY;
                            maxY = (vertex.y > maxY) ? vertex.y : maxY;
                        });
                        let c: string = "";
                        if (useDifferentColors) {
                            c = this.getHashedColor(name);
                        } else {
                            if (i === 0) {
                                //c = "#0000FF";
                                // Testweise
                                c = "#FF0000";
                            } else {
                                c = "#FF0000";
                            }
                            if (storeInCanvasBoundingBoxes === false) {
                                c = "#FF00FF";
                            }
                        }
                        let sc: string = "#" + zeroPad(i.toString(16), 6); // unique selection color (rgb)
                        let bb: canvasAnnotation = { id: i, db_id: element.Objects[0].object_id, name: name, bBox: { x: minX, y: minY, w: maxX - minX, h: maxY - minY }, verts: [], color: c, sel_color: sc, object_project: -1 };
                        bb.verts = verts.map(v => ({ x: Math.floor(v.x), y: Math.floor(v.y) }));
                        boxes.push(bb);
                        this.colorsHash.set(sc, i);
                    }
                }
                // TESTING
                // TESTING
                // TESTING
                // TESTING
                // TESTING
            } else {
                type annotation = { id: number, sign: string, confidence: number, box: number[], object_project: number }
                let annotations: annotation[] = json;

	            for (let i = 0; i < annotations.length; i++) {                
	                let element: any = annotations[i];                
	                
	                let db_id = element[0];
	                let name = element[1];
	                let verts = element[3];
	                let minX = verts[0];
	                let maxX = verts[2];
	                let minY = verts[1];
	                let maxY = verts[3];
	                
	                let c: string = "";
	                if (useDifferentColors) {
	                    c = this.getHashedColor(name);
	                } else {
	                    if (i===0) {
	                        //c = "#0000FF";
	                        // Testweise
	                        c = "#FF0000";
	                    } else {
	                        c = "#FF0000";
	                    }
	                    if (storeInCanvasBoundingBoxes === false) {
	                        c = "#FF00FF";
	                    }
	                }
	                let sc: string = "#" + zeroPad(i.toString(16), 6); // unique selection color (rgb)
	                let bb: canvasAnnotation = { id: i, db_id: db_id, name: name, bBox: { x: minX, y: minY, w: maxX - minX, h: maxY - minY }, verts: [], color: c, sel_color: sc, object_project: element[4] };
	                //bb.verts = verts.map(v => ({ x: Math.floor(v.x), y: Math.floor(v.y) }));
	                boxes.push(bb);
	                this.colorsHash.set(sc, i);   
	            }
            }
            
            if (storeInCanvasBoundingBoxes === true) {
                this.setState({ canvas_bounding_boxes: boxes }, () => {console.log(this.state.canvas_bounding_boxes); this.updateGhostCanvas()});
            } else {
                this.setState({ predicted_bounding_boxes: boxes }, () => this.updateGhostCanvas());
            }
        }        
    }

    getResultsQbECallback = (annotations: any, useDifferentColors: boolean) => {
        //console.log(annotations);
        this.setState({predicted_bounding_boxes: []}, () => this.loadResultsFromQuery(annotations, useDifferentColors, false));
    }

    getResultsCallback = (annotations: any, useDifferentColors: boolean) => {
        //this.setState({ query_state: state.status });
        //this.loadResultsFromQuery(annotations, useDifferentColors);
    }

// =========================================================================================
// =========================== END Callback functions ======================================
// =========================================================================================

// =========================================================================================
// =========================== START getImage functions ====================================
// =========================================================================================
    
    onloadFunction(imageName: string) {
        var _this = this;
        var canvas = this.canvas;
        var ctx = this.ctx;
        var selectionCanvas = this.selCanvas;

        _this.img = new Image();
        _this.img.onload = function () {
            selectionCanvas.width = _this.img.width;
            selectionCanvas.height = _this.img.height;

            _this.resize(_this.state.width, _this.state.height);

            _this.setState({
                imageLoaded: true,
                lastX: _this.canvas.width / 2,
                lastY: _this.canvas.height / 2
            });
            ctx.drawImage(_this.img, 0, 0);

            // zoom on whole image
            _this.zoomFit();

            //_this.randomBoundingBoxes();
            _this.updateGhostCanvas();
        }
        _this.img.onerror = function () {
            _this.img = new Image();
            // clear canvas
            ctx.save();
            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.restore();
            _this.setState({ imageLoaded: false });
            // maybe display error image?

            console.log("Error loading image " + this.src);
        }
        _this.img.src = this.state.src;
    }

    public getImage(id: number, name: string, useAnnotations: boolean) {
        var _onloadFunction = this.onloadFunction;
        let requestHeaders: any = { 'Authorization': localStorage.getItem('token') };
        
        // Retrieve the image from the backend and display it
        let url = clientConfig.webApiProtocol + clientConfig.webApiUrl + ":" + clientConfig.webApiPort + "/api/imageForFragmentId?id=" + id;
        customFetch(url, {
            method: 'GET',
            headers: requestHeaders,
        }).then(response => response.blob())
            .then(blob => {
                this.clearAnnotations();
                this.setState({ src: URL.createObjectURL(blob) }, () => _onloadFunction(name));
        });

        // Get the tablet_fragment_obj_id and issue the loading of the annotations
        url = clientConfig.webApiProtocol + clientConfig.webApiUrl + ":" + clientConfig.webApiPort + "/api/fragmentObjectIdForFragmentId?id=" + id;
        customFetch(url, {
            method: 'GET',
            headers: requestHeaders,
        }).then(response => response.json())
            .then(json => {
                if(useAnnotations) {
                    // Load the annotations
                    this.setState({canvas_bounding_boxes: [], predicted_bounding_boxes:[], tabletFragmentObjId: json.message.tablet_fragment_obj_id}, () => {console.log(this.state.tabletFragmentObjId);});
                    this.getAnnotations(json.message.tablet_fragment_obj_id);
                } else {
                    this.setState({tabletFragmentObjId: json.message.tablet_fragment_obj_id}, () => {console.log(this.state.tabletFragmentObjId);});
                }
            }
        );
    }

    // Load the annotations
    public getAnnotations(id: number) {
        var _loadAnnotations = this.loadAnnotations;
        let requestHeaders: any = { 'Authorization': localStorage.getItem('token') };
        customFetch(clientConfig.webApiProtocol + clientConfig.webApiUrl + ":" + clientConfig.webApiPort + "/api/annotationsForProject?id=" + id, {
            method: 'GET',
            headers: requestHeaders,
        }).then(response => response.json())
            .then(json => {
                console.log(json);
                // Display the loaded annotations
                _loadAnnotations(json);
            });
    }

    loadAnnotations(json: any) {
        type vert = { ord: number, x: number, y: number }
        type a_object = { anno: number, verts: vert[], object_id: number }
        type annotation = { Objects: a_object[], sign: string }
        if (json && json.annotations) {
            let annotations: annotation[] = json.annotations;
            this.colorsHash.clear();
            let boxes: canvasAnnotation[] = this.state.canvas_bounding_boxes;
            for (let i = 0; i < annotations.length; i++) {
                let element: annotation = annotations[i];
                //console.log(element.Objects[0].object_id);
                let name = element.sign;
                let verts = element.Objects[0].verts;
                if (verts.length > 0) {
                    let minX = verts[0].x;
                    let maxX = verts[0].x;
                    let minY = verts[0].y;
                    let maxY = verts[0].y;
                    verts.forEach(vertex => {
                        minX = (vertex.x < minX) ? vertex.x : minX;
                        maxX = (vertex.x > maxX) ? vertex.x : maxX;
                        minY = (vertex.y < minY) ? vertex.y : minY;
                        maxY = (vertex.y > maxY) ? vertex.y : maxY;
                    });
                    let c: string = this.getHashedColor(name);
                    let sc: string = "#" + zeroPad(i.toString(16), 6); // unique selection color (rgb)
                    let bb: canvasAnnotation = { id: i, db_id: element.Objects[0].object_id, name: name, bBox: { x: minX, y: minY, w: maxX - minX, h: maxY - minY }, verts: [], color: c, sel_color: sc, object_project: -1};
                    bb.verts = verts.map(v => ({ x: Math.floor(v.x), y: Math.floor(v.y) }));
                    boxes.push(bb);
                    this.colorsHash.set(sc, i);
                }
            }            
            //console.log("CALLING getImage");
            this.setState({ canvas_bounding_boxes: boxes }, () => {this.updateGhostCanvas()});
        }
    }

    clearAnnotations() {
        this.colorsHash.clear();
        let boxes: canvasAnnotation[] = [];
        this.setState({ canvas_bounding_boxes: boxes }, () => this.updateGhostCanvas());
    }

    // =========================================================================================
    // ============================= END getImage functions ====================================
    // =========================================================================================

    public setBoxSelectMode(value: boolean) {
        this.setState({ boxSelectMode: value });
    }

    drawAnnotations() {
        if (this.state.annotations.length > 0) {
            if (this.state.show_second_best === false) {
                this.state.annotations.forEach(element => {
                    if (this.state.filter === []) {
                        this.ctx.beginPath();
                        this.ctx.rect(element.box[0],
                            element.box[1],
                            element.box[2] - element.box[0],
                            element.box[3] - element.box[1]);
                        this.ctx.strokeStyle = "blue";
                        this.ctx.lineWidth = (5 / this.ctx.scale) > 1 ? (5 / this.ctx.scale) : 1;
                        this.ctx.stroke();
                    } else {
                        if (this.state.filter.includes(element.class)) {
                            this.ctx.beginPath();
                            this.ctx.rect(element.box[0],
                                element.box[1],
                                element.box[2] - element.box[0],
                                element.box[3] - element.box[1]);
                            this.ctx.strokeStyle = "blue";
                            this.ctx.lineWidth = (5 / this.ctx.scale) > 1 ? (5 / this.ctx.scale) : 1;
                            this.ctx.stroke();
                        }
                    }
                });
                if (this.state.show_classes) {
                    this.state.annotations.forEach(element => {
                        if (this.state.filter === []) {
                            this.ctx.font = "20px Arial";
                            this.ctx.fillStyle = "red";
                            this.ctx.fillText(element.class, element.box[0] + 10, element.box[1] + 25)
                        } else {
                            if (this.state.filter.includes(element.class)) {
                                this.ctx.font = "20px Arial";
                                this.ctx.fillStyle = "red";
                                this.ctx.fillText(element.class, element.box[0] + 10, element.box[1] + 25)
                            }
                        }
                    });
                }
            } else {
                this.state.annotations_s.forEach(element => {
                    this.ctx.beginPath();
                    this.ctx.rect(element.box[0],
                        element.box[1],
                        element.box[2] - element.box[0],
                        element.box[3] - element.box[1]);
                    this.ctx.strokeStyle = "blue";
                    this.ctx.lineWidth = (5 / this.ctx.scale) > 1 ? (5 / this.ctx.scale) : 1;
                    this.ctx.stroke();
                });
                if (this.state.show_classes) {
                    this.state.annotations_s.forEach(element => {
                        this.ctx.font = "20px Arial";
                        this.ctx.fillStyle = "red";
                        this.ctx.fillText(element.confidence, element.box[0] + 10, element.box[1] + 25)
                    });
                }
            }
        }
    }

    drawQueryByExamplePredictions() {
        const stroke_width = 2;
        let scale = this.ctx.getTransform().a;
        let color_scale = 0.5;
        this.ctx.fillStyle = "rgba(255, 0, 255, 1.0)";
        this.ctx.textAlign = "center";
        this.ctx.textBaseline = 'middle';
        this.ctx.font = `bolder ${14 / 1.0}px Arial`;
        this.ctx.lineJoin = "miter";
        this.ctx.miterLimit = 3;
        let num = 0;
        this.state.predicted_bounding_boxes.forEach(annotation => {
            if (num < this.state.queryByExampleNumCandidates) {
                if (this.state.filter.length === 0) {
                    let box = annotation.bBox;
                    let color = annotation.color;

                    this.ctx.strokeStyle = color;
                    
                    if(annotation.object_project > -1) {
                        this.ctx.strokeStyle = "rgba(0, 255, 0, 1.0)";
                    }

                    this.ctx.lineWidth = (stroke_width / scale) > stroke_width ? (stroke_width / scale) : stroke_width;
                    if (annotation.id === this.state.selected_bounding_box_id) {
                        //this.ctx.fillStyle = `rgba(${parseInt(color.substr(1, 2), 16)},${parseInt(color.substr(3, 2), 16)},${parseInt(color.substr(5, 2), 16)},0.5)`;
                        //this.fillAnnotation(this.ctx, annotation);
                        //this.ctx.lineWidth = (4 / scale) > 4 ? (4 / scale) : 4;
                    } else if (annotation.id === this.state.hovered_bounding_box_id) {
                        //this.ctx.fillStyle = `rgba(${parseInt(color.substr(1, 2), 16)},${parseInt(color.substr(3, 2), 16)},${parseInt(color.substr(5, 2), 16)},0.25)`;
                        //this.fillAnnotation(this.ctx, annotation);
                    }
                    this.strokeAnnotation(this.ctx, annotation);

                    // draw label
                    this.ctx.lineWidth = 2;
                    this.ctx.strokeText(annotation.name, box.x + box.w * 0.5, box.y + box.h * 0.5);                    
                    this.ctx.fillStyle = `rgba(${parseInt(color.substr(1, 2), 16) * color_scale},${parseInt(color.substr(3, 2), 16) * color_scale},${parseInt(color.substr(5, 2), 16) * color_scale},1.0)`;
                    this.ctx.fillText(annotation.name, box.x + box.w * 0.5, box.y + box.h * 0.5);
                } else {
                    if(this.state.filter.includes(annotation.name)) {
                        let box = annotation.bBox;
                        let color = annotation.color;
                        this.ctx.strokeStyle = color;
                        this.ctx.lineWidth = (stroke_width / scale) > stroke_width ? (stroke_width / scale) : stroke_width;
                        if (annotation.id === this.state.selected_bounding_box_id) {
                            //this.ctx.fillStyle = `rgba(${parseInt(color.substr(1, 2), 16)},${parseInt(color.substr(3, 2), 16)},${parseInt(color.substr(5, 2), 16)},0.5)`;
                            //this.fillAnnotation(this.ctx, annotation);
                            //this.ctx.lineWidth = (4 / scale) > 4 ? (4 / scale) : 4;
                        } else if (annotation.id === this.state.hovered_bounding_box_id) {
                            //this.ctx.fillStyle = `rgba(${parseInt(color.substr(1, 2), 16)},${parseInt(color.substr(3, 2), 16)},${parseInt(color.substr(5, 2), 16)},0.25)`;
                            //this.fillAnnotation(this.ctx, annotation);
                        }
                        this.strokeAnnotation(this.ctx, annotation);

                        // draw label
                        this.ctx.lineWidth = 2;
                        if(annotation.object_project > -1) {
                            this.ctx.strokeStyle = "rgba(0, 255, 0, 1.0)";
                        } else {
                            this.ctx.strokeStyle = "rgba(255, 255, 255, 1.0)";
                        }
                        this.ctx.strokeText(annotation.name, box.x + box.w * 0.5, box.y + box.h * 0.5);
                        this.ctx.fillStyle = `rgba(${parseInt(color.substr(1, 2), 16) * color_scale},${parseInt(color.substr(3, 2), 16) * color_scale},${parseInt(color.substr(5, 2), 16) * color_scale},1.0)`;
                        this.ctx.fillText(annotation.name, box.x + box.w * 0.5, box.y + box.h * 0.5);
                    }
                }
            }
            num = num + 1;
        });
    }

    fillAnnotation(ctx: any, annotation: canvasAnnotation) {
        let box = annotation.bBox;
        if (annotation.verts.length > 2) {
            ctx.beginPath();
            ctx.moveTo(annotation.verts[0].x, annotation.verts[0].y);
            for (let i = 1; i < annotation.verts.length; i++) {
                ctx.lineTo(annotation.verts[i].x, annotation.verts[i].y);
            }
            ctx.closePath();
            ctx.fill();
        } else {
            ctx.fillRect(box.x, box.y, box.w, box.h);
        }
    }

    strokeAnnotation(ctx: any, annotation: canvasAnnotation) {
        //let scale = this.ctx.getTransform().a;
        //let small_sized = annotation.bBox.w * scale > 25 || annotation.bBox.h * scale > 25;
        let box = annotation.bBox;
        if (annotation.verts.length > 2) {
            ctx.beginPath();
            ctx.moveTo(annotation.verts[0].x, annotation.verts[0].y);
            for (let i = 1; i < annotation.verts.length; i++) {
                ctx.lineTo(annotation.verts[i].x, annotation.verts[i].y);
            }
            ctx.closePath();
            ctx.stroke();
        } else {
            ctx.strokeRect(box.x, box.y, box.w, box.h);
        }
    }

    draw (annotation: canvasAnnotation, stroke_width: number, scale: number, color_scale: number) {
        let box = annotation.bBox;
        let color = annotation.color;
        this.ctx.strokeStyle = color;
        this.ctx.lineWidth = (stroke_width / scale) > stroke_width ? (stroke_width / scale) : stroke_width;
        if (annotation.id === this.state.selected_bounding_box_id) {
            this.ctx.fillStyle = `rgba(${parseInt(color.substr(1, 2), 16)},${parseInt(color.substr(3, 2), 16)},${parseInt(color.substr(5, 2), 16)},0.5)`;
            this.fillAnnotation(this.ctx, annotation);
            this.ctx.lineWidth = (4 / scale) > 4 ? (4 / scale) : 4;
        } else if (annotation.id === this.state.hovered_bounding_box_id) {
            this.ctx.fillStyle = `rgba(${parseInt(color.substr(1, 2), 16)},${parseInt(color.substr(3, 2), 16)},${parseInt(color.substr(5, 2), 16)},0.25)`;
            this.fillAnnotation(this.ctx, annotation);
        }
        this.strokeAnnotation(this.ctx, annotation);
        // draw label
        this.ctx.lineWidth = 2;
        this.ctx.strokeStyle = "rgba(255, 255, 255, 1.0)";
        this.ctx.strokeText(annotation.name, box.x + box.w * 0.5, box.y + box.h * 0.5);
        this.ctx.fillStyle = `rgba(${parseInt(color.substr(1, 2), 16) * color_scale},${parseInt(color.substr(3, 2), 16) * color_scale},${parseInt(color.substr(5, 2), 16) * color_scale},1.0)`;
        this.ctx.fillText(annotation.name, box.x + box.w * 0.5, box.y + box.h * 0.5);
    }

    // draw bounding boxes on canvas
    drawBoundingBoxes() {
        if(!this.state.hideAnnotations) {
            const stroke_width = 2;
            let scale = this.ctx.getTransform().a;
            let color_scale = 0.5;
            this.ctx.fillStyle = "rgba(255, 0, 0, 1.0)";
            this.ctx.textAlign = "center";
            this.ctx.textBaseline = 'middle';
            this.ctx.font = `bolder ${14 / 1.0}px Arial`;
            this.ctx.lineJoin = "miter";
            this.ctx.miterLimit = 3;

            this.state.canvas_bounding_boxes.forEach(annotation => {                
                if (this.state.filter.length === 0) {
                    this.draw(annotation, stroke_width, scale, color_scale)
                } else {
                    if(this.state.filter.includes(annotation.name)) {
                        this.draw(annotation, stroke_width, scale, color_scale)
                    }
                }                
            });
        }
    }

    // draw bounding boxes in selection buffer
    drawBoundingBoxesSB() {
        let ctx = this.selCtx;
        // Clear the entire ghost canvas
        ctx.clearRect(0, 0, this.selCanvas.width, this.selCanvas.height);

        // draw color coded selection bounding boxes
        this.state.canvas_bounding_boxes.forEach(annotation => {
            ctx.fillStyle = annotation.sel_color;
            this.fillAnnotation(ctx, annotation);
        });
    }

    // call this to update the selection buffer
    updateGhostCanvas() {
        // update indices
        this.colorsHash.clear();
        let gt_boxes: canvasAnnotation[];
        gt_boxes = [];
        gt_boxes.push(...this.state.canvas_bounding_boxes);
        for (let i = 0; i < gt_boxes.length; i++) {
            let sc: string = "#" + zeroPad(i.toString(16), 6); // unique selection color (rgb)
            gt_boxes[i].id = i;
            gt_boxes[i].sel_color = sc;
            this.colorsHash.set(sc, i);
        }

        let predicted_boxes: canvasAnnotation[];
        predicted_boxes = [];
        predicted_boxes.push(...this.state.predicted_bounding_boxes);
        for (let i = 0; i < predicted_boxes.length; i++) {
            let sc: string = "#" + zeroPad(i.toString(16), 6); // unique selection color (rgb)
            predicted_boxes[i].id = i;
            predicted_boxes[i].sel_color = sc;
            this.colorsHash.set(sc, i);
        }

        // set state and draw boxes
        this.setState({ canvas_bounding_boxes: gt_boxes, predicted_bounding_boxes: predicted_boxes }, () => {
            this.drawBoundingBoxesSB();
            this.redraw();
        });
    }

    computeSelectedBoundingBox(x: number, y: number): number {
        let tr = this.ctx.transformedPoint(x, y);
        const pixel: Uint8ClampedArray = this.selCtx.getImageData(tr.x, tr.y, 1, 1).data;
        const hex_color: string = `#${zeroPad(pixel[0].toString(16), 2)}${zeroPad(pixel[1].toString(16), 2)}${zeroPad(pixel[2].toString(16), 2)}`;

        if (pixel[3] === 0) {
            return -1; // background pixel (0, 0, 0, 0)
        } else {
            let id = this.colorsHash.get(hex_color);
            if (id === undefined)
                return -1;
            else
                return id;
        }
    }

    loadResultsFromQuery(json: any, useDifferentColors: boolean, storeInCanvasBoundingBoxes: boolean) {
        type annotation = { id: number, sign: string, confidence: number, box: number[], object_project: number}
        if (json) {
            let annotations: annotation[] = json;
            this.colorsHash.clear();
            
            let boxes: canvasAnnotation[];
            if (storeInCanvasBoundingBoxes === true) {
                boxes = this.state.canvas_bounding_boxes;
            } else {
                boxes = this.state.predicted_bounding_boxes;
            }
            for (let i = 0; i < annotations.length; i++) {
                let element: any = annotations[i];
                let db_id = element[0];
                let name = element[1];
                let verts = element[3];
                let minX = verts[0];
                let maxX = verts[2];
                let minY = verts[1];
                let maxY = verts[3];
                
                let c: string = "";
                if (useDifferentColors) {
                    c = this.getHashedColor(name);
                } else {
                    if (i===0) {
                        //c = "#0000FF";
                        // Testweise
                        c = "#FF0000";
                    } else {
                        c = "#FF0000";
                    }
                    if (storeInCanvasBoundingBoxes === false) {
                        c = "#FF00FF";
                    }
                }
                let sc: string = "#" + zeroPad(i.toString(16), 6); // unique selection color (rgb)
                let bb: canvasAnnotation = { id: i, db_id: db_id, name: name, bBox: { x: minX, y: minY, w: maxX - minX, h: maxY - minY }, verts: [], color: c, sel_color: sc, object_project: element[4] };
                //bb.verts = verts.map(v => ({ x: Math.floor(v.x), y: Math.floor(v.y) }));
                boxes.push(bb);
                this.colorsHash.set(sc, i);
            }
            if (storeInCanvasBoundingBoxes === true) {
                this.setState({ canvas_bounding_boxes: boxes }, () => {console.log(this.state.canvas_bounding_boxes); this.updateGhostCanvas()});
            } else {
                this.setState({ predicted_bounding_boxes: boxes }, () => this.updateGhostCanvas());
            }
        }
    }

    public async addAnnotation(signclass: string) {
        //console.log(this.state.tabletFragmentObjId);
        if (Math.abs(this.state.mouseCoordPressedX - this.state.mouseCoordReleasedX) < minAnnotationSize &&
            Math.abs(this.state.mouseCoordPressedY - this.state.mouseCoordReleasedY) < minAnnotationSize) {
            // We have an error here
        } else {
            let boxes: canvasAnnotation[] = this.state.canvas_bounding_boxes;
            let new_id: number = boxes.length;
            let sc: string = "#" + zeroPad(new_id.toString(16), 6); // unique selection color (rgb);
            let tabletFragmentObjId = this.state.tabletFragmentObjId;
            let db_id: any = await this.webservices.addAnnotation(signclass, 
                                            tabletFragmentObjId,
                                            [this.state.mouseCoordPressedX,
                                            this.state.mouseCoordPressedY,
                                            this.state.mouseCoordReleasedX,
                                            this.state.mouseCoordReleasedY])
            console.log(db_id);
            let bb: canvasAnnotation = {
                id: new_id,
                db_id: db_id,
                name: signclass,
                bBox: {
                    x: this.state.mouseCoordPressedX,
                    y: this.state.mouseCoordPressedY,
                    w: this.state.mouseCoordReleasedX - this.state.mouseCoordPressedX,
                    h: this.state.mouseCoordReleasedY - this.state.mouseCoordPressedY
                },
                verts: [],
                color: this.getRandomColor(),
                sel_color: sc,
                object_project: -1
            };
            bb.verts.push({ x: this.state.mouseCoordPressedX, y: this.state.mouseCoordPressedY });
            bb.verts.push({ x: this.state.mouseCoordReleasedX, y: this.state.mouseCoordReleasedY });
            boxes.push(bb);
            this.setState({
                canvas_bounding_boxes: boxes,
                mouseCoordPressedX: 0,
                mouseCoordPressedY: 0,
                mouseCoordCurrentX: 0,
                mouseCoordCurrentY: 0,
                mouseCoordReleasedX: 0,
                mouseCoordReleasedY: 0
            }, () => this.updateGhostCanvas()); // refresh selection buffer and hashes
        }
    }

    public deleteAnnotationSpecifiedByQueryObjectId() {
        if (this.state.selected_bounding_box_id !== -1) {
            let boxes: canvasAnnotation[] = this.state.canvas_bounding_boxes;
            let id: number = this.state.selected_bounding_box_id; //boxes.findIndex(box => box.id === this.state.selected_bounding_box_id);
            //let sc: string = "#" + zeroPad(id.toString(16), 6); // unique selection color (rgb);
                        
            let queryObjectId: number = boxes[id].db_id;
            console.log("queryObjectId: " + queryObjectId);
            this.webservices.deleteAnnotationSpecifiedByQueryObjectId(queryObjectId);
            if (boxes.splice(id, 1)) {
                this.setState({
                    canvas_bounding_boxes: boxes,
                    selected_bounding_box_id: -1
                }, () => this.updateGhostCanvas()); // refresh selection buffer and hashes
            }
        }
    }

    public removeAnnotationFromProject() {
        if (this.state.selected_bounding_box_id !== -1) {
            let boxes: canvasAnnotation[] = this.state.canvas_bounding_boxes;
            let id: number = this.state.selected_bounding_box_id; //boxes.findIndex(box => box.id === this.state.selected_bounding_box_id);
            //let sc: string = "#" + zeroPad(id.toString(16), 6); // unique selection color (rgb);
            
            let objectId: number = -1;
            console.log(boxes[id]);
            objectId = boxes[id].db_id;
            console.log("objectId: " + objectId);
            this.webservices.removeAnnotationFromProject(objectId);
            if (boxes.splice(id, 1)) {
                this.setState({
                    canvas_bounding_boxes: boxes,
                    selected_bounding_box_id: -1
                }, () => this.updateGhostCanvas()); // refresh selection buffer and hashes
            }
        }
    }

    updateConfirmedStateOfAnnotations(annotationsThatWereConfirmed: number[]) {
        console.log(annotationsThatWereConfirmed);
        console.log(this.state.canvas_bounding_boxes);
        console.log(this.state.predicted_bounding_boxes);
    }

    setCanvasQueryBoundingBox(box: number[]) {
        this.setState({canvas_query_bounding_box: box}, () => this.updateGhostCanvas());
    }

    drawQueryBox() {
        if (this.state.canvas_query_bounding_box !== null) {
            let box = this.state.canvas_query_bounding_box;
            
            const stroke_width = 5;
            let scale = this.ctx.getTransform().a;
            this.ctx.lineWidth = (stroke_width / scale) > stroke_width ? (stroke_width / scale) : stroke_width;
            this.ctx.strokeStyle = "rgba(0, 0, 255, 0.75)";
            this.ctx.strokeRect(box[0], box[1], box[2], box[3]);            
        }
    }

    // =========================================================================================
    // =============================== START Filter functions ==================================
    // =========================================================================================

    addSignToFilter(signClass: string) {
        let filteredClasses = this.state.filter;
        filteredClasses.push(signClass);
        this.setState({filter: filteredClasses});
        console.log(signClass);
    }
    
    removeSignFromFilter(signClass: string) {
        console.log("Remove sign called");
        let filteredClasses = this.state.filter;
        let index = filteredClasses.findIndex(element => element === signClass);
        filteredClasses.splice(index, 1);
        this.setState({filter: filteredClasses});
        console.log(signClass);
    }

    clearFilter() {
        this.setState({filter: []});
    }

    // =========================================================================================
    // ================================= END Filter functions ==================================
    // =========================================================================================

    // =========================================================================================
    // ================================= START React functions =================================
    // =========================================================================================

    componentDidMount() {
        this.canvas = this.canvasElement.current;
        this.ctx = this.canvas.getContext('2d');

        this.trackTransforms(this.ctx);
        this.selCtx.translate(0.5, 0.5); // aliasing fix to get hard lines

        this.canvas.addEventListener('mousedown', this.canvasMouseDown, false);
        this.canvas.addEventListener('mousemove', this.canvasMouseMove, false);
        this.canvas.addEventListener('mouseup', this.canvasMouseUp, false);
        this.canvas.addEventListener('DOMMouseScroll', this.handleScroll, false);
        this.canvas.addEventListener('mousewheel', this.handleScroll, false);
    }

    componentWillUnmount() {
        this.canvas.removeEventListener('mousedown', this.canvasMouseDown, false);
        this.canvas.removeEventListener('mousemove', this.canvasMouseMove, false);
        this.canvas.removeEventListener('mouseup', this.canvasMouseUp, false);
        this.canvas.removeEventListener('DOMMouseScroll', this.handleScroll, false);
        this.canvas.removeEventListener('mousewheel', this.handleScroll, false);
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <ResizeObserver
                    onResize={(rect) => {
                        this.resize(rect.width, rect.height);
                    }}
                />
                <canvas ref={this.canvasElement}
                    id="canvas_selector"
                    className="Annotation-Canvas"
                />
            </div>
        )
    }
    // =========================================================================================
    // =================================== END React functions =================================
    // =========================================================================================
}

export default AnnotationCanvas;