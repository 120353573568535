import React from "react";
import {ItemPredicate, ItemRenderer} from "@blueprintjs/select";
import {MenuItem} from "@blueprintjs/core";
import {highlightText} from "../sign-select-box/sign";

export const TEXT_CLASSES: ITextSelect[] = [
    /**
     * TODO: Maybe text should be changed to name -> TEXT_CLASSES.name
     */
    {text: "Hittite"},
    {text: "Sumerian"},
    {text: "Old Akkadian"},
    {text: "Old Babylonian"},
    {text: "Middle Babylonian"},
    {text: "Neo Babylonian"},
    {text: "Old Assyrian"},
    {text: "Middle Assyrian"},
    {text: "Neo Assyrian"},
]

export interface ITextSelect {
    /** text title **/
    text: string;
}

export const renderText: ItemRenderer<ITextSelect> = (text, {handleClick, modifiers, query}) => {
    if (!modifiers.matchesPredicate) {
        return null;
    } else {
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={text.text}
                key={text.text}
                onClick={handleClick}
                text={highlightText(text.text, query)}
            />
        )
    }
}

export const filterText: ItemPredicate<ITextSelect> = (query, text, _index, exactMatch) => {
    const normalizedText = text.text.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedText === normalizedQuery;
    } else {
        return normalizedText.indexOf(normalizedQuery) >= 0;
    }
}

export const textSelectProps = {
    itemPredicate: filterText,
    itemRenderer: renderText,
    items: TEXT_CLASSES,
}

export const renderCreateTextOption = (
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>,
) => (
    <MenuItem
        icon="add" text={`Create "${query}"`}
        active={active}
        onClick={handleClick}
        shouldDismissPopover={false}
    />
)

export function createText(text: string): ITextSelect {
    return {text: text};
}

export function areTextsEqual(textA: ITextSelect, textB: ITextSelect): boolean {
    return textA.text.toLowerCase() === textB.text.toLowerCase();
}

export function doesTextEqualQuery(text: ITextSelect, query: string): boolean {
    return text.text.toLowerCase() === query.toLowerCase();
}

export function arrayContainsText(texts: ITextSelect[], textToFind: ITextSelect): boolean {
    return texts.some((text: ITextSelect) => text.text === textToFind.text);
}

export function addTextToArray(texts: ITextSelect[], filmToAdd: ITextSelect) {
    return [...texts, filmToAdd];
}

export function deleteTextFromArray(texts: ITextSelect[], textToDelete: ITextSelect) {
    return texts.filter(text => text !== textToDelete);
}

export function maybeAddCreatedTextToArrays(
    items: ITextSelect[],
    createdItems: ITextSelect[],
    text: ITextSelect
): { createdItems: ITextSelect[], items: ITextSelect[] } {

    const isNewlyCreatedItem = !arrayContainsText(items, text);
    if (isNewlyCreatedItem) {
        createdItems = addTextToArray(createdItems, text);
        items = addTextToArray(items, text)
    }
    return {createdItems, items};
}

export function maybeDeleteCreatedTextFromArrays(
    items: ITextSelect[],
    createdItems: ITextSelect[],
    text: ITextSelect
): { createdItems: ITextSelect[], items: ITextSelect[] } {

    const wasItemCreatedByUser = arrayContainsText(createdItems, text);
    if (wasItemCreatedByUser) {
        createdItems = deleteTextFromArray(createdItems, text);
        items = deleteTextFromArray(items, text)
    }
    return {createdItems, items};
}