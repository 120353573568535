import React, { Component } from 'react';
import { Classes, Button } from '@blueprintjs/core';
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

class ThemeSwitcher extends Component {

    constructor() {
        super()
        this.state = {
            darkMode: false
        };
    }

    switchTriggered() {
        localStorage.setItem("DarkMode", (!this.state.darkMode) ? "yes" : "no");
        this.setState({ darkMode: !this.state.darkMode });
    }

    render() {
        document.body.className = ((this.state.darkMode) ? "bp3-dark" : "bp3-body");
        return (
            <Button className={Classes.MINIMAL} icon={((this.state.darkMode) ? "moon" : "flash")} title="Switch Theme, 'Dark Mode' is currently under development" onClick={this.switchTriggered.bind(this)} />
        );
    }
}

export default ThemeSwitcher;