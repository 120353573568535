import React from "react";
import {ItemPredicate, ItemRenderer} from "@blueprintjs/select";
import {MenuItem} from "@blueprintjs/core";
import {highlightText} from "../sign-select-box/sign";

export const EPOCH_CLASSES: IEpochSelect[] = [
    /**
     * TODO: Maybe epoch should be changed to name -> TEXT_CLASSES.name
     */
    {epoch: "old"},
    {epoch: "middle"},
    {epoch: "new"},
]

export interface IEpochSelect {
    /** epoch title **/
    epoch: string;
}

export const renderEpoch: ItemRenderer<IEpochSelect> = (epoch, {handleClick, modifiers, query}) => {
    if (!modifiers.matchesPredicate) {
        return null;
    } else {
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={epoch.epoch}
                key={epoch.epoch}
                onClick={handleClick}
                text={highlightText(epoch.epoch, query)}
            />
        )
    }
}

export const filterEpoch: ItemPredicate<IEpochSelect> = (query, epoch, _index, exactMatch) => {
    const normalizedEpoch = epoch.epoch.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedEpoch === normalizedQuery;
    } else {
        return normalizedEpoch.indexOf(normalizedQuery) >= 0;
    }
}

export const epochSelectProps = {
    itemPredicate: filterEpoch,
    itemRenderer: renderEpoch,
    items: EPOCH_CLASSES,
}

export const renderCreateEpochOption = (
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>,
) => (
    <MenuItem
        icon="add"
        text={`Create "${query}"`}
        active={active}
        onClick={handleClick}
        shouldDismissPopover={false}
    />
)

export function createEpoch(epoch: string): IEpochSelect {
    return {epoch: epoch};
}

export function areEpochsEqual(epochA: IEpochSelect, epochB: IEpochSelect): boolean {
    return epochA.epoch.toLowerCase() === epochB.epoch.toLowerCase();
}

export function doesEpochEqualQuery(epoch: IEpochSelect, query: string): boolean {
    return epoch.epoch.toLowerCase() === query.toLowerCase();
}

export function arrayContainsEpoch(epochs: IEpochSelect[], epochToFind: IEpochSelect): boolean {
    return epochs.some((epoch: IEpochSelect) => epoch.epoch === epochToFind.epoch);
}

export function addEpochToArray(epochs: IEpochSelect[], filmToAdd: IEpochSelect) {
    return [...epochs, filmToAdd];
}

export function deleteEpochFromArray(epochs: IEpochSelect[], epochToDelete: IEpochSelect) {
    return epochs.filter(epoch => epoch !== epochToDelete);
}

export function maybeAddCreatedEpochToArrays(
    items: IEpochSelect[],
    createdItems: IEpochSelect[],
    epoch: IEpochSelect
): { createdItems: IEpochSelect[], items: IEpochSelect[] } {

    const isNewlyCreatedItem = !arrayContainsEpoch(items, epoch);
    if (isNewlyCreatedItem) {
        createdItems = addEpochToArray(createdItems, epoch);
        items = addEpochToArray(items, epoch)
    }
    return {createdItems, items};
}

export function maybeDeleteCreatedEpochFromArrays(
    items: IEpochSelect[],
    createdItems: IEpochSelect[],
    epoch: IEpochSelect
): { createdItems: IEpochSelect[], items: IEpochSelect[] } {

    const wasItemCreatedByUser = arrayContainsEpoch(createdItems, epoch);
    if (wasItemCreatedByUser) {
        createdItems = deleteEpochFromArray(createdItems, epoch);
        items = deleteEpochFromArray(items, epoch)
    }
    return {createdItems, items};
}