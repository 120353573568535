import React, { Component } from 'react'
import './app.css'
import { clientConfig } from './../../config.js';
import { customFetch } from "./../utils/utils";
import { SplitPane } from 'react-multi-split-pane'

// blueprintjs imports
import {
    Alignment,
    Button,
    Classes,
    Navbar,
    Dialog,
    NavbarDivider,
    NavbarHeading,
    NavbarGroup,
    Position,
    Tooltip,
    Intent,
    HotkeysTarget2,
} from '@blueprintjs/core'
import 'normalize.css/normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'

import { saveAs } from "file-saver";
import QueryByExampleDisplay from '../query-by-example-displayer'
import AnnotationPopUpRemove from '../annotation-popup-remove'
import AnnotationPopUpAdd from '../annotation-popup-add'
import AnnotationCanvas from './../annotation-canvas'
import ThemeSwitcher from './../theme-switcher'
import DocumentSelect from './../document-select'
import LoginDialog from './../login-dialog'
import QueryDialog from './../query-dialog'
import QuerySelect from './../query-select'
import Webservices from './../webservices'
import QueryGallery from './../query-gallery'
import HelpMenu from './../help-menu'
import taskToNum from './../utils'
import customFetchLoginStatus from './../utils'
import UploadFileDialog from './../upload-file-dialog'
import SignSelectBox from './../sign-select-box/sign-select-box'

//@HotkeysTarget
class App extends Component {
    constructor(props) {
        super(props)

        this.documentSelectElement = React.createRef()
        this.annotationCanvasElement = React.createRef()
        this.queryByExampleDisplay = React.createRef()
        this.ThemeSwitcherElement = React.createRef()
        this.queryDialogElement = React.createRef()
        this.queryGalleryElement = React.createRef()
        this.querySelectElement = React.createRef()
        this.signSelectBox = React.createRef()
        this.errorDialog = React.createRef();

        this.state = {
            logged_in: false,
            isOpenHideAnnotations: false,
            willLoadHideAnnotations: false,
            isLoadingHideAnnotations: false,
            boxSelectModeActive: false,
            hideAnnotationsActive: false,
            loadedImgId: -1,
            queryByExampleMode: 0,  // 0:OnlyCanvas - 1:Canvas next to QueryGallery (when the target tablet is the source tablet) - 2:No Canvas, just QueryGalleries
            currentQuery: -1,
            documentLoadedWithDocumentSelect: false, // If this is false, then it means that a Query was selected
            fileDownloadUrl: "",
            displayAnnotationMenu: false,
            isOpenError: false,
            errorMessage: "The place for an error message",
            aiModells: [],
            tabletName: "",
            fragmentName: "",
            representationType: "",
        }

        this.webservices = new Webservices();
        this.selectedQueryId = -1;
        this.images = []
    }

    hotkeys = [
        {
            combo: "T",
            global: true,
            label: "Test",
            onKeyDown: () => console.info("Test..."),
        },
        {
            combo: "S",
            global: true,
            label: "Select Rectangle",
            onKeyDown: () => this.handleSelectBoxModeChange(),
        },
        {
            combo: "H",
            global: true,
            label: "Hide Annotations",
            onKeyDown: () => this.handleHideAnnotationsChange(),
        },
    ];

    alertProps = {
        canEscapeKeyCancel: true,
        canOutsideClickCancel: true,
    }

    async componentDidMount() {
        if (localStorage.getItem('DarkMode') === 'yes') {
            this.ThemeSwitcherElement.current.switchTriggered()
        }
        // to fetch the login status from the backend with current token
        customFetchLoginStatus.customFetchLoginStatus().then(
            result => {
                if (result === true) {
                    this.setState({ logged_in: true })
                    this.webservices.getAiModells(this.handleAiModells);
                }
            }
        ).catch(e => { console.log("FAILED to login using an old token."); });
    }

    handleAiModells = (aiModells) => {
        this.setState({ aiModells: aiModells });
    }

    handleOpenAlertHideAnnotations = () => this.setState({ isOpenHideAnnotations: true });

    handleCloseAlertHideAnnotations = () => {
        const close = () => this.setState({ isLoadingHideAnnotations: false, isOpenHideAnnotations: false });
        if (this.state.willLoadHideAnnotations) {
            this.setState({ isLoadingHideAnnotations: true });
            setTimeout(close, 2000);
        } else {
            close();
        }
    };

    handleCancelHideAnnotations = () => this.setState({ isOpenHideAnnotations: false });

    async getImage(id, name, useAnnotations, queryByExampleMode = 0) {
        console.log(id + "     " + name);
        this.setState({ loadedImgId: id, queryByExampleMode: queryByExampleMode })
        this.forceUpdate();
        //await new Promise((r) => setTimeout(r, 500));

        if (this.state.representationType === "2") {
            console.log("We should initiate the 3D display now");
            // id, name, within tablet_fragment_obj->type=2
        } else {
            if (this.annotationCanvasElement.current !== null && this.annotationCanvasElement.current !== undefined) {
                this.annotationCanvasElement.current.setState({ query_id: -1 })
                this.annotationCanvasElement.current.getImage(id, name, useAnnotations)
                this.queryDialogElement.current.setState({ disabled: false });
            }
        }
    }

    handleSelectBoxModeChange() {
        const canvasElement = this.annotationCanvasElement.current
        if (canvasElement) {
            const active = !this.state.boxSelectModeActive
            this.setState({ boxSelectModeActive: active })
            canvasElement.setBoxSelectMode(active)
        }
    }

    handleHideAnnotationsChange() {
        const canvasElement = this.annotationCanvasElement.current
        if (canvasElement !== undefined && canvasElement !== null) {
            const active = !this.state.hideAnnotationsActive
            this.setState({ hideAnnotationsActive: active })
            canvasElement.setState({ hideAnnotations: active })
        }
    }

    handleZoomToFit() {
        this.annotationCanvasElement.current.zoomFit()
    }

    handleGetQueryByExamplePredictions() {
        if (this.state.queryByExampleMode === 0) {
            this.downloadAnnotations();
        } else {
            this.downloadQbEPredictions();
        }
    }

    handleGetAnnotationsForTraining() {
        var JSZip = require("jszip");
        let annotations = this.annotationCanvasElement.current.state.canvas_bounding_boxes;

        var zip = new JSZip();

        let requestHeaders = { 'Authorization': localStorage.getItem('token') };
        // Retrieve the image from the backend and display it
        let url = clientConfig.webApiProtocol + clientConfig.webApiUrl + ":" + clientConfig.webApiPort + "/api/imageForFragmentId?id=" + this.state.loadedImgId;
        customFetch(url, {
            method: 'GET',
            headers: requestHeaders,
        }).then(response => response.blob())
            .then(blob => {
                zip.file(this.state.tabletName + "_" + this.state.fragmentName + ".png", blob);


                // Download the annotations
                var annotationsAsString = "";
                for (let index = 0; index < annotations.length; index++) {
                    let bBox = annotations[index].bBox;
                    annotationsAsString += annotations[index].name + " " + bBox.x + " " + bBox.y + " " + (bBox.x + bBox.w).toString() + " " + (bBox.y + bBox.h).toString();
                    annotationsAsString += "\n";
                }
                zip.file(this.state.tabletName + "_" + this.state.fragmentName + ".txt", annotationsAsString);

                zip.generateAsync({ type: "blob" }).then((content) => {
                    saveAs(content, this.state.tabletName + "_" + this.state.fragmentName + "_annotations.zip");
                });
            });
    }

    downloadQbEPredictions() {
        var JSZip = require("jszip");
        let predictions = "";
        let candidates = "";
        if (this.queryGalleryElement.current !== undefined) {
            predictions = this.queryGalleryElement.current.state.imgCandidates;
            candidates = this.queryGalleryElement.current.state.candidate;
        } else {
            predictions = this.queryByExampleDisplay.current.state.imgCandidates;
            // TODO: This won't work. Need to get one of the query-galleries from the "QbEdisplay"
        }
        var zip = new JSZip();
        var img = zip.folder("images");
        for (let index = 0; index < predictions.length; index++) {
            img.file(candidates[index].prediction.sign + "_" + this.state.tabletName + "_" + this.state.fragmentName + ".jpg", predictions[index].substr(22), { base64: true });
        }

        zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, this.state.tabletName + "_" + this.state.fragmentName + "_queryByExample.zip");
        });
    }

    cropImage(bb) {
        return new Promise((resolve) => {
            const inputImage = new Image();

            inputImage.onload = () => {
                const outputImage = document.createElement("canvas");

                outputImage.width = bb.w;
                outputImage.height = bb.h;

                // draw our image at position 0, 0 on the canvas
                const ctx = outputImage.getContext("2d");

                ctx.drawImage(inputImage, -bb.x, -bb.y);
                resolve(outputImage);
            };

            // start loading our image
            inputImage.src = this.annotationCanvasElement.current.state.src;
        });
    }

    async downloadAnnotations() {
        var JSZip = require("jszip");
        let annotations = this.annotationCanvasElement.current.state.canvas_bounding_boxes;

        var zip = new JSZip();
        var img = zip.folder("images");
        for (let index = 0; index < annotations.length; index++) {
            // Extract the regions from the image
            var imgBase64 = "";

            await this.cropImage(annotations[index].bBox).then((canvas) => {
                imgBase64 = canvas.toDataURL();
            });

            // cut: "data:image/png;base64,"
            img.file(annotations[index].name + "_" + this.state.tabletName + "_" + this.state.fragmentName + "_" + index + ".png", imgBase64.substr(22), { base64: true });
        }

        zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, this.state.tabletName + "_" + this.state.fragmentName + "_annotations.zip");
        });
    }

    makeCSV(content) {
        /*
        let csv = '';
        content.forEach(value => {
            value.forEach((item, i) => {
                let innerValue = item === null ? '' : item.toString();
                let result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0) {
                result = '"' + result + '"'
                }
                if (i > 0) {csv += ','}
                csv += result;
            })
            csv += '\n';
        })
        return csv
        */
    }

    handleAddAnnotation(signclass) {
        this.annotationCanvasElement.current.addAnnotation(signclass)
    }

    handleRemoveAnnotation() {
        if (this.state.queryByExampleMode) {
            this.annotationCanvasElement.current.removeAnnotationFromProject();
        } else {
            if (this.state.documentLoadedWithDocumentSelect) {  // If the user selected a document, then
                console.log("Loaded with DocumentSelect");
                this.annotationCanvasElement.current.removeAnnotationFromProject();
            } else {                                            // If the user selected an annotate-query, then
                console.log("Loaded with QuerySelect");
                this.annotationCanvasElement.current.deleteAnnotationSpecifiedByQueryObjectId();
            }
        }
    }

    focusOnAnnotation(box) {
        this.annotationCanvasElement.current.focusOnAnnotation(box)
    }

    handleFileUpload(file, tablet, fragment) {
        this.webservices.uploadImage(file, tablet, fragment)
    }

    hideMenus(docSelect, queryState) {
        if (docSelect === true) {
            let docSelectElement = this.documentSelectElement.current
            docSelectElement.setState({ isOpen: false })
        }
        if (queryState === true) {
            let queryStateElement = this.querySelectElement.current
            queryStateElement.setState({ isOpen: false })
        }
    }

    handleTask(task, targets, model) {
        console.log("Handle Task called");
        // TODO: Enum?
        const task_num = taskToNum.taskToNum(task);
        const canvasElement = this.annotationCanvasElement.current
        let parameters = '';
        if (task_num === 2 || task_num === 4) {
            const XMin =
                canvasElement.state.mouseCoordPressedX <
                    canvasElement.state.mouseCoordReleasedX
                    ? canvasElement.state.mouseCoordPressedX
                    : canvasElement.state.mouseCoordReleasedX;
            const XMax =
                canvasElement.state.mouseCoordPressedX >
                    canvasElement.state.mouseCoordReleasedX
                    ? canvasElement.state.mouseCoordPressedX
                    : canvasElement.state.mouseCoordReleasedX;
            const YMin =
                canvasElement.state.mouseCoordPressedY <
                    canvasElement.state.mouseCoordReleasedY
                    ? canvasElement.state.mouseCoordPressedY
                    : canvasElement.state.mouseCoordReleasedY;
            const YMax =
                canvasElement.state.mouseCoordPressedY >
                    canvasElement.state.mouseCoordReleasedY
                    ? canvasElement.state.mouseCoordPressedY
                    : canvasElement.state.mouseCoordReleasedY;
            //parameters = "{\"rect\":[" + canvasElement.state.mouseCoordPressedX + ", " + canvasElement.state.mouseCoordPressedY + ", " + canvasElement.state.mouseCoordReleasedX + ", " + canvasElement.state.mouseCoordReleasedY + "]}";
            parameters = '{"rect":[' + XMin + ', ' + YMin + ', ' + XMax + ', ' + YMax + ']}'
            if (XMin === 0 && XMax === 0 && YMin === 0 && YMax === 0) {
                // No rectangle selected -> Don't queue this
                this.setState({ isOpenError: true, errorMessage: "No query rectangle selected" });
                return
            }
            let MinSize = 25
            if (XMax - XMin < MinSize || YMax - YMin < MinSize) {
                // selected rectangle is too small -> Don't queue this
                this.setState({ isOpenError: true, errorMessage: "The selected query rectangle is too small. The minimum size of the query is " + MinSize + "*" + MinSize + " pixels. Currently, your query rectangle has " + parseInt(XMax - XMin) + "*" + parseInt(YMax - YMin) + "pixels." });
                return
                // TODO: Show error message to the user
            }
        }
        if (targets !== undefined && targets.length > 0) {
            targets.unshift(this.state.loadedImgId);
            console.log(targets);
            this.webservices.queueTask(
                task_num, //task
                localStorage.getItem('username'), //user name
                targets, // array of tablet_ids
                parameters, // parameters
                model.id, // model
                canvasElement.taskQueueCallback,
            )
        } else {
            if (this.state.loadedImgId > -1) {
                this.webservices.queueTask(
                    task_num, //task
                    localStorage.getItem('username'), //user name
                    [this.state.loadedImgId], // array of tablet_ids
                    parameters, // parameters
                    model.id, // model
                    canvasElement.taskQueueCallback,
                )
            } else {
                // TODO: Show error message to the user
                // The loadedImage Id is not available for some reason
            }
        }
    }

    async displayResultsAnnotate(query) {
        const index = query.tablet_id.indexOf(']');
        let source_tablet = await this.webservices.getTabletAndFragmentName(query.query_id);
        source_tablet = source_tablet.result[0][0];
        this.setState({ currentQuery: query.query_id, queryByExampleMode: 0, tabletName: source_tablet.tablet_name, fragmentName: source_tablet.tablet_fragment_name });
        this.forceUpdate();

        // TODO: Remove the sleep and use a promise on the getImage method above
        await new Promise((r) => setTimeout(r, 1000))
        const canvasElement = this.annotationCanvasElement.current
        this.getImage(query.tablet_id.substring(1, index), '', false);
        if (canvasElement !== null && canvasElement !== undefined) {
            canvasElement.setState({ query_id: query.query_id }, () => {
                //const galleryElement = this.queryGalleryElement.current
                this.webservices.getResultsForQuery(
                    query.query_id,
                    query.task,
                    canvasElement.getResultsAnnotateCallback,
                    null,
                )
            })
        }
    }

    extractQueryCoordinatesFromParams(params) {
        let coordinateString = params.substring(params.indexOf("[") + 1, params.indexOf("]"));
        let coordinates = coordinateString.split(",");
        coordinates = coordinates.map(parseFloat);
        coordinates[2] = coordinates[2] - coordinates[0];
        coordinates[3] = coordinates[3] - coordinates[1];
        return coordinates;
    }

    async displayResultsQbE(query) {
        // Get source tabletfragment 
        let source_tablet = await this.webservices.getTabletAndFragmentName(query.query_id);
        source_tablet = source_tablet.result[0][0];

        // TODO: Get the amount of targets for this query and based upon that information, decide wether to display the QueryByExample mode or the Annotation+Gallery Mode        
        let targetDetails = await this.webservices.getTargetDetailsForQuery(query.query_id);
        targetDetails = targetDetails.result[0];
        if (targetDetails.length === 1 &&
            targetDetails[0].tablet_name === source_tablet.tablet_name) {

            // Set queryByExampleMode to 1
            this.setState({ queryByExampleMode: 1, displayAnnotationMenu: true, tabletName: source_tablet.tablet_name, fragmentName: source_tablet.tablet_fragment_name });
            const canvasElement = this.annotationCanvasElement.current;
            canvasElement.setState({ query_id: query.query_id });
            const index = query.tablet_id.indexOf(']');
            this.getImage(query.tablet_id.substring(1, index), '', true, 1);

            const galleryElement = this.queryGalleryElement.current;
            this.forceUpdate();
            let queryBoundingBox = this.extractQueryCoordinatesFromParams(query.params);
            canvasElement.setCanvasQueryBoundingBox(queryBoundingBox);

            this.webservices.getResultsForQuery(
                query.query_id,
                query.task,
                canvasElement.getResultsQbECallback,
                galleryElement.queryByExampleCallback,
            )
        } else {
            if (this.state.queryByExampleMode === 2) {
                const displayElement = this.queryByExampleDisplay.current;

                displayElement.setState({ query_id: query.query_id });
            } else {
                // Set queryByExampleMode to 2
                this.setState({ currentQuery: query.query_id, queryByExampleMode: 2, displayAnnotationMenu: false });
                await new Promise((r) => setTimeout(r, 1000))
                //const displayElement = this.queryByExampleDisplay.current;
            }
        }
    }

    async displayResultsQbE_OLD(query) {
        const index = query.tablet_id.indexOf(']');
        this.getImage(query.tablet_id.substring(1, index), '', false)

        // TODO: Remove the sleep and use a promise on the getImage method above
        await new Promise((r) => setTimeout(r, 1000))
        const canvasElement = this.annotationCanvasElement.current
        canvasElement.setState({ query_id: query.query_id })

        const galleryElement = this.queryGalleryElement.current
        this.webservices.getResultsForQuery(
            query.query_id,
            query.task,
            canvasElement.getResultsCallback,
            galleryElement.queryByExampleCallback,
        )
    }

    async handleQuerySelect(query) {
        this.setState({ documentLoadedWithDocumentSelect: false, selectedQueryId: query });
        if (query.state === 3) {
            if (query.task === 1) {
                this.setState({ displayAnnotationMenu: true });
                this.displayResultsAnnotate(query);
            } else if (query.task === 2) {
                this.displayResultsQbE(query);
            }
        } else {
            // TODO: Display error message: task is not done yet
            // <Alert severity="error">This is an error alert — check it out!</Alert>
        }
    }

    confidenceChange(n) {
        //console.log("Confidence changed to: " + n);
    }

    numCandidatesChange(n) {
        //console.log("Number of candidates changed to: " + n);
        console.log("numCandidatesChange: " + n)
        this.annotationCanvasElement.current.setState({ queryByExampleNumCandidates: n }, () => this.annotationCanvasElement.current.issueRedraw());
    }

    handleBatchConfirmClick() {
        this.webservices.confirmAnnotationPredictions(this.state.currentQuery);
    }

    handleLogin() {
        this.setState({ logged_in: true });
        //this.documentSelectElement.current.getTablets();
    }

    handleLogout() {
        this.setState({ logged_in: false });
        localStorage.setItem('username', '');
        localStorage.setItem('token', '');
        localStorage.setItem('refreshToken', '');
    }

    annotationsConfirmed(selectedAnnotationsThatWereConfirmed) {
        let indices = [];
        for (var i = 0; i < selectedAnnotationsThatWereConfirmed.length; i++) {
            if (selectedAnnotationsThatWereConfirmed[i] === true) {
                indices.push(i);
            }
        }
        this.annotationCanvasElement.current.updateConfirmedStateOfAnnotations(indices);
    }

    handleClose = () => {
        this.setState({ isOpenError: false });
    }

    render() {
        return (
            <div>
                {!this.state.logged_in &&
                    <LoginDialog
                        hideMenus={() => {
                            this.hideMenus(false, false)
                        }}
                        onSuccess={() => {
                            this.handleLogin();
                        }}
                    />
                }
                {this.state.logged_in &&
                    <HotkeysTarget2 hotkeys={this.hotkeys}>
                        {({ handleKeyDown, handleKeyUp }) => (
                            <div className="App" tabIndex={0} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
                                <header className="App-Header">
                                    <Navbar>
                                        <NavbarGroup align={Alignment.LEFT}>
                                            <NavbarHeading>Cuneiform AI</NavbarHeading>
                                            <NavbarDivider />
                                            <Tooltip
                                                content="Click to logout and to remove credentials from cookies."
                                                position={Position.BOTTOM}
                                            >
                                                <Button
                                                    text="Logout"
                                                    icon="log-out"
                                                    intent={Intent.PRIMARY}
                                                    onClick={() => {
                                                        this.handleLogout();
                                                    }}
                                                />
                                            </Tooltip>
                                            <DocumentSelect
                                                ref={this.documentSelectElement}
                                                hideMenus={() => {
                                                    this.hideMenus(false, true)
                                                }}
                                                annotationCanvasTarget={this.annotationCanvasElement}
                                                onItemSelect={
                                                    (id, name, tabletName, type) => {
                                                        console.log(tabletName + " / " + name + "    Type: " + type);
                                                        this.setState(
                                                            { queryByExampleMode: 0, documentLoadedWithDocumentSelect: true, displayAnnotationMenu: true, tabletName: tabletName, fragmentName: name, representationType: type }, () => this.getImage(id, name, true)
                                                        )
                                                    }
                                                }
                                            />
                                            <QueryDialog
                                                ref={this.queryDialogElement}
                                                hideMenus={() => {
                                                    this.hideMenus(true, true)
                                                }}
                                                onSuccess={(task, targets, model) => {
                                                    this.handleTask(task, targets, model)
                                                }}
                                            />
                                            <QuerySelect
                                                ref={this.querySelectElement}
                                                hideMenus={() => {
                                                    this.hideMenus(true, false)
                                                }}
                                                username={localStorage.getItem('username')}
                                                onItemSelect={(query) => this.setState({ currentQuery: query }, () => this.handleQuerySelect(query))}
                                            />
                                            <UploadFileDialog
                                                hideMenus={() => {
                                                    this.hideMenus(true, true)
                                                }}
                                                onSuccess={(file, tablet, fragment) => {
                                                    this.handleFileUpload(file, tablet, fragment)
                                                }}
                                            />
                                        </NavbarGroup>
                                        <NavbarGroup align={Alignment.RIGHT}>
                                            <ThemeSwitcher ref={this.ThemeSwitcherElement} />
                                            <HelpMenu />
                                        </NavbarGroup>
                                    </Navbar>
                                    {this.state.displayAnnotationMenu === true &&
                                        <Navbar>
                                            <NavbarGroup align={Alignment.LEFT}>
                                                <NavbarHeading>Annotation Menu</NavbarHeading>
                                                <NavbarDivider />
                                                <Tooltip position={Position.BOTTOM} content="Enables/disables select rectangle mode. (Hotkey S)">
                                                    <Button
                                                        className={Classes.MINIMAL}
                                                        icon="new-text-box"
                                                        text="Select&nbsp;Rectangle"
                                                        active={this.state.boxSelectModeActive}
                                                        onClick={() => this.handleSelectBoxModeChange()}
                                                    />
                                                </Tooltip>
                                                <AnnotationPopUpAdd
                                                    hideMenus={() => {
                                                        this.hideMenus(true, true)
                                                    }}
                                                    onSuccess={(signclass) => {
                                                        this.handleAddAnnotation(signclass)
                                                    }}
                                                />
                                                <AnnotationPopUpRemove
                                                    hideMenus={() => {
                                                        this.hideMenus(true, true)
                                                    }}
                                                    onSuccess={() => {
                                                        this.handleRemoveAnnotation()
                                                    }}
                                                />
                                                <Tooltip position={Position.BOTTOM} content="Hides/shows Annotations. (Hotkey H)">
                                                    <Button
                                                        className={Classes.MINIMAL}
                                                        icon="new-text-box"
                                                        text="Hide Annotations"
                                                        active={this.state.hideAnnotationsActive}
                                                        onClick={() =>
                                                            /*this.handleOpenAlertHideAnnotations*/
                                                            this.handleHideAnnotationsChange()
                                                        }
                                                    />
                                                </Tooltip>
                                                {/*
                                                <Alert
                                                    {...this.alertProps}
                                                    cancelButtonText="Cancel"
                                                    confirmButtonText="Confirm"
                                                    icon="new-text-box"
                                                    intent={Intent.DANGER}
                                                    isOpen={this.state.isOpenHideAnnotations}
                                                    loading={this.state.isLoadingHideAnnotations}
                                                    onCancel={this.handleCancelHideAnnotations}
                                                    onConfirm={() => {
                                                        this.handleCloseAlertHideAnnotations();
                                                        this.handleHideAnnotationsChange();
                                                    }}
                                                >
                                                    <p>
                                                        Are you sure you want to hide/show annotations?
                                                    </p>
                                                </Alert>
                                                */}
                                                <Button
                                                    icon="add"
                                                    text="Batch Confirm"
                                                    onClick={() => this.handleBatchConfirmClick()}
                                                />
                                                <SignSelectBox
                                                    ref={this.signSelectBox}
                                                    allowCreate={true}
                                                    hasInitialContent={false}
                                                    AnnotationCanvas fill={false}
                                                    selectSign={(sign) => this.annotationCanvasElement.current.addSignToFilter(sign)}
                                                    deselectSign={(sign) => this.annotationCanvasElement.current.removeSignFromFilter(sign)}
                                                    clearSigns={() => this.annotationCanvasElement.current.clearFilter()}>
                                                </SignSelectBox>
                                            </NavbarGroup>
                                            <NavbarGroup align={Alignment.RIGHT}>
                                                <Button
                                                    className={Classes.MINIMAL}
                                                    icon="bring-data"
                                                    onClick={() => this.handleGetAnnotationsForTraining()}
                                                />
                                                <Button
                                                    className={Classes.MINIMAL}
                                                    icon="bring-data"
                                                    onClick={() => this.handleGetQueryByExamplePredictions()}
                                                />
                                                <Button
                                                    className={Classes.MINIMAL}
                                                    icon="zoom-to-fit"
                                                    onClick={() => this.handleZoomToFit()}
                                                />
                                            </NavbarGroup>
                                        </Navbar>
                                    }
                                </header>

                                <div className="App-Content">
                                    {this.state.queryByExampleMode === 0 &&
                                        <AnnotationCanvas ref={this.annotationCanvasElement} path={''} />
                                    }
                                    {this.state.queryByExampleMode === 1 &&
                                        /* SplitPane defines the working canvas underneath the menubar  */
                                        <SplitPane
                                            split="vertical"
                                            defaultSizes={[80, 20]}
                                            minSize={[500, 200]}
                                        >
                                            {/* AnnotationCanvas defines the working space on the left side
                                                where photographs of cuneiform tablets/fragments are displayed */}
                                            <div className="Pane">
                                                <AnnotationCanvas ref={this.annotationCanvasElement} path={''} />
                                            </div>

                                            {/* QueryGallery defines the canvas on the right side
                                                where retrieval lists are display */}
                                            <div className="Pane">
                                                <QueryGallery
                                                    ref={this.queryGalleryElement}
                                                    focusOnSelection={(box) => this.focusOnAnnotation(box)}
                                                    numCandidatesChange={(n) => this.numCandidatesChange(n)}
                                                    confidenceChange={(n) => this.confidenceChange(n)}
                                                    confirmAnnotations={(arr) => this.annotationsConfirmed(arr)}
                                                />
                                            </div>
                                        </SplitPane>
                                    }
                                    {this.state.queryByExampleMode === 2 &&
                                        <QueryByExampleDisplay
                                            ref={this.queryByExampleDisplay}
                                            query_id={this.state.currentQuery} />
                                    }
                                </div>

                                <Dialog
                                    icon="warning-sign"
                                    title="Error message"
                                    ref={this.errorDialog}
                                    onClose={this.handleClose}
                                    isOpen={this.state.isOpenError}
                                    {...this.state}
                                >
                                    <div className={Classes.ALERT_BODY}>
                                        <div className={Classes.ALERT_CONTENTS}>
                                            {this.state.errorMessage}
                                        </div>
                                    </div>
                                </Dialog>
                            </div>
                        )}
                    </HotkeysTarget2>
                }
            </div>
        )
    }
}

export default App;