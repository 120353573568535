// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".retrieval-list-control_input_container__2YwkD{\n    display: flex;\n    flex-direction: row;\n\n    justify-content: center;\n    align-content: center;\n\n    border: none;\n\n    height: auto;\n    width: 50%;\n\n    padding: 0;\n    margin: 0;\n}\n\n.retrieval-list-control_form_group__3Cj1V{\n    font-size: 9pt;\n    margin: 2%;\n}\n", "",{"version":3,"sources":["webpack://src/components/retrieval-list-control/retrieval-list-control.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;;IAEnB,uBAAuB;IACvB,qBAAqB;;IAErB,YAAY;;IAEZ,YAAY;IACZ,UAAU;;IAEV,UAAU;IACV,SAAS;AACb;;AAEA;IACI,cAAc;IACd,UAAU;AACd","sourcesContent":[".input_container{\n    display: flex;\n    flex-direction: row;\n\n    justify-content: center;\n    align-content: center;\n\n    border: none;\n\n    height: auto;\n    width: 50%;\n\n    padding: 0;\n    margin: 0;\n}\n\n.form_group{\n    font-size: 9pt;\n    margin: 2%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_container": "retrieval-list-control_input_container__2YwkD",
	"form_group": "retrieval-list-control_form_group__3Cj1V"
};
export default ___CSS_LOADER_EXPORT___;
