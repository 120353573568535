import { clientConfig } from './../../config.js';

import React from 'react';
import { MenuItem, Button } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { customFetch } from "./../utils/utils";

import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";


import {
    areTabletsEqual,
    createTablet,
    tabletSelectProps,
    ITablet,
    maybeAddCreatedTabletToArrays,
    maybeDeleteCreatedTabletFromArrays,
    renderCreateTabletOption,
    arrayContainsTablet,
} from "./tablet";
import Webservices from '../webservices';

const TabletSelect = Select.ofType<ITablet>();

export interface ITabletSelectProps {
    onItemSelect: ((tablet: ITablet) => void),
    initialItem: ITablet,
}

export interface ITabletSelectState {
    allowCreate: boolean;
    closeOnSelect: boolean;
    createdItems: ITablet[];
    disableItems: boolean,
    disabled: boolean,
    filterable: boolean,
    tablet: ITablet | undefined;
    items: ITablet[];
    minimal: boolean;
    openOnKeyDown: boolean;
    resetOnClose: boolean;
    resetOnQuery: boolean;
    resetOnSelect: boolean;
}

interface jsonTablet {
    /** ID of tablet. */
    id: number;
    /** Name of tablet. */
    name: string;
    /** Finding place of tablet. */
    location: number;
    /** Number of fragments. */
    num_frags: number;
}

class TabletSelectBox extends React.PureComponent<ITabletSelectProps, ITabletSelectState> {
    tabletSelectElement = React.createRef<Select<ITablet>>();
    webservices: Webservices;

    constructor(props: ITabletSelectProps, state: ITabletSelectState) {
        super(props);
        this.state = {
            allowCreate: false,
            closeOnSelect: true,
            createdItems: [],
            disableItems: false,
            disabled: false,
            filterable: true,
            tablet: props.initialItem,
            items: tabletSelectProps.items,
            minimal: true,
            openOnKeyDown: false,
            resetOnClose: false,
            resetOnQuery: true,
            resetOnSelect: false,
        };
        this.webservices = new Webservices();
    }

    public getTabletName(): string {
        return (this.state.tablet) ? this.state.tablet.name : "";
    }

    public getTablets() {
        let initialTablets = new Array<jsonTablet>();
        let requestHeaders: any = { 'Authorization': localStorage.getItem('token') };

        customFetch(clientConfig.webApiProtocol + clientConfig.webApiUrl + ":" + clientConfig.webApiPort + "/api/tabletListWithNumberOfFragments", {
            method: 'GET',
            headers: requestHeaders,
        }).then(response => {
            return response.json();
        }).then(data => {
            initialTablets = data.message;
            //console.log(initialTablets);
            tabletSelectProps.items = initialTablets.map(function (item) { return createTablet(item.id, item.name, item.num_frags + 0) }); //ToDo: insert fragment count
            tabletSelectProps.items.sort((a, b) => (a.name > b.name) ? 1 : -1); // sort by name
            this.setState({ items: tabletSelectProps.items });
            if (!arrayContainsTablet(tabletSelectProps.items, this.state.tablet)) {
                this.setState({ tablet: undefined });
            }
        });
    }

    componentDidMount() {
        this.getTablets();
    }

    render() {
        const { allowCreate, tablet, minimal, disabled, filterable, items,
            resetOnClose, resetOnQuery, resetOnSelect } = this.state;

        //const maybeCreateNewItemFromQuery = allowCreate ? createTablet : undefined;
        const maybeCreateNewItemRenderer = allowCreate ? renderCreateTabletOption : undefined;

        return (
            <>
                <label className="bp3-label">
                    Tablets:
                    <TabletSelect
                        {...tabletSelectProps}
                        filterable={filterable}
                        resetOnClose={resetOnClose}
                        resetOnQuery={resetOnQuery}
                        resetOnSelect={resetOnSelect}
                        className="fragment-select"
                        //createNewItemFromQuery={maybeCreateNewItemFromQuery}
                        createNewItemRenderer={maybeCreateNewItemRenderer}
                        itemsEqual={areTabletsEqual}
                        ref={this.tabletSelectElement}
                        // we may customize the default tabletSelectProps.items by
                        // adding newly created items to the list, so pass our own.
                        items={items}
                        //activeItem={this.state.tablet}
                        noResults={<MenuItem disabled={true} text="No results." />}
                        initialContent={<MenuItem disabled={true} text={tabletSelectProps.items.length + " tablets loaded."} />}
                        onItemSelect={this.handleValueChange}
                        popoverProps={{ minimal }}
                        scrollToActiveItem={false}
                    >
                        <Button
                            icon="layers"
                            rightIcon="caret-down"
                            text={tablet ? `${tablet.name} (${tablet.fragmentCount})` : "(No selection)"}
                            disabled={disabled}
                        />
                    </TabletSelect>
                </label>
            </>
        );
    }

    private handleValueChange = (selectedTablet: ITablet) => {
        // delete the old tablet from the list if it was newly created
        const { createdItems, items } = maybeDeleteCreatedTabletFromArrays(
            this.state.items,
            this.state.createdItems,
            this.state.tablet,
        );
        // add the new tablet to the list if it is newly created
        const { createdItems: nextCreatedItems, items: nextItems } = maybeAddCreatedTabletToArrays(
            items,
            createdItems,
            selectedTablet,
        );
        // set state and notify parent when complete
        this.setState({ createdItems: nextCreatedItems, tablet: selectedTablet, items: nextItems },
            () => this.props.onItemSelect(selectedTablet)
        );
    };
}

export default TabletSelectBox;