import React from "react";
import {Classes, Button} from "@blueprintjs/core"

import {color} from "../utils/utils";
import styles from "./retrieval-list-candidates.module.css";
import {rgbaStringColorMap, scoreToColor} from "../color/color";

const {INTENT_SUCCESS, INTERACTIVE} = Classes;

export interface IRetrievalListCandidatesProps {
    // this event will be invoked if the user
    // click on the select button of a candidate
    images: string[],
    imagesContext: string[],
    confidences: number[],
    visible: boolean[],
    selected: boolean[],
    onButtonClick: (index: number) => void,
    onImageClick: (index: number) => void,
    classes: string[],
}

export class RetrievalListCandidates extends React.PureComponent<IRetrievalListCandidatesProps> {

    public render() {
        const {images, imagesContext, confidences, visible, selected, classes} = this.props;

        const buttonClasses = selected.map((s) => s ? INTENT_SUCCESS : INTERACTIVE);
        const visualize = visible.filter(v => v)

        return (
            <div className={styles.scroll_container}>
                {visualize.map(
                    (isVisible, index) => {
                        return (
                            <React.Fragment>
                                <div className={styles.candidate_container}
                                     //style={{backgroundColor: color(confidences[index])}}>
                                     style={{backgroundColor: scoreToColor(rgbaStringColorMap, confidences[index])}}>

                                    <div className={styles.text_container}>
                                        <p className={styles.text}>
                                            {index + 1}:
                                        </p>
                                    </div>

                                    <div className={styles.button_container}>
                                        <Button className={buttonClasses[index]}
                                                icon="tick"
                                                onClick={() => this.props.onButtonClick(index)}
                                                aria-label={"button for retrieval candidate: " + index + " selection"}/>
                                    </div>

                                    <div className={styles.button_container}>
                                        <p className={styles.text}>
                                            {classes[index]}
                                        </p>
                                    </div>

                                    <div className={styles.image_container}>
                                        <ul className={styles.enlarge}>
                                        <li>
                                            <img className={styles.image}
                                                src={images[index]}
                                                key={index}
                                                alt={'retrieval candidate with index: ' + index}
                                                onClick={() => this.props.onImageClick(index)}
                                            />
                                            <span>
                                                <img className={styles.image}
                                                    src={imagesContext[index]}
                                                    key={index}
                                                />  
                                            </span>
                                        </li>
                                        </ul>
                                    </div>

                                    <div className={styles.confidence_container}>
                                        <p className={styles.text}>
                                            {confidences[index].toFixed(2)}
                                        </p>
                                    </div>

                                </div>
                            </React.Fragment>
                        )
                    }
                )}
            </div>
        )
    }
}

export default RetrievalListCandidates;