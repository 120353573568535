/*
  Query gallery implements the retrieval list display on the right side
  of the web frontend. On the top the user defined (selected) query is displayed.
  Below, the retrieved elements (images/thumbnails) are displayed.
*/
import React, { Component } from 'react'
import { Button, Classes, FormGroup } from '@blueprintjs/core'
import SignSelectBox from "../sign-select-box";
import RetrievalListControl from "../retrieval-list-control";
import RetrievalListCandidates from "../retrieval-list-candidates";
import TextSelectBox from "../text-select-box";
import EpochSelectBox from "../epoch-select-box";
import styles from "./querygallery.module.css";
import { Target } from "./../query-by-example-displayer/targets-list";
import { saveAs } from "file-saver";

import { Query, Candidate, Controller, BoundingBox } from "../data-types/retrieval-list-types";
import { Boxes, Thumbnails } from "../data-types/api-types";

/** ################################################################################################################ **/
/** ############# This part is implemented for demonstration purposes and will be removed step by step ############# **/
import query_example from '../../images/retrieval_examples/na_query.png'
import retrieved_candidate_0 from '../../images/retrieval_examples/candidate_0.png'
import retrieved_candidate_2 from '../../images/retrieval_examples/candidate_1.png'
import retrieved_candidate_1 from '../../images/retrieval_examples/candidate_2.png'
import retrieved_candidate_3 from '../../images/retrieval_examples/candidate_3.png'
import Webservices from '../webservices/webservices';
const randomCandidates = [
    retrieved_candidate_0,
    retrieved_candidate_1,
    retrieved_candidate_2,
    retrieved_candidate_3
]
/** ############# This part is implemented for demonstration purposes and will be removed step by step ############# **/
/** ################################################################################################################ **/

export interface IQueryGalleryProps {
    path: string
    target: Target
    focusOnSelection: (box: any) => void
    numCandidatesChange: (n: any) => void
    confidenceChange: (n: any) => void
    confirmAnnotations: (array: boolean[]) => void
    showFileName: boolean
    queryImg: string
    contextImg: string
}

export interface IQueryGalleryState {
    queryImg: string
    contextImg: string
    // thumbnails contains a path to cropped images (retrieved candidates)
    imgQuery: string
    imgCandidates: string[]
    imgContext: string[]
    bbxQuery: any[]
    bbxCandidates: any[]
    // boxes contains four numbers representing bounding boxes coordinates
    boxes: BoundingBox[]
    // confidence defines a score estimated by the ai model within range (0% - 100%)
    confidences: number[]
    // defines the states of buttons next to retrieval candidates which can be selected for annotation confirmations
    selected: boolean[]
    // defines a string which contains a blueprintjs button class
    // for example Classes.INTENT_SUCCESS for a green button
    // this state will change the color of the select/deselect all button
    // from green (select all retrieval candidates) to red (deselect all candidates)
    buttonSelectClass: string
    buttonDeselectClass: string
    // defines a string which contains a blueprintjs button text
    // for example the text "Select All" for a green button
    // this state will alternate the text of the selection button
    // between the "Select All" and "Deselect All" depending on the current button state
    buttonSelectText: string
    // define button isActive states
    btnSelectAllIsDisabled: boolean
    btnDeselectAllIsDisabled: boolean

    maxCandidates: number
    minConfidence: number
    numCandidatesVisible: number
    isCandidateVisible: boolean[]

    query: Query,
    controller: Controller,
    candidate: Candidate[],
    boxescomplete: Boxes[],
}

class QueryGallery extends Component<IQueryGalleryProps, IQueryGalleryState> {
    // queryGalleryElement = React.createRef<HTMLCanvasElement>()
    webservices = new Webservices();
    canvasQueryWithContext: any;
    signClassBox: any;

    constructor(props: IQueryGalleryProps) {
        super(props)

        const numCandidates = 50
        const minConfidence = 50

        const random_indices = new Array<number>(numCandidates)
            .fill(0.0)
            .map(() => Math.floor(Math.random() * randomCandidates.length))
        let imgCandidates = random_indices.map((i) => randomCandidates[i]);

        const confidences = new Array<number>(numCandidates)
            .fill(0.0)
            .map(() => Math.random() * 40 + 60)
            .sort((a, b) => b - a);
        let visibleCandidates = confidences.map((c, i) => c > minConfidence && i < numCandidates);
        let selected = new Array<boolean>(numCandidates).fill(false);

        const query = this.setQuery(query_example, [0, 0, 0, 0], ['AN', 'TI', 'NA'], [99.64, 87.53, 76.93]);
        const boundingBoxes = new Array<BoundingBox>(imgCandidates.length).fill([0, 0, 0, 0])
        const signs = new Array<string>(imgCandidates.length).fill('AN')
        const candidates = this.setCandidates(imgCandidates, boundingBoxes, signs, confidences)

        const controller: Controller = {
            currentCandidates: 0,
            currentConfidence: 0,
            maxCandidates: 0,
            minConfidence: 0,
        }

        this.state = {
            queryImg: this.props.queryImg,
            contextImg: this.props.contextImg,
            imgQuery: "",
            imgCandidates: imgCandidates,
            imgContext: imgCandidates,
            bbxQuery: [],
            bbxCandidates: [],
            boxes: boundingBoxes,
            confidences: confidences,
            selected: selected,
            buttonSelectClass: Classes.INTENT_SUCCESS,
            buttonDeselectClass: Classes.INTENT_DANGER,
            btnSelectAllIsDisabled: false,
            btnDeselectAllIsDisabled: true,
            buttonSelectText: "Select All",
            maxCandidates: imgCandidates.length,
            minConfidence: minConfidence,
            numCandidatesVisible: numCandidates,
            isCandidateVisible: visibleCandidates,
            query: query,
            candidate: candidates,
            controller: controller,
            boxescomplete: [],
        }

        // bind class methods as explained in the react tutorial
        // https://reactjs.org/docs/handling-events.html
        this.queryByExampleCallback = this.queryByExampleCallback.bind(this)
        this.handleQueryClick = this.handleQueryClick.bind(this)
        this.handleCandidateClick = this.handleCandidateClick.bind(this)
        this.handleSelectItem = this.handleSelectItem.bind(this)
        this.handleSelectAll = this.handleSelectAll.bind(this)
        this.handleDeselectAll = this.handleDeselectAll.bind(this)
        this.handleDownload = this.handleDownload.bind(this)

        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleConfidenceChange = this.handleConfidenceChange.bind(this);
        this.handleInfoClick = this.handleInfoClick.bind(this);
        this.handleConfirmAnnotations = this.handleConfirmAnnotations.bind(this);
        this.canvasQueryWithContext = React.createRef();
        this.signClassBox = React.createRef();
    }

    async componentDidMount() {
        if (this.props.target !== undefined) {
            var id: number = this.props.target.id;
            var results: any = await this.webservices.getResultsForQbE(id);
            var thumbnails: any = await this.webservices.getThumbsnailsForQBE(id);

            //console.log(results);
            this.displayResults(thumbnails, results);
            this.getImages();

            //console.log(this.state.candidate);
        }
    }

    private async getImages() {
        // Get the whole image
        var tabletName: string = this.props.target.tablet;
        var fragmentName: string = this.props.target.fragment;
        let image = this.webservices.getImageByName(tabletName, fragmentName);

        let contextImages: HTMLImageElement[] = [];

        // Extract the corresponding region for each BB
        this.state.boxes.forEach(async element => {
            // element is the box with 4 coordinates in an array            
            let croppedImg: any = await this.cropImage(image, element[0], element[1], element[2] - element[0], element[3] - element[1]);
            contextImages.push(croppedImg);
        });
        /*
        this.setState({
            imgContext: contextImages,
        });
        */
    }

    private cropImage(image: any, x: number, y: number, w: number, h: number) {
        let curElement = this.canvasQueryWithContext.current;

        var img = new Image();
        const ctx = curElement.getContext('2d');

        var promise = new Promise((resolve, reject) => {
            img.onload = function () {
                ctx.drawImage(img, x, y, w, h, 0, 0, 200, 200);
                resolve("Promise resolved successfully");
                console.log(img);
            }
            img.src = image;
        });

        promise.then(function (result) {
            return img;
        }, err => {
            console.log(err); // Error: "Promise rejected"
            return img;
        });
    }

    displayResults(thumbnails: any[], results: any[][]) {
        const images: string[] = thumbnails.map(t => t.thumbnail)

        const boxes: Boxes[] = results.map(
            (r) => {
                return {
                    query_id: r[0],
                    sign: r[1],
                    score: (r[2] * 100.),
                    coordinates: r[3]
                }
            }
        )

        // get first object of images array as query
        const queryImage = images[0]
        const queryBoundingBox = boxes[0].coordinates
        //const queryScore = boxes[0].score

        // get the rest of images objects as retrieved items (annotation candidates)
        let candidateImages: Thumbnails['thumbnail'][] = [];
        let candidateSigns: Boxes["sign"][] = [];
        let candidateScores: Boxes["score"][] = [];
        let candidateBoundingBoxes: Boxes["coordinates"][] = [];
        for (let i = 1; i < images.length; i++) {
            candidateImages.push(images[i])
            candidateSigns.push(boxes[i].sign)
            candidateScores.push(boxes[i].score)
            candidateBoundingBoxes.push(boxes[i].coordinates)
        }

        const maxCandidates = candidateImages.length;
        const minConfidence = 50;

        // TODO: confidences will be predicted by model, and not randomly generated
        let visibleCandidates = candidateScores.map(
            (c, i) => c > minConfidence && i < maxCandidates
        )

        const selected = new Array<boolean>(maxCandidates).fill(false)

        const query = this.setQuery(queryImage, queryBoundingBox, ['AN', 'TI', 'NA'], [99.64, 87.53, 76.93]);
        const boundingBoxes = new Array<BoundingBox>(candidateImages.length).fill([0, 0, 0, 0])
        const candidates = this.setCandidates(candidateImages, boundingBoxes, candidateSigns, candidateScores)

        this.setState({
            imgQuery: queryImage,
            imgCandidates: candidateImages,
            imgContext: candidateImages,
            bbxQuery: queryBoundingBox,
            bbxCandidates: candidateBoundingBoxes,
            boxes: boxes.map(obj => obj.coordinates),
            boxescomplete: boxes,
            confidences: candidateScores,
            selected: selected,
            maxCandidates: maxCandidates,
            btnSelectAllIsDisabled: false,
            btnDeselectAllIsDisabled: true,
            isCandidateVisible: visibleCandidates,
            query: query,
            candidate: candidates
        }, () => { })
    }

    private setQuery(image: string, boundingBox: BoundingBox, signs: string[], scores: number[]): Query {
        return {
            thumbnail: {
                image: image,
                boundingBox: boundingBox
            },
            top3: [
                { sign: signs[0], score: scores[0] },
                { sign: signs[1], score: scores[1] },
                { sign: signs[2], score: scores[2] }
            ]
        }
    }

    private setCandidate(
        image: string,
        boundingBox: BoundingBox,
        sign: string,
        score: number,
        index: number
    ): Candidate {
        return {
            thumbnail: { image: image, boundingBox: boundingBox },
            prediction: { sign: sign, score: score },
            index: index, selected: false, visible: true
        }
    }

    private setCandidates(images: string[], boundingBoxes: BoundingBox[], signs: string[], scores: number[]): Candidate[] {
        return images.map(
            (image, index) =>
                this.setCandidate(image, boundingBoxes[index], signs[index], scores[index], index)
        );
    }

    private mapSmallerIndex(
        array: number[],
        threshold: number
    ): boolean[] {
        return array.map((v, i) => i < threshold)
    }

    private mapBiggerValue(
        array: number[],
        threshold: number
    ): boolean[] {
        return array.map((v) => v > threshold)
    }

    private getVisibleCandidates(
        confidences: number[],
        isMaxCandidates: boolean
    ): boolean[] {
        const { maxCandidates, minConfidence } = this.state;
        return isMaxCandidates ?
            this.mapSmallerIndex(confidences, maxCandidates) :
            this.mapBiggerValue(confidences, minConfidence)
    }

    private getNewSelected(
        currentSelected: boolean[],
        visibleCandidates: boolean[]
    ): boolean[] {
        return visibleCandidates.map(
            (visible, index) =>
                visible ? currentSelected[index] : false)
    }

    private static getVisibleAndSelected(
        selectedCandidates: boolean[],
        numVisibleCandidates: number
    ): boolean[] {
        return selectedCandidates.slice(0, numVisibleCandidates)
    }

    private static getNumCandidates(
        visibleCandidates: boolean[]
    ): number {
        return visibleCandidates.filter(isVisible => isVisible).length
    }

    /**
     *
     */
    private changeVisibleCandidates(
        eventElementId: string,
        numCandidates: number,
        minConfidence: number,
    ) {
        // get necessary states
        const { selected, confidences } = this.state;

        let amountOfElementsThatHaveHigherConfidence = confidences.filter((x) => { return x > minConfidence }).length;
        let numElementsToBeShown = amountOfElementsThatHaveHigherConfidence < numCandidates ? amountOfElementsThatHaveHigherConfidence : numCandidates;
        let visibleSelected: boolean[] = QueryGallery.getVisibleAndSelected(selected, numElementsToBeShown);
        const isCandidateVisible = Array(numElementsToBeShown).fill(true).concat(Array(this.state.imgCandidates.length - numElementsToBeShown).fill(false));

        // get number of candidates which needs to be visualized
        let numCandidatesVisible: number = QueryGallery.getNumCandidates(isCandidateVisible);

        this.props.numCandidatesChange(numCandidatesVisible);

        this.setState({
            selected: this.getNewSelected(selected, isCandidateVisible),
            numCandidatesVisible: numCandidatesVisible,
            isCandidateVisible: isCandidateVisible,
            btnSelectAllIsDisabled: visibleSelected.every(s => s),
            btnDeselectAllIsDisabled: visibleSelected.every(s => !s)
        })
    }

    /**
     * This function is called if user is requesting retrieval results.
     * Updates the states of select all button
     * @param {Thumbnail[]} images - Array of strings containing cropped images of retrieved candidates
     * @param {any[]} boxes - Array of coordinates for corresponding candidates retrieved
     **/
    queryByExampleCallback(thumbnails: any[], results: any[][]) {
        console.log('query by example callback ...')
        //console.log(results);
        const images: string[] = thumbnails.map(t => t.thumbnail)
        const boxes: Boxes[] = results.map(
            (r) => {
                return {
                    query_id: r[0],
                    sign: r[1],
                    score: (r[2] * 100.),
                    coordinates: r[3]
                }
            }
        )

        // get first object of images array as query
        console.log(boxes);
        if (boxes.length === 0) {
            console.log("Boxes has length 0!");
        } else {
            const queryImage = images[0]
            const queryBoundingBox = boxes[0].coordinates
            //const queryScore = boxes[0].score // <- Why is this not used anywhere?

            // get the rest of images objects as retrieved items (annotation candidates)
            let candidateImages: Thumbnails['thumbnail'][] = [];
            let candidateSigns: Boxes["sign"][] = [];
            let candidateScores: Boxes["score"][] = [];
            let candidateBoundingBoxes: Boxes["coordinates"][] = [];
            for (let i = 0; i < images.length; i++) {
                candidateImages.push(images[i])
                candidateSigns.push(boxes[i].sign)
                candidateScores.push(boxes[i].score)
                candidateBoundingBoxes.push(boxes[i].coordinates)
            }
            //console.log(candidateBoundingBoxes);
            const maxCandidates = candidateImages.length;
            const minConfidence = 50;

            // TODO: confidences will be predicted by model, and not randomly generated

            let visibleCandidates = candidateScores.map(
                (c, i) => c > minConfidence && i < maxCandidates
            )

            const selected = new Array<boolean>(maxCandidates).fill(false)

            const query = this.setQuery(queryImage, queryBoundingBox, ['AN', 'TI', 'NA'], [99.64, 87.53, 76.93]);
            const boundingBoxes = new Array<BoundingBox>(candidateImages.length).fill([0, 0, 0, 0])
            //const signs = new Array<string>(candidateImages.length).fill('AN')
            const signs = candidateSigns;
            const candidates = this.setCandidates(candidateImages, boundingBoxes, signs, candidateScores)

            this.setState({
                imgQuery: queryImage,
                imgCandidates: candidateImages,
                bbxQuery: queryBoundingBox,
                bbxCandidates: candidateBoundingBoxes,
                boxes: boxes.map(obj => obj.coordinates),
                boxescomplete: boxes,
                confidences: candidateScores,
                selected: selected,
                maxCandidates: maxCandidates,
                btnSelectAllIsDisabled: false,
                btnDeselectAllIsDisabled: true,
                isCandidateVisible: visibleCandidates,
                query: query,
                candidate: candidates
            })
        }
    }

    /**
     * Focus the annotation canvas center around the selected retrieval list item
     */
    handleQueryClick() {
        //console.log('Query image clicked')
        if (this.state.bbxQuery.length > 0) {
            console.log(this.state.bbxQuery)
            this.props.focusOnSelection(this.state.bbxQuery)
        } else {
            //console.log('Nothing to do...')
        }
    }

    /**
     * Focus the annotation canvas center around the selected retrieval list item
     * @param {number} index - index of selected retrieval list item
     */
    handleCandidateClick(index: number) {
        //console.log('Candidate image ' + index + ' clicked')
        if (this.state.bbxCandidates.length > 0) {
            this.props.focusOnSelection(this.state.bbxCandidates[index])
        } else {
            //console.log('Nothing to do...')
        }
    }

    /**
     *
     * @param event
     * @param maxCandidates
     * @param minConfidence
     */
    handleKeyUp(
        event: React.KeyboardEvent<HTMLDivElement>,
        maxCandidates: number,
        minConfidence: number,
    ) {
        event.preventDefault()

        if (event.key === 'Enter') {
            console.log('QueryGallery.handleCandidatesKeyUp() - Enter key pressed -> changing visible candidates')
            this.changeVisibleCandidates(
                event.currentTarget.id,
                maxCandidates,
                minConfidence
            )
        }
    }

    handleInfoClick() {
        console.log('info icon clicked...')
    }

    /**
     * Event gets invoked if input of numeric input component is changed by clicking
     * increase/decrease buttons or up/down arrow on keyboard
     * @param event
     */
    handleConfidenceChange(n: number) {
        /*
        console.log(
            'QueryGallery.handleConfidenceChange() - Event invoked! '
            + this.state.minConfidence + ' '
            + n)
        */
        this.setState({ minConfidence: n })
        //this.props.confidenceChange(n);
        this.changeVisibleCandidates(
            "null",
            this.state.maxCandidates,
            n
        )
    }

    /**
     *
     * @param event
     */
    handleCandidateChange(n: number) {
        /*
        console.log(
            'QueryGallery.handleCandidateChange() - Event invoked! '
            + this.state.maxCandidates + ' '
            + n)
        */
        console.log(this.props.target);
        this.setState({ maxCandidates: n })
        //this.props.numCandidatesChange(n);
        this.changeVisibleCandidates(
            "input_max_candidates",
            n,
            this.state.minConfidence
        )
    }

    handleSelectItem(index: number) {

        // create shallow copy boolean array
        const newSelected = [...this.state.selected]
        // alternate selected item state
        newSelected[index] = !newSelected[index]

        // print console information
        //console.log('Select item button clicked: ' + newSelected)

        // set select all button to disabled if at least one item is selected
        // some() return true if at least one item of selected boolean array is true
        let visibleSelected = newSelected.slice(0, this.state.numCandidatesVisible)
        let btnSelectAllIsDisabled = visibleSelected.every(s => s)
        let btnDeselectAllIsDisabled = visibleSelected.every(s => !s)

        this.setState({
            selected: newSelected,
            btnSelectAllIsDisabled: btnSelectAllIsDisabled,
            btnDeselectAllIsDisabled: btnDeselectAllIsDisabled
        })
    }

    /**
     * This function will be called if "Select All" button is active and gets clicked
     */
    handleSelectAll() {
        // print console information
        console.log('Select all button clicked!')

        this.setState({
            selected: [...this.state.isCandidateVisible],
            btnSelectAllIsDisabled: true,
            btnDeselectAllIsDisabled: false
        })
    }

    handleDeselectAll() {
        // print console information
        console.log('Select all button clicked!')

        this.setState({
            selected: new Array<boolean>(this.state.selected.length).fill(false),
            btnSelectAllIsDisabled: false,
            btnDeselectAllIsDisabled: true
        })
    }

    handleConfirmAnnotations() {
        let selectedItems = this.state.selected;
        let boxes = this.state.boxescomplete;
        let confirmedAnnotations = [];

        for (let i = 0; i < selectedItems.length; i++) {
            if (selectedItems[i] === true) {
                confirmedAnnotations.push(boxes[i].query_id);
            }
        }
        // TODO: In theory, the sign class that will overwrite the existing ones, should be passed as parameter
        // Currently the annotation canvas does not update the annotations immediately (just on reload), therefore the new sign class does not have to be passed (YET)
        if (this.props.confirmAnnotations !== undefined) {
            this.props.confirmAnnotations(selectedItems);
        }

        let selectedSignClasses = this.signClassBox.current.state.signClasses;
        if (selectedSignClasses.length === 1) {
            let classOverwrittenWith = selectedSignClasses[0].sign;
            console.log(classOverwrittenWith);
            this.webservices.confirmQueryByExamplePredictions(confirmedAnnotations, classOverwrittenWith);
        } else {
            this.webservices.confirmQueryByExamplePredictions(confirmedAnnotations);
        }
    }

    handleDownload() {
        console.log("Download clicked");

        var JSZip = require("jszip");
        let predictions = this.state.imgCandidates;
        let candidates = this.state.candidate;
        let queryImg = this.state.queryImg;
        let contextImg = this.state.contextImg;

        var zip = new JSZip();
        // TODO: Include the query
        zip.file("query.jpg", queryImg, { base64: true });
        zip.file("context.jpg", contextImg, { base64: true });

        var img = zip.folder("images");
        let selectedItems = this.state.selected;
        for (let index = 0; index < predictions.length; index++) {
            if (selectedItems[index] === true) {
                img.file(candidates[index].prediction.sign + "_" + this.props.target.tablet + "_" + this.props.target.fragment + "_" + index.toString() + ".jpg", predictions[index].substr(22), { base64: true });
            }
        }

        zip.generateAsync({ type: "blob" }).then((content: any) => {
            saveAs(content, this.props.target.tablet + "_" + this.props.target.fragment + "_queryByExample.zip");
        });
    }

    render() {
        const {
            imgCandidates,
            imgContext,
            maxCandidates,
            confidences,
            isCandidateVisible,
            selected
        } = this.state;

        const initialCandidates = maxCandidates > 30 ? 30 : maxCandidates;
        const initialConfidence = 50.;

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'stretch',
                maxWidth: '100%',
                width: '100%',
                maxHeight: '100%',
                minHeight: '100%',
                marginRight: '1%',
                padding: '1%',
                border: 'solid',
                borderColor: 'orange'
            }}>

                <div id="retrieval" style={{
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    height: '100%',
                    minHeight: '100px',
                    width: '100%',
                    maxWidth: '400px',
                    margin: '0%',
                    padding: '1%',
                    border: 'solid 2px darkgray',
                    borderRadius: '5px',
                    overflowY: 'hidden'
                }}>
                    {/*
                    <div className={styles.query_container}>
                        <RetrievalListQuery
                            onClick={focusOnSelection}
                            queryImage={query.thumbnail.image}
                            boundingBox={query.thumbnail.boundingBox}
                            confidences={query.top3.map(q => q.score)}
                            signClasses={query.top3.map(q => q.sign)}
                        />
                    </div>
                    */}
                    {this.props.showFileName && <div>{this.props.target.tablet + " / " + this.props.target.fragment}</div>}
                    <div className={styles.control_container}>
                        <RetrievalListControl
                            onKeyUp={(e, n, m) => this.handleKeyUp(e, n, m)}
                            initialCandidates={initialCandidates}
                            initialConfidence={initialConfidence}
                            maxCandidates={maxCandidates}
                            changedNum={(n) => this.handleCandidateChange(n)}
                            changedConf={(n) => this.handleConfidenceChange(n)}
                        />
                    </div>

                    <div className={styles.candidate_container} style={{ height: '580px' }}>
                        <RetrievalListCandidates
                            images={imgCandidates}
                            imagesContext={imgContext}
                            confidences={confidences}
                            visible={isCandidateVisible}
                            selected={selected}
                            onImageClick={this.handleCandidateClick}
                            onButtonClick={this.handleSelectItem}
                            classes={this.state.candidate.map(element => element.prediction.sign)}
                        />
                    </div>

                </div>

                <div id="confirm" style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'fit-content',
                    width: '100%',
                    maxWidth: '400px',
                    marginTop: '1%',
                    padding: '1%',
                    borderStyle: 'solid',
                    borderWidth: '2px',
                    borderRadius: '5px',
                    borderColor: 'darkgray',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        padding: '1%',
                        margin: '0%',
                        width: '95%',
                        height: '70%',
                        border: 'none',
                    }}>
                        <FormGroup
                            style={{
                                display: 'flex',
                                width: '100%',
                                border: 'none',
                                margin: '0%'
                            }}
                            label={"Text type:"}
                            labelFor={"text_select_box"}
                        >
                            <TextSelectBox id={"text_select_box"} />
                        </FormGroup>
                        <FormGroup
                            style={{
                                display: 'flex',
                                width: '100%',
                                border: 'none',
                                margin: '0%'
                            }}
                            label={"Epoch:"}
                            labelFor={"epoch_select_box"}
                        >
                            <EpochSelectBox id={"epoch_select_box"} />
                        </FormGroup>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '1%',
                        margin: '0%',
                        width: '95%',
                        height: '70%',
                        border: 'none',
                    }}>
                        <FormGroup
                            style={{
                                display: 'flex',
                                width: '100%',
                                border: 'none',
                                margin: '0%'
                            }}
                            label={"Sign Classes:"}
                            labelFor={"sign_class_multi_select"}
                        >
                            <SignSelectBox id={"sign_class_multi_select"}
                                ref={this.signClassBox}
                                allowCreate={true}
                                hasInitialContent={true} />
                        </FormGroup>

                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        margin: '2px',
                        width: '95%',
                        height: '33%',
                        border: 'none'
                    }}>
                        <Button
                            className={Classes.INTENT_SUCCESS}
                            style={{
                                marginLeft: '5%',
                                marginRight: '2.5%',
                                width: '45%',
                                height: 'auto',
                            }}
                            text={"Select All"}
                            rightIcon="add"
                            onClick={this.handleSelectAll}
                            disabled={this.state.btnSelectAllIsDisabled}
                        />

                        <Button
                            className={Classes.INTENT_DANGER}
                            style={{
                                marginLeft: '2.5%',
                                marginRight: '5%',
                                width: '45%',
                                height: 'auto',
                            }}
                            text={"Deselect All"}
                            rightIcon="remove"
                            onClick={this.handleDeselectAll}
                            disabled={this.state.btnDeselectAllIsDisabled}
                        />
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: '2px',
                        width: '95%',
                        height: 'auto',
                        border: 'none'
                    }}>
                        <Button
                            className={Classes.INTENT_SUCCESS}
                            style={{
                                width: '65%',
                                height: 'auto',
                            }}
                            text="Confirm Annotations"
                            rightIcon="tick-circle"
                            onClick={this.handleConfirmAnnotations}
                        />
                        <Button
                            className={Classes.INTENT_SUCCESS}
                            style={{
                                width: '20%',
                                marginLeft: '5%',
                                height: 'auto',
                            }}
                            text=""
                            rightIcon="download"
                            onClick={this.handleDownload}
                        />
                    </div>
                </div>
                <canvas ref={this.canvasQueryWithContext} width={200} height={0}></canvas>
            </div>
        )
    }
}

export default QueryGallery;
