import { clientConfig } from './../../config.js';

import React from 'react';
import { MenuItem, Button } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { customFetch } from "./../utils/utils";

import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";

import {
    areFragmentsEqual,
    createFragment,
    fragmentSelectProps,
    IFragment,
    maybeAddCreatedFragmentToArrays,
    maybeDeleteCreatedFragmentFromArrays,
    renderCreateFragmentOption,
    arrayContainsFragment,
} from "./fragment";


const FragmentSelect = Select.ofType<IFragment>();

export interface IFragmentSelectProps {
    onItemSelect: ((fragment: IFragment) => void),
    initialItem: IFragment,
}
export interface IFragmentSelectState {
    allowCreate: boolean;
    closeOnSelect: boolean;
    createdItems: IFragment[];
    disableItems: boolean,
    disabled: boolean,
    filterable: boolean,
    fragment: IFragment | undefined;
    items: IFragment[];
    minimal: boolean;
    openOnKeyDown: boolean;
    resetOnClose: boolean;
    resetOnQuery: boolean;
    resetOnSelect: boolean;
}

interface jsonFragment {
    /** ID of fragment. */
    id: number;
    /** Name of fragment. */
    name: string;
}

class FragmentSelectBox extends React.PureComponent<IFragmentSelectProps, IFragmentSelectState> {
    fragmentSelectElement = React.createRef<Select<IFragment>>();

    constructor(props: IFragmentSelectProps, state: IFragmentSelectState) {
        super(props);
        this.state = {
            allowCreate: false,
            closeOnSelect: true,
            createdItems: [],
            disableItems: false,
            disabled: false,
            filterable: true,
            fragment: props.initialItem,
            items: fragmentSelectProps.items,
            minimal: true,
            openOnKeyDown: false,
            resetOnClose: false,
            resetOnQuery: true,
            resetOnSelect: false,
        }
    };

    public getFragmentName(): string {
        return (this.state.fragment) ? this.state.fragment.name : "";
    }

    public getFragments(id: number, representation: string) {
        let initialFragments = new Array<jsonFragment>();
        let requestHeaders: any = { 'Authorization': localStorage.getItem('token') };
        if (representation === undefined) {
            representation = "1";
        }
        customFetch(clientConfig.webApiProtocol + clientConfig.webApiUrl + ":" + clientConfig.webApiPort + "/api/fragmentListForTabletId?id=" + id + "&t=" + representation, {
            method: 'GET',
            headers: requestHeaders,
        }).then(response => {
            return response.json();
        }).then(data => {
            initialFragments = data.message;
            console.log(initialFragments);
            fragmentSelectProps.items = initialFragments.map(function (item) { return createFragment(item.id, item.name) });
            fragmentSelectProps.items.sort((a, b) => (a.name > b.name) ? 1 : -1); // sort by name
            this.setState({ items: fragmentSelectProps.items });
            if (!arrayContainsFragment(fragmentSelectProps.items, this.state.fragment))
                this.setState({ fragment: undefined });
        });
    }

    render() {
        const { allowCreate, fragment, minimal, disabled, filterable, items,
            resetOnClose, resetOnQuery, resetOnSelect } = this.state;

        //const maybeCreateNewItemFromQuery = allowCreate ? createFragment : undefined;
        const maybeCreateNewItemRenderer = allowCreate ? renderCreateFragmentOption : undefined;

        return (
            <>
                <label className="bp3-label">
                    Fragments:
                    <FragmentSelect
                        {...fragmentSelectProps}
                        filterable={filterable}
                        resetOnClose={resetOnClose}
                        resetOnQuery={resetOnQuery}
                        resetOnSelect={resetOnSelect}
                        className="tablet-select"
                        //createNewItemFromQuery={maybeCreateNewItemFromQuery}
                        createNewItemRenderer={maybeCreateNewItemRenderer}
                        itemsEqual={areFragmentsEqual}
                        ref={this.fragmentSelectElement}
                        // we may customize the default fragmentSelectProps.items by
                        // adding newly created items to the list, so pass our own.
                        items={items}
                        noResults={<MenuItem disabled={true} text="No results." />}
                        initialContent={<MenuItem disabled={true} text={fragmentSelectProps.items.length + " fragments loaded."} />}
                        onItemSelect={this.handleValueChange}
                        popoverProps={{ minimal }}
                        scrollToActiveItem={false}
                    >
                        <Button
                            icon="layer"
                            rightIcon="caret-down"
                            text={fragment ? `${fragment.name}` : "(No selection)"}
                            disabled={disabled}
                        />
                    </FragmentSelect>
                </label>
            </>
        );
    }

    private handleValueChange = (selectedFragment: IFragment) => {
        // delete the old fragment from the list if it was newly created
        const { createdItems, items } = maybeDeleteCreatedFragmentFromArrays(
            this.state.items,
            this.state.createdItems,
            this.state.fragment,
        );
        // add the new fragment to the list if it is newly created
        const { createdItems: nextCreatedItems, items: nextItems } = maybeAddCreatedFragmentToArrays(
            items,
            createdItems,
            selectedFragment,
        );
        // set state and notify parent when complete
        this.setState({ createdItems: nextCreatedItems, fragment: selectedFragment, items: nextItems },
            () => this.props.onItemSelect(selectedFragment)
        );
    };
}

export default FragmentSelectBox;