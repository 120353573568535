export const rgbaColorMap = require('colormap')({
    colormap: 'warm',
    nshades: 101,
    format: 'rba',
    alpha: 1.0
})

export const rgbaStringColorMap = require('colormap')({
    colormap: 'warm',
    nshades: 101,
    format: 'rgbaString',
    alpha: 0.75
})

export function indexToColor(colormap: any, index: number): any {
    index = index > 101 ? 101 : index < 0 ? 0 : index;
    return colormap[index];
}

export function scoreToColor(colormap: any, score: number): any {
    return colormap[Math.floor(score)];
}

const color = {rgbaColorMap, rgbaStringColorMap, indexToColor, scoreToColor};

export default color;