import * as React from "react";
import { Button, Classes, Dialog, Intent, Tooltip, FileInput, FormGroup, InputGroup, Position } from "@blueprintjs/core";

export interface IUploadFileDialogProps {
    onSuccess: ((file: File, tablet: string, fragment: string) => void),
    hideMenus: (() => void),
}

export interface IUploadFileDialogState {
    isOpen: boolean;
    option: string;
    tabletName: string;
    tabletFragment: string;
    selectedFile: File;
}

export class UploadFileDialog extends React.PureComponent<IUploadFileDialogProps, IUploadFileDialogState> {
    tabletName = React.createRef<HTMLInputElement>();
    tabletFragment = React.createRef<HTMLInputElement>();
    chooseFile = React.createRef<FileInput>();
    tooltipElement: React.RefObject<Tooltip> = React.createRef<Tooltip>();
    fileInputText = "Choose file...";

    public render() {
        return (
            <>
                <Tooltip ref={this.tooltipElement} content={"Opens a menu to upload a new image to the database."} position={Position.BOTTOM}>
                    <Button icon="log-in" intent={Intent.PRIMARY} onClick={this.handleOpen}>Upload Image</Button>
                </Tooltip>
                <Dialog
                    icon="log-in"
                    onClose={this.handleClose}
                    title="Query"
                    {...this.state}
                >
                    <form onSubmit={this.handleSubmit}>
                        <div className={Classes.DIALOG_BODY}>
                            <FormGroup
                                label="Tablet name"
                                labelFor="text-input"
                                labelInfo="(required)">
                                <InputGroup id="tabletname-input" autoFocus inputRef={this.tabletName} placeholder="Tablet name" name="TabletName" onChange={this.handleChangeOnTablet} />
                            </FormGroup>
                        </div>
                        <div className={Classes.DIALOG_BODY}>
                            <FormGroup
                                label="Fragment name"
                                labelFor="text-input"
                                labelInfo="(required)">
                                <InputGroup id="fragmentname-input" autoFocus inputRef={this.tabletFragment} placeholder="Fragment name" name="FragmentName" onChange={this.handleChangeOnFragment} />
                            </FormGroup>
                        </div>
                        <FileInput 
                            disabled={false}
                            text={this.fileInputText}
                            id="choose-file"
                            fill={true}
                            ref={this.chooseFile}
                            onInputChange={(event) => this.handleFileInput(event)} />

                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip content="Close the dialog.">
                                    <Button onClick={this.handleClose}>Cancel</Button>
                                </Tooltip>
                                <Button intent={Intent.PRIMARY} rightIcon="arrow-right" type="submit">Upload image</Button>
                            </div>
                        </div>
                    </form>
                </Dialog>                
            </>
        );
    }

    private handleOpen = () => {this.props.hideMenus(); this.setState({ isOpen: true })};
    
    private handleClose = () => this.setState({ isOpen: false });

    private handleChangeOnTablet = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ tabletName: event.target.value });
    }

    private handleChangeOnFragment = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ tabletFragment: event.target.value });
    }

    private handleFileInput(event: any) {
        if (event.target.files[0]) {
            if (this.chooseFile.current !== null) {
                this.fileInputText = event.target.files[0].name;
                this.setState({selectedFile : event.target.files[0]});
                this.forceUpdate();
            }
        }
    }

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.onSuccess(this.state.selectedFile, this.state.tabletName, this.state.tabletFragment);
        this.handleClose();
    }
}

export default UploadFileDialog;