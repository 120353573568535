import React from "react";
import {FormGroup, NumericInput} from "@blueprintjs/core";
import styles from "./retrieval-list-control.module.css";

export interface IRetrievalListControlProps {
    initialCandidates: number;
    initialConfidence: number;
    maxCandidates: number;
    onKeyUp: (event: React.KeyboardEvent<HTMLDivElement>, num: number, max: number) => void;
    changedNum: (num: number) => void;
    changedConf: (conf: number) => void;
}

export interface IRetrievalListControlState {
    numCandidates: number;
    minConfidence: number;
}

class RetrievalListControl extends React.PureComponent<IRetrievalListControlProps, IRetrievalListControlState> {

    constructor(props: IRetrievalListControlProps) {
        super(props);

        this.state = {
            numCandidates: this.props.initialCandidates,
            minConfidence: this.props.initialConfidence,
        }

        this.handleCandidatesValueChange = this.handleCandidatesValueChange.bind(this);
        this.handleConfidenceValueChange = this.handleConfidenceValueChange.bind(this);
    }

    static clipValue(n: number, min: number, max: number): number {
        return n > max ? n : n < min ? 0 : n;
    }

    private handleCandidatesValueChange(n: number) {
        const newValue = RetrievalListControl.clipValue(n, 0, this.props.maxCandidates);
        this.setState({numCandidates: newValue});
        if(this.props.changedNum !== undefined) {
            this.props.changedNum(n);
        }
    }

    private handleConfidenceValueChange(n: number) {
        const newValue = RetrievalListControl.clipValue(n, 0, 100);
        this.setState({minConfidence: newValue});
        if(this.props.changedConf !== undefined) {
            this.props.changedConf(n);
        }
    }

    render() {

        const {onKeyUp} = this.props;
        const {numCandidates, minConfidence} = this.state;

        // TODO: implement
        return (
            <React.Fragment>
                <div className={styles.input_container}>
                    <FormGroup
                        className={styles.form_group}
                        label="Num Candidates"
                        labelFor="input_max_candidates">
                        <NumericInput
                            id="input_max_candidates"
                            value={this.state.numCandidates}
                            min={0} max={this.props.maxCandidates}
                            stepSize={1} size={1}
                            onKeyUp={(e) => onKeyUp(e, numCandidates, minConfidence)}
                            onValueChange={(n) => this.handleCandidatesValueChange(n)}/>
                    </FormGroup>
                </div>

                <div className={styles.input_container}>
                    <FormGroup
                        className={styles.form_group}
                        label="Min Confidence"
                        labelFor="input_min_confidence">
                        <NumericInput
                            id="input_min_confidence"
                            value={this.state.minConfidence}
                            min={0} max={100}
                            stepSize={1} size={1}
                            onKeyUp={(e) => onKeyUp(e, numCandidates, minConfidence)}
                            onValueChange={(n) => this.handleConfidenceValueChange(n)}/>
                    </FormGroup>
                </div>
            </React.Fragment>
        )
    }
}

export default RetrievalListControl;