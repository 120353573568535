import React from 'react';
import { SplitPane } from 'react-multi-split-pane'
import './query-by-example-displayer.css'
import { clientConfig } from './../../config.js';
import { customFetch } from "./../utils/utils";
import QueryGallery from './../query-gallery';
import b64toBlob from 'b64-to-blob';
import TargetList, { Target } from "./targets-list";
import Webservices from "./../webservices";
import { Button } from '@blueprintjs/core'
import { saveAs } from "file-saver";

export interface IQueryByExampleDisplayProps {
    query_id: number,
    webservices: Webservices,
}

export interface IQueryByExampleDisplayState {
    currentImage: string,
    selectedTargets: number[],
    targets: Target[],
    query_id: number,
    queryImg: string,
    contextImg: string,
}

class QueryByExampleDisplay extends React.PureComponent<IQueryByExampleDisplayProps, IQueryByExampleDisplayState> {
    webservices: Webservices;
    queryGalleryElement: QueryGallery[];
    canvasQuery: any;
    canvasQueryWithContext: any;

    constructor(props: IQueryByExampleDisplayProps, state: IQueryByExampleDisplayState) {
        super(props)
        this.state = {
            currentImage: "",
            selectedTargets: [],
            targets: [],
            query_id: this.props.query_id,
            queryImg: "",
            contextImg: "",
        }
        this.webservices = new Webservices();
        this.queryGalleryElement = [];
        this.canvasQuery = React.createRef();
        this.canvasQueryWithContext = React.createRef();
    }

    drawContextImg(imageData: any, x: number, y: number, w: number, h: number) {
        let curElement = this.canvasQueryWithContext.current;
        const ctx = curElement.getContext('2d');
        var img = new Image();
        img.onload = function () {
            ctx.drawImage(img, 0, 0, 200, 200);
        }
        img.src = imageData;
    }

    drawQueryImg(imageData: any, x: number, y: number, w: number, h: number) {
        let curElement = this.canvasQuery.current;
        const ctx = curElement.getContext('2d');
        var img = new Image();
        img.onload = function () {
            // void ctx.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
            //ctx.drawImage(img, x, y, w, h, 0, 0, 200, 200);
            ctx.drawImage(img, 0, 0, 200, 200);
        }
        img.src = imageData;
    }

    getQueryRegion(query: number) {
        let url = clientConfig.webApiProtocol + clientConfig.webApiUrl + ":" + clientConfig.webApiPort + "/api/getQueryRegion";
        let requestHeaders: any = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token')
        };
        customFetch(url, {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                query_id: query,
            })
        }).then(response => response.json()).then(data => {
            this.setState({ queryImg: data.message });
            const blob = b64toBlob(data.message, 'image/jpg');
            let tmpBlob = URL.createObjectURL(blob);
            this.drawQueryImg(tmpBlob, 0, 0, 0, 0);
        });
    }

    getContextRegion(query: number) {
        let url = clientConfig.webApiProtocol + clientConfig.webApiUrl + ":" + clientConfig.webApiPort + "/api/getQueryContextRegion";
        let requestHeaders: any = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token')
        };
        customFetch(url, {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                query_id: query,
            })
        }).then(response => response.json()).then(data => {
            this.setState({ contextImg: data.message });
            const blob = b64toBlob(data.message, 'image/jpg');
            let tmpBlob = URL.createObjectURL(blob);
            this.drawContextImg(tmpBlob, 0, 0, 0, 0);
        });
    }

    async componentDidMount() {
        // Get query ID
        const QueryID: number = this.state.query_id;
        console.log(QueryID);
        // Get the name of the tablet + fragment
        // TODO: Why does this query return the results 2 times?
        let response: any = await this.webservices.getTabletAndFragmentName(QueryID);
        let Name: string = response.result[0][0].tablet_name + " / " + response.result[0][0].tablet_fragment_name
        this.setState({ currentImage: Name });

        // Get query and query with context
        //let queryImages: any = this.getQueryRegion(QueryID);

        // Get the target details
        // TODO: Why does this query return the results 2 times?
        let targetDetails: any = await this.webservices.getTargetDetailsForQuery(QueryID);
        targetDetails = targetDetails.result[0];
        let targets: Target[] = [];

        targetDetails.forEach((element: any) => {
            targets.push(new Target(element.tablet_name, element.tablet_fragment_name, element.sq_result_id))
        });
        this.setState({ targets: targets });
        // w : 111, h : 102
        //this.drawImg(retrieved_candidate_0, 0, 0, 100, 100);
        this.getQueryRegion(QueryID);
        this.getContextRegion(QueryID);
    }

    /*
    getImage(id: number) {
        let url = clientConfig.webApiProtocol + clientConfig.webApiUrl + ":" + clientConfig.webApiPort + "/api/fragmentobjects?id=" + id;
        let requestHeaders: any = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token')
        };
        customFetch(url, {
            method: 'GET',
            headers: requestHeaders,
        }).then(response => response.blob())
            .then(blob => {
                let tmpBlob = URL.createObjectURL(blob);
        });
    }
    */

    handleSelectionChange = (target: number, checked: boolean) => {
        let tmpTargets: number[] = this.state.selectedTargets;

        if (checked === true) {
            tmpTargets.push(target);
        } else {
            const index = tmpTargets.indexOf(target);
            if (index > -1) {
                tmpTargets.splice(index, 1);
            }
        }
        this.setState({ selectedTargets: tmpTargets });
        console.log(tmpTargets);
        this.forceUpdate();
    }

    focusOnAnnotation = () => {

    }

    numCandidatesChange = (n: number) => {

    }

    confidenceChange = (n: number) => {

    }

    handleDownloadAll = () => {
        console.log("'Download All' clicked");

        var JSZip = require("jszip");
        let queryImg = this.state.queryImg;
        let contextImg = this.state.contextImg;

        var zip = new JSZip();
        zip.file("query.jpg", queryImg, { base64: true });
        zip.file("context.jpg", contextImg, { base64: true });

        for (let index = 0; index < this.queryGalleryElement.length; index++) {
            let ele = this.queryGalleryElement[index];
            let predictions = ele.state.imgCandidates;
            let candidates = ele.state.candidate;

            var img = zip.folder(ele.props.target.tablet + "_" + ele.props.target.fragment);
            let selectedItems = ele.state.selected;
            console.log(selectedItems);
            for (let index = 0; index < predictions.length; index++) {
                if (selectedItems[index] === true) {
                    img.file(candidates[index].prediction.sign + "_" + ele.props.target.tablet + "_" + ele.props.target.fragment + "_" + index.toString() + ".jpg", predictions[index].substr(22), { base64: true });
                }
            }
        }
        /*
        let targets = this.state.targets;
        for (let index = 0; index < targets.length; index++) {
            var folder = zip.folder(targets[index].tablet + "_" + targets[index].fragment);
            folder.file(
                "sign" + "_" + "tablet" + "_" + "fragment" + ".jpg", 
                0, 
                {base64: true}
            );
        }
        */

        zip.generateAsync({ type: "blob" }).then((content: any) => {
            let names = this.state.currentImage.split("/");
            saveAs(content, names[0].trim() + "_" + names[1].trim() + "_queryByExample.zip");
        });
    }

    render() {
        return (
            <SplitPane split="vertical"
                defaultSizes={[15, 80]}
                minSize={[330, 200]}>
                <div style={{ height: "100%", width: "100%", float: "left" }}>
                    <div className="background" style={{ width: "100%", height: "7%" }}>
                        <div className="background" style={{ padding: "10px" }}>
                            <div className="span-2 border header">
                                Query by example
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "30%" }}>
                        <div className="span-2 border textsize" style={{ width: "100%", height: "100%" }}>
                            Fragment:
                            {/*TODO: The following text should be changable from a function*/}
                            <div>
                                {this.state.currentImage}
                            </div>
                            <p></p>
                            <table style={{ width: "100%", height: "80%" }}>
                                <tr>
                                    <td style={{ width: "50%", textAlign: "left" }}>
                                        Context:
                                    </td>
                                    <td style={{ width: "50%", textAlign: "left" }}>
                                        Query:
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "60%", height: "100%", textAlign: "left" }}>
                                        <div style={{ width: "100%", height: "100%", float: "left", padding: "10px" }}>
                                            <canvas ref={this.canvasQueryWithContext} width={200} height={200} style={{ width: "150px", height: "150px" }}></canvas>
                                            {//<img src={retrieved_candidate_0} ref={this.queryContext} style={{width : "100%"}} alt="Left"/>
                                            }
                                        </div>
                                    </td>
                                    <td style={{ width: "40%", textAlign: "left" }}>
                                        <div style={{ width: "100%", height: "100%", float: "right", padding: "20px" }}>
                                            <canvas ref={this.canvasQuery} width={200} height={200} style={{ width: "100px", height: "100px" }}></canvas>
                                            {//<img src={retrieved_candidate_0} ref={this.query} style={{width : "100%"}} alt="Right"/>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="background" style={{ width: "100%", height: "7%" }}>
                        <div className="background" style={{ padding: "10px" }}>
                            <div className="span-2 border header">
                                Targets
                            </div>
                        </div>
                    </div>
                    {this.state.targets.length > 0 && <TargetList targets={this.state.targets} handleSelectionChange={this.handleSelectionChange} />}
                    <div style={{ position: "absolute", bottom: "10px", height: "20px" }}>
                        <Button
                            icon="download"
                            text="Download All"
                            onClick={() => this.handleDownloadAll()}
                        />
                    </div>
                </div>
                <div style={{ height: "100%", float: "left", overflow: "hidden" }}>
                    {
                        this.queryGalleryElement = [] &&
                        this.state.selectedTargets.map((target) => {
                            let getRef = (element: any) => {
                                if (element !== null) {
                                    this.queryGalleryElement.push(element);
                                    this.queryGalleryElement.splice(0, 1);
                                    //console.log(this.queryGalleryElement);
                                }
                            };
                            let targetElement: Target | undefined = this.state.targets.find(element => element.id === target);
                            return <div style={{ float: "left" }}>
                                <QueryGallery
                                    ref={getRef}
                                    target={targetElement}
                                    focusOnSelection={() => this.focusOnAnnotation()}
                                    numCandidatesChange={(n: number) => this.numCandidatesChange(n)}
                                    confidenceChange={(n: number) => this.confidenceChange(n)}
                                    showFileName={true}
                                    queryImg={this.state.queryImg}
                                    contextImg={this.state.contextImg}
                                />
                            </div>
                        })
                    }
                </div>
            </SplitPane>
        )
    }
}

export default QueryByExampleDisplay;