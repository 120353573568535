import { clientConfig } from './../../config.js';

import React from 'react';
import { MenuItem, Button } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { customFetch } from "./../utils/utils";

import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";

import {
    createModel,
    modelSelectProps,
    IModel,
    renderCreateTabletOption,
} from "./model";
import Webservices from '../webservices';

const ModelSelect = Select.ofType<IModel>();

export interface IModelSelectProps {
    onItemSelect: ((model: IModel) => void),
    initialItem: IModel,
}

export interface IModelSelectState {
    allowCreate: boolean;
    closeOnSelect: boolean;
    createdItems: IModel[];
    disableItems: boolean,
    disabled: boolean,
    filterable: boolean,
    model: IModel | undefined;
    items: IModel[];
    minimal: boolean;
    openOnKeyDown: boolean;
    resetOnClose: boolean;
    resetOnQuery: boolean;
    resetOnSelect: boolean;
    type: string;
}

interface jsonModel {
    /** ID of tablet. */
    id: number;
    /** Name of tablet. */
    name: string;
    /** Type of the model. */
    type: string;
    /** Description of the model. */
    description: string;
    /** File name of the model. */
    filename: string;
}

class ModelSelectBox extends React.PureComponent<IModelSelectProps, IModelSelectState> {
    modelSelectElement = React.createRef<Select<IModel>>();
    webservices: Webservices;

    constructor(props: IModelSelectProps, state: IModelSelectState) {
        super(props);
        this.state = {
            allowCreate: false,
            closeOnSelect: true,
            createdItems: [],
            disableItems: false,
            disabled: false,
            filterable: false,
            model: props.initialItem,
            items: modelSelectProps.items,
            minimal: true,
            openOnKeyDown: false,
            resetOnClose: false,
            resetOnQuery: true,
            resetOnSelect: false,
            type: "annotate"
        };
        this.webservices = new Webservices();
    }

    public getModelName(): string {
        return (this.state.model) ? this.state.model.name : "";
    }

    public getModels() {
        let models = new Array<jsonModel>();
        let requestHeaders: any = { 'Authorization': localStorage.getItem('token') };
        var requestOptions = {
            method: 'POST',
            headers: requestHeaders
        }
        customFetch(clientConfig.webApiProtocol + clientConfig.webApiUrl + ":" + clientConfig.webApiPort + "/api/getAiModells", requestOptions).then(response => {
            return response.json();
        }).then(data => {
            models = data.result;            
            console.log(models);

            modelSelectProps.items = models.map(function (item) { return createModel(item.id, item.name, item.type, item.description, item.filename) });
            modelSelectProps.items.sort((a, b) => (a.name > b.name) ? 1 : -1); // sort by name
            modelSelectProps.items = modelSelectProps.items.filter(item => item.type === this.state.type);

            this.setState({ items: modelSelectProps.items, model: modelSelectProps.items[0] }, () => this.props.onItemSelect(modelSelectProps.items[0]));
        });
    }

    componentDidMount() {
        this.getModels();
    }

    render() {
        const { allowCreate, model, minimal, disabled, filterable, items,
            resetOnClose, resetOnQuery, resetOnSelect } = this.state;

        const maybeCreateNewItemRenderer = allowCreate ? renderCreateTabletOption : undefined;

        return (
            <>
                <label className="bp3-label">
                    Models:
                    <ModelSelect
                        {...modelSelectProps}
                        filterable={filterable}
                        resetOnClose={resetOnClose}
                        resetOnQuery={resetOnQuery}
                        resetOnSelect={resetOnSelect}
                        className="fragment-select"                        
                        createNewItemRenderer={maybeCreateNewItemRenderer}                        
                        ref={this.modelSelectElement}
                        items={items} 
                        //activeItem={this.state.model}
                        noResults={<MenuItem disabled={true} text="No results." />}
                        onItemSelect={this.handleValueChange}
                        popoverProps={{ minimal }}
                        scrollToActiveItem={false}
                    >
                        <Button
                            icon="layers"
                            rightIcon="caret-down"
                            text={model ? `${model.name} (${model.type})` : "(No selection)"}
                            disabled={disabled}
                        />
                    </ModelSelect>
                </label>
            </>
        );
    }

    private handleValueChange = (selectedModel: IModel) => {        
        // set state and notify parent when complete
        this.setState({ model: selectedModel },
            () => this.props.onItemSelect(selectedModel)
        );
    };
}

export default ModelSelectBox;