import React from "react";

import {Suggest} from "@blueprintjs/select";
import {Button, Intent, MenuItem, Props} from "@blueprintjs/core";

import {
    ITextSelect,
    textSelectProps,
    createText,
    renderCreateTextOption,
    areTextsEqual,
    maybeDeleteCreatedTextFromArrays,
    maybeAddCreatedTextToArrays
} from "./text";

export const INTENTS = [Intent.NONE, Intent.PRIMARY, Intent.SUCCESS, Intent.DANGER, Intent.WARNING];

const TextSelect = Suggest.ofType<ITextSelect>();

export interface ITextSelectProps extends Props {
    id: string;
}

export interface ITextSelectState {
    allowCreate: boolean;
    closeOnSelect: boolean;
    createdItems: ITextSelect[];
    fill: boolean;
    text: ITextSelect;
    items: ITextSelect[];
    minimal: boolean;
    openOnKeyDown: boolean;
    resetOnClose: boolean;
    resetOnQuery: boolean;
    resetOnSelect: boolean;
}

export class TextSelectBox extends React.PureComponent<ITextSelectProps, ITextSelectState> {

    public state: ITextSelectState = {
        allowCreate: false,
        closeOnSelect: true,
        createdItems: [],
        fill: true,
        text: {text: ""},
        items: textSelectProps.items,
        minimal: true,
        openOnKeyDown: false,
        resetOnClose: false,
        resetOnQuery: false,
        resetOnSelect: false,
    };

    public render() {

        const {allowCreate, text, minimal, ...flags} = this.state;

        const maybeCreateNewItemFromQuery = allowCreate ? createText : undefined;
        const maybeCreateNewItemRenderer = allowCreate ? renderCreateTextOption : undefined;

        return (
            <TextSelect
                {...textSelectProps}
                {...flags}
                selectedItem={this.state.text}
                createNewItemFromQuery={maybeCreateNewItemFromQuery}
                createNewItemRenderer={maybeCreateNewItemRenderer}
                inputValueRenderer={(text: ITextSelect) => text.text}
                itemsEqual={areTextsEqual}
                items={this.state.items}
                noResults={<MenuItem disabled={true} text="No results." />}
                onItemSelect={this.handleValueChange}
                popoverProps={{ minimal }}
                inputProps={{
                    placeholder: "Epoch...",
                    rightElement: <Button minimal outlined icon="cross" onClick={this.handleClearSelected}/>
                }}
            />
        )
    }

    private handleClearSelected = () => {
        console.log('Select all button clicked!');
        const text: ITextSelect = {text: ""};
        this.setState({text});
    }

    private handleValueChange = (text: ITextSelect) => {
        // delete the old text from the list if it was newly created
        const {createdItems, items} = maybeDeleteCreatedTextFromArrays(
            this.state.items,
            this.state.createdItems,
            this.state.text,
        );

        // add the new text to the list if it is newly created
        const {createdItems: nextCreatedItems, items: nextItems} = maybeAddCreatedTextToArrays(
            items,
            createdItems,
            text,
        );

        // change component state
        this.setState({
            createdItems: nextCreatedItems,
            text,
            items: nextItems
        })
    }
}
export default TextSelectBox;