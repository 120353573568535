import * as React from "react";
import { HotkeysTarget2, Button, Classes, Dialog, Intent, Tooltip, FormGroup, Position } from "@blueprintjs/core";

export interface IAnnotationPopupProps {
    onSuccess: (() => void),
    hideMenus: (() => void),
}

export interface IAnnotationPopupState {
    isOpen: boolean,
    signclass: string,
    disabled: boolean,
}

export class AnnotationPopUpRemove extends React.Component<IAnnotationPopupProps, IAnnotationPopupState> {
    signclassElement = React.createRef<HTMLInputElement>();
    tooltipElement: React.RefObject<Tooltip> = React.createRef<Tooltip>();

    constructor(props: IAnnotationPopupProps, state: IAnnotationPopupState) {
        super(props);
        this.state = {
            isOpen: false,
            signclass: '',
            disabled: false,
        };
    }

    private hotkeys = [
        {
            combo: "R",
            global: true,
            label: "Remove Annotation",
            onKeyDown: () => this.handleOpen(),
        },
    ];

    render() {
        return (
            <HotkeysTarget2 hotkeys={this.hotkeys}>
                {({ handleKeyDown, handleKeyUp }) => (
                    <div tabIndex={0} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
                        <Tooltip ref={this.tooltipElement} content={"Removes the selected annotation. (Hotkey R)"} position={Position.BOTTOM}>
                            <Button
                                icon="delete"
                                intent={Intent.PRIMARY}
                                onClick={this.handleOpen}
                                disabled={this.state.disabled}
                            >
                                Remove
                            </Button>
                        </Tooltip>

                        <Dialog
                            icon="delete"
                            onClose={this.handleClose}
                            title="Remove annotation"
                            {...this.state}
                        >
                            <form onSubmit={this.handleSubmit}>
                                <div className={Classes.DIALOG_BODY}>
                                    <FormGroup
                                        label="Do you really want to remove the selected annotation?">
                                    </FormGroup>
                                    <FormGroup
                                        label="Note: This action can not be reverted.">
                                    </FormGroup>
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        <Tooltip content="Close the dialog.">
                                            <Button onClick={this.handleClose}>Cancel</Button>
                                        </Tooltip>
                                        <Button intent={Intent.PRIMARY} rightIcon="delete" type="submit">Remove</Button>
                                    </div>
                                </div>
                            </form>
                        </Dialog>

                    </div>
                )}
            </HotkeysTarget2>
        );
    }

    private handleOpen = () => { this.props.hideMenus(); this.setState({ isOpen: true }) };

    private handleClose = () => this.setState({ isOpen: false });

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ signclass: event.target.value });
    }

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.onSuccess();
        this.handleClose();
    }
}
export default AnnotationPopUpRemove;